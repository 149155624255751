import {createSlice} from "@reduxjs/toolkit";
const initialState = {
	customer: {},
	data: {},
	investments: [],
	transactions: [],
	products: [
		// {name: "Product #1", description: "This is a product description - Space, the final frontier. These are the voyages of the Starship Enterprise. Its five-year mission."},
		// {name: "Product #1", description: "This is a product description - Space, the final frontier. These are the voyages of the Starship Enterprise. Its five-year mission.This is a product description - Space, the final frontier. These are the voyages of the Starship Enterprise. Its five-year mission.This is a product description - Space, the final frontier. These are the voyages of the Starship Enterprise. Its five-year mission.e"},
		// {name: "Product #1", description: "This is a product description - Space, the final frontier. These are the voyages of the Starship Enterprise. Its five-year mission."},
		// {name: "Product #1", description: "This is a product description - Space, the final frontier. These are the voyages of the Starship Enterprise. Its five-year mission."},
		// {name: "Product #1", description: "This is a product description - Space, the final frontier. These are the voyages of the Starship Enterprise. Its five-year mission."},
	],
	cash_balance: null,
	fetchingInvestments: false,
	fetchingTransactions: false,
	fetchingBalance: false,
	fetchingProducts: false,
	totalInvest: "", totalInterest: "", fetchingInvest: false, fetchingInterest: false,
	profileCompletion: {}
};
const userSlice = createSlice({
	initialState,
	name: "profile",
	reducers: {
		reset: (state) => {
			state = initialState;
			return state;
		},
		setTotalInvest: (state, {payload}) => {
			state = {...state, totalInvest: payload}
			return state;
		},
		setFetchingInvest: (state, {payload}) => {
			state = {...state, fetchingInvest: payload}
			return state;
		},
		setFetchingInterest: (state, {payload}) => {
			state = {...state, fetchingInterest: payload}
			return state;
		},
		setTotalInterest: (state, {payload}) => {
			state = {...state, totalInterest: payload}
			return state;
		},
		setProfile: (state, {payload}) => {
			state = {
				...state, ...payload
			}
			return state;
		},
		setInvestments: (state, {payload}) => {
			state = {...state, investments: payload}
			return state;
		},
		setProducts: (state, {payload}) => {
			state = {...state, products: payload}
			return state;
		},
		setCashBal: (state, {payload}) => {
			state = {...state, cash_balance: payload}
			return state;
		},
		setTransactions: (state, {payload}) => {
			state = {...state, transactions: Array.isArray(payload) ? [...payload.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))] : payload}
			return state;
		},
		setFetchingInvestments: (state, {payload}) => {
			state = {...state, fetchingInvestments: payload}
			return state;
		},
		setFetchingBalance: (state, {payload}) => {
			state = {...state, fetchingBalance: payload}
			return state;
		},
		setFetchingProducts: (state, {payload}) => {
			state = {...state, fetchingProducts: payload}
			return state;
		},
		setFetchingTransactions: (state, {payload}) => {
			state = {...state, fetchingTransactions: payload}
			return state;
		},
		setProfileCompletion: (state, {payload}) => {
			state = {...state, profileCompletion: payload}
			return state;
		}
	}
})

export const userReducer = userSlice.reducer;
export const {
	setProfile,
	setInvestments,
	setProducts,
	setFetchingProducts,
	setCashBal,
	setFetchingInvestments, reset, setProfileCompletion,
	setFetchingBalance, setFetchingInterest, setFetchingInvest, setFetchingTransactions, setTotalInterest, setTotalInvest, setTransactions
} = userSlice.actions;