import React from 'react'

const InvPattern = () => {
	return (
		<svg className='absolute inset-0 h-full w-full rounded-2xl' viewBox="0 0 350 286" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g opacity="0.05">
				<path d="M46.0384 152C-68.2501 152 -152.785 68.5778 -152.089 -36.5343C-151.651 -73.3898 -140.371 -109.301 -119.656 -139.787C-98.9421 -170.274 -69.7104 -193.987 -35.6073 -207.97C-1.50424 -221.952 35.9607 -225.584 72.1145 -218.413C108.268 -211.243 141.511 -193.586 167.696 -167.646C185.516 -150.553 199.644 -129.989 209.209 -107.224C218.775 -84.4583 223.574 -59.9747 223.311 -35.283C224 11.095 206.71 55.9395 175.065 89.8504C158.874 108.391 139.093 123.457 116.916 134.139C94.74 144.821 70.6286 150.897 46.0384 152ZM35.1935 134.203C68.7125 134.203 101.48 124.272 129.36 105.664C157.239 87.0557 178.98 60.6047 191.839 29.6503C204.698 -1.30423 208.097 -35.3745 201.609 -68.2595C195.12 -101.144 179.033 -131.37 155.38 -155.12C131.727 -178.87 101.568 -195.08 68.7098 -201.703C35.8516 -208.326 1.76773 -205.066 -29.2391 -192.334C-60.2459 -179.602 -86.7854 -157.969 -105.507 -130.166C-124.229 -102.363 -134.294 -69.636 -134.432 -36.1172C-134.542 -13.7782 -130.234 8.36227 -121.757 29.0308C-113.281 49.6993 -100.801 68.488 -85.0373 84.3166C-69.2734 100.145 -50.5357 112.701 -29.9021 121.263C-9.26842 129.824 12.8542 134.222 35.1935 134.203Z" className='fill-black dark:fill-white' fillOpacity="0.3" />
				<path d="M35.1941 134.204C1.61076 134.037 -31.1674 123.905 -58.9869 105.091C-86.8064 86.2778 -108.415 59.6297 -121.075 28.5234C-133.735 -2.58279 -136.876 -36.747 -130.101 -69.6402C-123.326 -102.534 -106.939 -132.675 -83.0164 -156.246C-59.0941 -179.817 -28.7132 -195.757 4.27666 -202.045C37.2665 -208.333 71.3806 -204.687 102.296 -191.568C133.212 -178.45 159.537 -156.449 177.937 -128.354C196.337 -100.26 205.983 -67.3353 205.654 -33.7532C205.177 11.0778 186.985 53.9 155.049 85.3668C123.114 116.834 80.0273 134.39 35.1941 134.204ZM35.1941 -193.09C3.98547 -192.925 -26.4749 -183.518 -52.342 -166.057C-78.2091 -148.595 -98.3234 -123.862 -110.146 -94.9789C-121.969 -66.0958 -124.97 -34.3576 -118.77 -3.77042C-112.571 26.8167 -97.4484 54.8817 -75.3125 76.8819C-53.1766 98.8821 -25.0192 113.831 5.60548 119.843C36.2302 125.854 67.9493 122.658 96.7592 110.658C125.569 98.6581 150.178 78.3921 167.48 52.4181C184.782 26.4441 194.002 -4.07347 193.974 -35.2826C193.827 -77.1626 177.097 -117.279 147.444 -146.854C117.791 -176.429 77.6306 -193.053 35.7503 -193.09H35.1941Z" className='fill-black dark:fill-white' fillOpacity="0.1" />
				<path d="M35.7494 -193.09C66.9759 -192.953 97.461 -183.562 123.352 -166.105C149.244 -148.648 169.38 -123.907 181.215 -95.0105C193.051 -66.1135 196.055 -34.3566 189.849 -3.75275C183.642 26.8511 168.503 54.9284 146.345 76.9314C124.187 98.9343 96.0034 113.875 65.3566 119.866C34.7098 125.857 2.97485 122.63 -25.8381 110.591C-54.6511 98.5524 -79.2491 78.2431 -96.5236 52.2295C-113.798 26.216 -122.974 -4.33443 -122.892 -35.5612C-122.837 -56.3265 -118.689 -76.8773 -110.683 -96.0374C-102.678 -115.198 -90.9725 -132.591 -76.2376 -147.223C-61.5027 -161.854 -44.0272 -173.437 -24.8112 -181.307C-5.59518 -189.178 14.9842 -193.182 35.7494 -193.09ZM35.7494 104.727C63.4779 104.645 90.5601 96.347 113.575 80.8819C136.591 65.4167 154.507 43.478 165.061 17.8364C175.615 -7.80507 178.334 -35.9991 172.874 -63.1849C167.414 -90.3706 154.02 -115.329 134.384 -134.907C114.748 -154.485 89.75 -167.804 62.5481 -173.184C35.3463 -178.563 7.16029 -175.761 -18.4498 -165.131C-44.0598 -154.5 -65.9453 -136.519 -81.342 -113.458C-96.7388 -90.3966 -104.956 -63.2898 -104.956 -35.5612C-105.124 -17.0444 -101.592 1.31931 -94.5666 18.4523C-87.5411 35.5853 -77.164 51.1424 -64.0443 64.2103C-50.9245 77.2782 -35.3266 87.5938 -18.1659 94.5515C-1.00523 101.509 17.3724 104.969 35.8883 104.727H35.7494Z" className='fill-black dark:fill-white' fillOpacity="0.6" />
				<path d="M35.8886 104.727C8.10106 104.671 -19.0439 96.3637 -42.1039 80.8592C-65.1638 65.3547 -83.0999 43.3512 -93.6375 17.6391C-104.175 -8.073 -106.84 -36.3353 -101.293 -63.5637C-95.7464 -90.7922 -82.2385 -115.76 -62.4825 -135.301C-42.7265 -154.842 -17.6124 -168.076 9.67513 -173.324C36.9626 -178.573 65.1941 -175.599 90.7894 -164.781C116.385 -153.963 138.191 -135.787 153.442 -112.559C168.693 -89.3308 176.703 -62.0966 176.455 -34.3101C176.161 2.73914 161.209 38.1646 134.867 64.2195C108.526 90.2743 72.9388 104.838 35.8886 104.727ZM35.8886 87.4865C60.1523 87.404 83.8474 80.1337 103.982 66.5936C124.116 53.0534 139.787 33.8507 149.015 11.4103C158.243 -11.03 160.615 -35.7018 155.831 -59.4892C151.047 -83.2767 139.322 -105.113 122.136 -122.241C104.949 -139.369 83.0733 -151.02 59.2698 -155.724C35.4662 -160.427 10.8027 -157.972 -11.6062 -148.667C-34.0152 -139.363 -53.1646 -123.627 -66.6364 -103.447C-80.1081 -83.2667 -87.2981 -59.5471 -87.2983 -35.2833C-87.2617 -2.67149 -74.3094 28.5982 -51.2754 51.6843C-28.2414 74.7704 2.99894 87.7933 35.6106 87.9036L35.8886 87.4865Z" className='fill-black dark:fill-white' fillOpacity="0.3" />
				<path d="M35.6111 87.9039C11.3539 87.739 -12.3108 80.3918 -32.3961 66.7895C-52.4814 53.1873 -68.0869 33.9398 -77.243 11.4764C-86.3991 -10.987 -88.6952 -35.6595 -83.8417 -59.4267C-78.9882 -83.194 -67.2027 -104.991 -49.9725 -122.066C-32.7423 -139.141 -10.8399 -150.729 12.9702 -155.367C36.7804 -160.006 61.431 -157.487 83.8107 -148.128C106.19 -138.769 125.296 -122.99 138.716 -102.783C152.136 -82.5756 159.269 -58.8454 159.215 -34.5877C159.142 -18.4288 155.887 -2.44257 149.636 12.4584C143.385 27.3594 134.26 40.8832 122.782 52.2576C111.305 63.6321 97.6989 72.6344 82.742 78.7506C67.7851 84.8669 51.77 87.9771 35.6111 87.9039ZM35.6111 76.0858C57.6102 76.0858 79.1154 69.5623 97.407 57.3402C115.699 45.1181 129.955 27.7464 138.374 7.42178C146.793 -12.9028 148.995 -35.2673 144.704 -56.8437C140.412 -78.4202 129.818 -98.2394 114.262 -113.795C98.7066 -129.351 78.8874 -139.945 57.3109 -144.236C35.7345 -148.528 13.3698 -146.325 -6.95473 -137.907C-27.2793 -129.488 -44.651 -115.231 -56.873 -96.9399C-69.0951 -78.6482 -75.6186 -57.1431 -75.6186 -35.1439C-75.5087 -5.78841 -63.7986 22.3334 -43.041 43.091C-22.2834 63.8486 5.83848 75.5588 35.194 75.6687L35.6111 76.0858Z" className='fill-black dark:fill-white' fillOpacity="0.1" />
				<path d="M35.1946 75.6684C13.1954 75.6684 -8.30972 69.1449 -26.6014 56.9228C-44.893 44.7008 -59.1495 27.329 -67.5683 7.00443C-75.987 -13.3201 -78.1897 -35.6847 -73.8979 -57.2611C-69.606 -78.8375 -59.0124 -98.6569 -43.4567 -114.213C-27.9009 -129.768 -8.08169 -140.362 13.4948 -144.654C35.0712 -148.946 57.4357 -146.743 77.7603 -138.324C98.0849 -129.905 115.457 -115.649 127.679 -97.3572C139.901 -79.0656 146.424 -57.5604 146.424 -35.5613C146.424 -6.06131 134.705 22.2304 113.846 43.09C92.9862 63.9496 64.6945 75.6684 35.1946 75.6684ZM129.323 -35.5613C129.323 -60.4518 119.435 -84.323 101.835 -101.923C84.2344 -119.524 60.3632 -129.411 35.4726 -129.411C10.582 -129.411 -13.2891 -119.524 -30.8894 -101.923C-48.4897 -84.323 -58.3774 -60.4518 -58.3774 -35.5613C-58.3774 -10.6707 -48.4897 13.2004 -30.8894 30.8007C-13.2891 48.401 10.582 58.2888 35.4726 58.2888C60.3632 58.2888 84.2344 48.401 101.835 30.8007C119.435 13.2004 129.323 -10.6707 129.323 -35.5613Z" className='fill-black dark:fill-white' fillOpacity="0.6" />
				<path d="M129.323 -35.5609C129.433 -16.9581 124.011 1.25777 113.748 16.7733C103.484 32.2889 88.8408 44.404 71.6776 51.5803C54.5143 58.7566 35.6055 60.6703 17.3525 57.0782C-0.900517 53.4861 -17.6742 44.5503 -30.8384 31.4057C-44.0026 18.2611 -52.9634 1.50067 -56.5827 -16.747C-60.2019 -34.9946 -58.3165 -53.9062 -51.1657 -71.0801C-44.015 -88.254 -31.9216 -102.915 -16.4213 -113.202C-0.921066 -123.489 17.2866 -128.937 35.8895 -128.855C48.1682 -128.928 60.3397 -126.567 71.6996 -121.906C83.0596 -117.245 93.3826 -110.378 102.072 -101.702C110.76 -93.0264 117.643 -82.7136 122.32 -71.3606C126.998 -60.0075 129.378 -47.8396 129.323 -35.5609ZM35.3334 -111.197C20.3313 -111.087 5.6983 -106.534 -6.71825 -98.114C-19.1348 -89.6936 -28.778 -77.783 -34.4303 -63.886C-40.0826 -49.989 -41.4904 -34.7288 -38.4761 -20.0323C-35.4618 -5.33575 -28.1605 8.1382 -17.4942 18.6882C-6.8278 29.2382 6.72536 36.3912 21.4541 39.2441C36.1828 42.097 51.4266 40.5218 65.2608 34.7175C79.0949 28.9131 90.899 19.1399 99.1828 6.63173C107.467 -5.87643 111.858 -20.5585 111.804 -35.5609C111.767 -45.5536 109.759 -55.4409 105.893 -64.6556C102.026 -73.8702 96.379 -82.2308 89.2743 -89.258C82.1697 -96.2851 73.7477 -101.84 64.4912 -105.605C55.2347 -109.37 45.3259 -111.27 35.3334 -111.197Z" className='fill-black dark:fill-white' fillOpacity="0.3" />
				<path d="M35.333 -111.198C50.3356 -111.17 64.9937 -106.698 77.4564 -98.346C89.9191 -89.9939 99.6277 -78.1363 105.356 -64.2705C111.085 -50.4047 112.576 -35.1522 109.643 -20.4393C106.709 -5.72633 99.4819 7.78748 88.8734 18.3959C78.265 29.0043 64.7511 36.2316 50.0381 39.1652C35.3252 42.0988 20.0729 40.6072 6.20707 34.8787C-7.65876 29.1501 -19.5163 19.4416 -27.8684 6.97884C-36.2206 -5.48387 -40.6927 -20.1419 -40.7202 -35.1444C-40.7569 -45.1422 -38.815 -55.0484 -35.0059 -64.2922C-31.1969 -73.536 -25.5962 -81.9347 -18.5267 -89.0042C-11.4571 -96.0737 -3.05849 -101.674 6.18532 -105.483C15.4291 -109.292 25.3353 -111.234 35.333 -111.198ZM35.333 29.0907C48.0647 29.1729 60.5349 25.478 71.1665 18.4731C81.7982 11.4682 90.1139 1.46796 95.0622 -10.263C100.01 -21.994 101.369 -34.9289 98.966 -47.4319C96.563 -59.935 90.5065 -71.4447 81.5622 -80.5057C72.6179 -89.5666 61.1876 -95.7719 48.7168 -98.3366C36.2459 -100.901 23.2945 -99.7106 11.5004 -94.9147C-0.293701 -90.1189 -10.4008 -81.9335 -17.5429 -71.3934C-24.685 -60.8534 -28.5413 -48.4322 -28.624 -35.7006C-28.6625 -18.697 -22.0008 -2.36272 -10.082 9.76432C1.83685 21.8914 18.0533 28.8348 35.055 29.0907H35.333Z" className='fill-black dark:fill-white' fillOpacity="0.1" />
				<path d="M35.0546 29.0921C22.3004 28.952 9.87526 25.0262 -0.644087 17.8127C-11.1634 10.5993 -19.3029 0.423416 -24.0294 -11.4235C-28.7559 -23.2705 -29.8565 -36.2545 -27.1915 -48.728C-24.5266 -61.2015 -18.2162 -72.6022 -9.06118 -81.4834C0.0938721 -90.3646 11.6809 -96.3259 24.2296 -98.6109C36.7782 -100.896 49.7229 -99.4016 61.4209 -94.3175C73.1189 -89.2335 83.0429 -80.7889 89.9336 -70.0554C96.8243 -59.3219 100.371 -46.7833 100.124 -34.0307C99.795 -17.0543 92.7722 -0.895485 80.5849 10.9272C68.3977 22.7498 52.0331 29.2786 35.0546 29.0921ZM82.0491 -35.143C82.1238 -47.4615 77.33 -59.311 68.7109 -68.1123C60.0918 -76.9137 48.3453 -81.9545 36.0279 -82.1376C23.6135 -81.9649 11.7437 -77.0129 2.88721 -68.3118C-5.96924 -59.6108 -11.1304 -47.8304 -11.5228 -35.4211C-11.4514 -23.0514 -6.53427 -11.2023 2.17353 -2.41656C10.8813 6.36922 22.686 11.3918 35.0546 11.5735C41.2231 11.6475 47.3445 10.4917 53.0614 8.17363C58.7782 5.85558 63.976 2.42169 68.351 -1.92744C72.726 -6.27657 76.1906 -11.4538 78.5425 -17.1568C80.8945 -22.8598 82.0865 -28.9742 82.0491 -35.143Z" className='fill-black dark:fill-white' fillOpacity="0.6" />
			</g>
			<g opacity="0.05">
				<path d="M311.132 593C196.844 593 112.309 509.578 113.004 404.466C113.443 367.61 124.723 331.699 145.437 301.213C166.152 270.726 195.383 247.013 229.486 233.03C263.59 219.048 301.054 215.416 337.208 222.587C373.362 229.757 406.605 247.414 432.79 273.354C450.61 290.447 464.738 311.011 474.303 333.776C483.868 356.542 488.667 381.025 488.404 405.717C489.094 452.095 471.804 496.939 440.159 530.85C423.967 549.391 404.186 564.457 382.01 575.139C359.834 585.821 335.722 591.897 311.132 593ZM300.287 575.203C333.806 575.203 366.574 565.272 394.453 546.664C422.333 528.056 444.074 501.605 456.933 470.65C469.792 439.696 473.191 405.626 466.702 372.741C460.213 339.856 444.127 309.63 420.474 285.88C396.821 262.13 366.662 245.92 333.804 239.297C300.945 232.674 266.861 235.934 235.855 248.666C204.848 261.398 178.308 283.031 159.586 310.834C140.864 338.637 130.799 371.364 130.662 404.883C130.552 427.222 134.859 449.362 143.336 470.031C151.813 490.699 164.292 509.488 180.056 525.317C195.82 541.145 214.558 553.701 235.192 562.263C255.825 570.824 277.948 575.222 300.287 575.203Z" className='fill-black dark:fill-white' fillOpacity="0.3" />
				<path d="M300.288 575.204C266.705 575.037 233.926 564.905 206.107 546.091C178.287 527.278 156.679 500.63 144.019 469.523C131.359 438.417 128.217 404.253 134.993 371.36C141.768 338.466 158.155 308.325 182.077 284.754C206 261.183 236.381 245.243 269.37 238.955C302.36 232.667 336.474 236.313 367.39 249.432C398.306 262.55 424.631 284.551 443.031 312.646C461.431 340.74 471.077 373.665 470.747 407.247C470.27 452.078 452.079 494.9 420.143 526.367C388.207 557.834 345.121 575.39 300.288 575.204ZM300.288 247.91C269.079 248.075 238.619 257.482 212.752 274.943C186.885 292.405 166.77 317.138 154.948 346.021C143.125 374.904 140.124 406.642 146.324 437.23C152.523 467.817 167.645 495.882 189.781 517.882C211.917 539.882 240.075 554.831 270.699 560.843C301.324 566.854 333.043 563.658 361.853 551.658C390.663 539.658 415.272 519.392 432.574 493.418C449.876 467.444 459.095 436.927 459.068 405.717C458.921 363.837 442.191 323.721 412.538 294.146C382.885 264.571 342.724 247.947 300.844 247.91H300.288Z" className='fill-black dark:fill-white' fillOpacity="0.1" />
				<path d="M300.843 247.91C332.07 248.047 362.555 257.438 388.446 274.895C414.337 292.352 434.473 317.093 446.309 345.989C458.145 374.886 461.149 406.643 454.942 437.247C448.736 467.851 433.597 495.928 411.439 517.931C389.28 539.934 361.097 554.875 330.45 560.866C299.804 566.857 268.069 563.63 239.256 551.591C210.443 539.552 185.845 519.243 168.57 493.23C151.296 467.216 142.12 436.666 142.202 405.439C142.256 384.674 146.405 364.123 154.411 344.963C162.416 325.802 174.121 308.409 188.856 293.777C203.591 279.146 221.067 267.563 240.283 259.693C259.499 251.822 280.078 247.818 300.843 247.91ZM300.843 545.727C328.572 545.645 355.654 537.347 378.669 521.882C401.685 506.417 419.601 484.478 430.155 458.836C440.709 433.195 443.428 405.001 437.968 377.815C432.508 350.629 419.114 325.671 399.478 306.093C379.841 286.515 354.844 273.196 327.642 267.816C300.44 262.437 272.254 265.239 246.644 275.869C221.034 286.5 199.148 304.481 183.752 327.542C168.355 350.603 160.138 377.71 160.138 405.439C159.969 423.956 163.502 442.319 170.527 459.452C177.553 476.585 187.93 492.142 201.049 505.21C214.169 518.278 229.767 528.594 246.928 535.551C264.089 542.509 282.466 545.969 300.982 545.727H300.843Z" className='fill-black dark:fill-white' fillOpacity="0.6" />
				<path d="M300.982 545.727C273.195 545.671 246.05 537.364 222.99 521.859C199.93 506.355 181.994 484.351 171.456 458.639C160.919 432.927 158.254 404.665 163.801 377.436C169.347 350.208 182.855 325.24 202.611 305.699C222.367 286.158 247.481 272.924 274.769 267.676C302.056 262.427 330.288 265.401 355.883 276.219C381.478 287.037 403.284 305.213 418.536 328.441C433.787 351.669 441.797 378.903 441.549 406.69C441.255 443.739 426.303 479.165 399.961 505.219C373.62 531.274 338.033 545.838 300.982 545.727ZM300.982 528.486C325.246 528.404 348.941 521.134 369.076 507.594C389.21 494.053 404.881 474.851 414.109 452.41C423.337 429.97 425.709 405.298 420.925 381.511C416.141 357.723 404.415 335.887 387.229 318.759C370.043 301.631 348.167 289.98 324.364 285.276C300.56 280.573 275.896 283.028 253.488 292.333C231.079 301.637 211.929 317.373 198.457 337.553C184.986 357.733 177.796 381.453 177.795 405.717C177.832 438.329 190.784 469.598 213.818 492.684C236.852 515.77 268.093 528.793 300.704 528.904L300.982 528.486Z" className='fill-black dark:fill-white' fillOpacity="0.3" />
				<path d="M300.705 528.904C276.448 528.739 252.783 521.392 232.698 507.79C212.612 494.187 197.007 474.94 187.851 452.476C178.695 430.013 176.399 405.341 181.252 381.573C186.106 357.806 197.891 336.009 215.121 318.934C232.351 301.859 254.254 290.271 278.064 285.633C301.874 280.994 326.525 283.513 348.904 292.872C371.284 302.231 390.39 318.01 403.81 338.217C417.23 358.424 424.363 382.155 424.309 406.412C424.236 422.571 420.981 438.557 414.73 453.458C408.479 468.359 399.354 481.883 387.876 493.258C376.398 504.632 362.793 513.634 347.836 519.751C332.879 525.867 316.864 528.977 300.705 528.904ZM300.705 517.086C322.704 517.086 344.209 510.562 362.501 498.34C380.792 486.118 395.049 468.746 403.468 448.422C411.886 428.097 414.089 405.733 409.797 384.156C405.505 362.58 394.912 342.761 379.356 327.205C363.8 311.649 343.981 301.055 322.405 296.764C300.828 292.472 278.464 294.675 258.139 303.093C237.814 311.512 220.443 325.769 208.221 344.06C195.999 362.352 189.475 383.857 189.475 405.856C189.585 435.212 201.295 463.333 222.053 484.091C242.81 504.849 270.932 516.559 300.288 516.669L300.705 517.086Z" className='fill-black dark:fill-white' fillOpacity="0.1" />
			</g>
		</svg>

	)
}

export default InvPattern