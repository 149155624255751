import React from 'react'
import CustomButton from './CustomButton'
import {useNavigate} from 'react-router'
import RightArrIcon from './Icons/RightArrIcon';

const WalletWarning = ({err}) => {
	const navigate = useNavigate();
	return (
		<>
			<p className='text-red-500 text-sm font-medium mx-auto max-w-[18rem] text-center'>{err}</p>
			<CustomButton onClick={() => navigate('/profile')} type="button" className={`mx-auto mt-4 px-6`}>
				<span>Go to Profile</span>
				<RightArrIcon className="fill-white" />
			</CustomButton>
		</>
	)
}

export default WalletWarning