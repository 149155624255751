import React, {useState} from 'react'
import Greeting from '../../../components/Greeting'
import {corporatePersonalInfoInputs, corporatePersonalInfoSchema, getDefault, individualPersonalInfoInputs, individualPersonalInfoSchema} from '../../../services/reg-inputs'
import {useFormik} from 'formik'
import {useDispatch, useSelector} from 'react-redux'
import CustomInput from '../../../components/CustomInput'
import CustomButton from '../../../components/CustomButton'
import RightArrIcon from '../../../components/Icons/RightArrIcon'
import axios from 'axios'
import {BURL} from '../../../services/base'
import {setProfile} from '../../../slices/userSlice'
import {toast} from 'react-toastify'
import ProfileGoTo from '../../../components/ProfileGoTo';

const PersonalDetails = () => {
	const {data, customer, profileCompletion: {banks: bankDetailsUpdated}} = useSelector(state => state.profile);
	const [inProgress, setInProgress] = useState(false);
	const isCORP = (customer?.label === "CORP");

	const dispatch = useDispatch()
	const initialValues = getDefault((isCORP ? corporatePersonalInfoInputs : individualPersonalInfoInputs), {...data, ...customer});
	const personalInfo = useFormik({
		validationSchema: (isCORP ? corporatePersonalInfoSchema : individualPersonalInfoSchema),
		initialValues,
		onSubmit: (values) => {
			const body = {...data, ...customer, ...values, email: customer.email}
			setInProgress(true);
			axios.post(`${BURL}edit-customer`, {...body, customer_id: customer.id, rm_id: customer.rm_id}).then(res => {
				if (!res?.data?.success) throw new Error(res);
				axios.post(`${BURL}get-customer`).then(res => {
					if (!res?.data?.success) throw new Error(res);
					setInProgress(false);
					// successRes("Updated successfully");
					toast.success("Updated successfully")
					dispatch(setProfile(res.data.message));
				}).catch(err => {
					toast.error("Can't fetch updated details")
				})
			}).catch(err => {
				setInProgress(false);
				if (err?.response?.data) {
					toast.error(err?.response?.data?.message)
				} else {
					toast.error("An error occured")
				}
			})
		}
	})


	return (
		<div>
			<Greeting showBack primaryBack noPre head={isCORP ? 'Company Details' : `Personal Details`} sub={'Make changes to your account details here'} />
			<div>
				<form onSubmit={personalInfo.handleSubmit} className='flex w-full lg:w-2/3 xl:w-1/2 flex-wrap'>
					{(isCORP ? corporatePersonalInfoInputs : individualPersonalInfoInputs).map((inputProps, i) => (
						<div key={i} className={`px-3 ${inputProps.class ? inputProps.class : 'lg:w-1/2'} w-full`}>
							<CustomInput
								className="min-w-full"
								key={i}
								formik={personalInfo}
								initialValues={initialValues}
								{...{
									...inputProps,
									disableUpdate: (bankDetailsUpdated || inputProps.rigid) ? inputProps.disableUpdate : false
								}}
							/>
						</div>
					))}
					<div className='flex space-x-2 pb-2 pt-6 w-full px-3'>
						<CustomButton type="submit" className="w-full" loading={inProgress}>
							<span>Update Details</span>
							{!inProgress && <RightArrIcon />}
						</CustomButton>
					</div>
					<div className='flex justify-end py-4 w-full px-3'>
						<ProfileGoTo next to={"/profile/banks"} />
					</div>
				</form>
			</div>
		</div>
	)
}

export default PersonalDetails