import React from 'react'
import LoadingIcon from './Icons/LoadingIcon'

const CustomButton = ({onClick, className, children, light, dark, loading, ...props}) => {
	return (
		<button disabled={loading} {...props} onClick={onClick} className={`${className ? className : ''} rounded-lg px-4 py-[.65rem] font-medium ${light ? 'bg-light' : dark ? 'bg-dark dark:bg-white dark:text-dark text-white' : 'bg-dark dark:bg-primary text-white'} space-x-3 outline-none text-sm flex items-center justify-center`
		}>
			{
				loading && <LoadingIcon className={`${light ? ' fill-primary' : 'fill-white'}`} />
			}
			{children}
		</button >
	)
}

export default CustomButton