import React from 'react'

const RightArrAlt = () => {
  return (
    <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.2771 8.07509C0.162516 7.97687 0.105225 7.86059 0.105225 7.72623C0.105225 7.59187 0.162516 7.47572 0.2771 7.37777L3.63439 4.50009L0.265641 1.61259C0.158697 1.52092 0.105225 1.40634 0.105225 1.26884C0.105225 1.13134 0.162516 1.01348 0.2771 0.915266C0.391683 0.817051 0.52735 0.767944 0.6841 0.767944C0.84085 0.767944 0.976363 0.817051 1.09064 0.915266L4.94064 4.22509C4.98647 4.26437 5.01902 4.30693 5.03827 4.35277C5.05752 4.3986 5.06699 4.44771 5.06668 4.50009C5.06668 4.55247 5.05706 4.60158 5.03781 4.64741C5.01856 4.69324 4.98617 4.7358 4.94064 4.77509L1.07918 8.08491C0.972238 8.17658 0.840391 8.22241 0.683641 8.22241C0.526891 8.22241 0.391377 8.1733 0.2771 8.07509Z" className='fill-current' />
    </svg>

  )
}

export default RightArrAlt