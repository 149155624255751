import React from 'react'

const TimeoutIllus = () => {
	return (
		<svg width="180" height="200" viewBox="0 0 210 204" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M208.455 202.26C207.049 204.862 175.79 186.859 169.285 181.655C169.285 181.655 144.988 188.793 120.023 192.274C115.804 192.872 111.549 193.329 107.4 193.645C98.504 194.348 90.03 194.313 83.1382 193.012C75.8246 191.641 69.4251 189.918 63.8344 187.597C47.6248 180.846 38.1662 169.067 32.1536 146.458C32.0129 145.93 31.8723 145.403 31.7316 144.84C30.1494 138.617 28.567 132.499 27.1254 126.31C21.3237 100.958 18.5108 74.4815 32.5755 36.6474C39.8892 16.9919 77.7937 8.76405 111.057 3.31397C144.32 -2.13612 157.927 -2.73387 178.04 16.2184C198.153 35.1706 199.313 41.0778 201.739 68.7502C203.849 92.6954 203.251 125.607 201.212 142.098C200.895 144.665 200.544 146.845 200.157 148.532C197.309 160.945 191.05 161.894 191.296 163.195C191.542 164.496 209.897 199.658 208.455 202.26Z" fill="#ed7627" />
			<path d="M85.6349 126.697C95.5506 141.641 105.431 174.411 107.435 192.027V193.645C98.5393 194.348 90.0653 194.313 83.1736 193.012C75.8599 191.641 69.4605 189.918 63.8697 187.597C63.9752 184.749 64.2917 175.853 64.1862 174.763C64.0807 173.462 61.6897 171.317 59.4745 169.981C57.2593 168.68 57.2945 155.6 59.4745 154.721C59.4745 154.721 57.8571 152.259 56.0638 152.154C54.2706 152.084 45.5504 150.994 38.6939 146.247C36.7951 146.704 34.5799 146.809 32.1889 146.458C31.9076 146.423 31.6263 146.352 31.345 146.317C20.8316 144.313 22.0623 137.105 28.3211 135.382C22.5897 133.272 23.082 128.631 27.1608 126.31C27.5476 126.099 27.9343 125.923 28.3563 125.747L27.9343 124.974L32.6812 103.455C38.0258 100.677 38.94 98.286 54.0596 102.154C67.4563 105.6 79.4113 122.196 81.9429 125.923C82.6813 124.446 83.701 123.814 85.6349 126.697Z" fill="#FFFEF2" />
			<path d="M208.455 202.26C207.049 204.862 175.79 186.859 169.285 181.655C169.285 181.655 144.988 188.793 120.023 192.274L120.199 191.149C123.153 177.014 134.826 153.033 134.826 153.033C134.826 153.033 130.115 144.419 130.115 137.246C130.115 133.87 130.748 132.358 132.963 131.444C131.873 123.04 127.442 107.217 127.442 107.217C126.599 106.198 126.352 105.108 126.352 105.108C126.352 105.108 123.293 109.819 118.933 108.518C114.679 107.253 115.839 102.576 115.874 102.365C115.769 102.471 113.589 104.44 111.725 103.068C110.108 101.908 111.127 96.915 114.186 93.7153C101.669 93.0824 100.227 92.5198 100.473 90.5859C100.755 88.441 112.991 63.1596 122.133 51.7672C122.133 51.7672 122.133 51.7672 122.168 51.7321C122.203 51.6969 122.203 51.6617 122.274 51.6266C122.379 51.5211 122.485 51.3453 122.696 51.1343C122.766 51.0288 122.836 50.9585 122.942 50.853C122.977 50.7827 123.047 50.7475 123.117 50.6772C126.001 47.2665 135.037 37.2805 142.07 36.7531C156.838 35.6982 167.527 50.1498 170.516 54.7208C170.516 54.7208 178.251 43.7152 184.756 50.2904C192.351 57.9557 176.634 69.2075 176.634 69.2075C176.634 69.2075 180.537 83.7997 187.956 104.264C188.87 106.831 189.82 109.433 190.699 111.929C195.586 125.607 199.806 137.949 201.212 142.168C200.896 144.735 200.544 146.915 200.157 148.603C197.309 161.015 191.05 161.964 191.296 163.265C191.542 164.496 209.897 199.658 208.455 202.26Z" fill="#FFFEF2" />
			<path d="M127.689 73.1805C126.88 73.0398 126.353 72.2663 126.493 71.4927L127.161 67.8007C127.302 66.992 128.076 66.4646 128.849 66.6052C129.658 66.7458 130.185 67.5194 130.045 68.293L129.377 71.985C129.236 72.7585 128.498 73.3211 127.689 73.1805Z" fill="#171819" />
			<path d="M139.714 73.7083C138.906 73.5677 138.378 72.7941 138.519 72.0205L139.187 68.3285C139.328 67.5198 140.101 66.9924 140.875 67.133C141.683 67.2737 142.211 68.0472 142.07 68.8208L141.402 72.5128C141.261 73.2864 140.523 73.8489 139.714 73.7083Z" fill="#171819" />
			<path d="M129.27 95.614H129.235C105.395 94.6295 101.387 94.3834 99.8399 92.555C99.1367 91.7111 99.2422 90.7266 99.3125 90.4101C99.629 87.7378 116.753 52.2595 126.211 46.0007C126.774 45.6139 127.547 45.7897 127.899 46.3523C128.286 46.9149 128.11 47.6885 127.547 48.0401C119.109 53.5956 102.196 88.0542 101.704 90.7266C101.668 90.9375 101.704 90.9727 101.704 91.0078C102.547 92.0275 112.041 92.4495 129.306 93.1527C129.974 93.1879 130.501 93.7505 130.501 94.4185C130.466 95.0866 129.938 95.614 129.27 95.614Z" fill="#171819" />
			<path d="M122.168 51.7319C126.352 57.1819 134.826 57.4281 142.773 51.2747C150.719 45.1214 153.251 64.4956 161.831 61.6475C170.41 58.7994 171.359 55.9864 171.359 55.9864C171.359 55.9864 159.58 35.4168 142.07 36.6826C133.596 37.3155 122.168 51.7319 122.168 51.7319Z" fill="#171819" />
			<path d="M160.143 63.1945C156.31 63.1945 153.603 59.4674 151.388 56.4083C149.665 54.0525 147.907 51.5911 146.113 51.345C145.34 51.2395 144.496 51.5208 143.511 52.2944C138.835 55.916 133.736 57.6038 129.13 57.0061C125.86 56.5841 123.118 55.037 121.184 52.5054C120.832 52.0482 120.832 51.4505 121.184 50.9934C121.676 50.3956 133.033 36.1199 141.964 35.487C160.073 34.186 171.922 54.5447 172.414 55.4238C172.59 55.7402 172.625 56.0919 172.52 56.4435C172.344 57.0061 170.938 59.9597 162.218 62.8429C161.479 63.0539 160.811 63.1945 160.143 63.1945ZM145.797 48.8485C146.008 48.8485 146.254 48.8485 146.465 48.8837C149.278 49.2705 151.282 52.0131 153.357 54.9315C156.275 58.94 158.385 61.5068 161.444 60.4871C167.105 58.5884 169.215 56.7951 169.918 55.9864C167.773 52.6108 156.978 36.8583 142.175 37.9132C135.424 38.4054 126.282 48.743 123.786 51.6966C125.262 53.2438 127.196 54.2283 129.482 54.5096C133.42 55.0018 137.85 53.525 142.035 50.2902C143.265 49.3408 144.566 48.8485 145.797 48.8485Z" fill="#171819" />
			<path d="M174.524 62.7726C174.384 62.7726 174.243 62.7375 174.102 62.7023C173.469 62.4562 173.153 61.7529 173.364 61.12C175.017 56.5841 179.236 53.4899 179.412 53.3492C179.974 52.9625 180.748 53.068 181.135 53.6305C181.521 54.1931 181.416 54.9667 180.853 55.3535C180.818 55.3886 177.056 58.1664 175.649 61.9639C175.474 62.4913 175.017 62.7726 174.524 62.7726Z" fill="#171819" />
			<path d="M115.981 92.2737C111.621 95.0866 109.898 101.697 111.797 103.068C113.695 104.44 115.946 102.33 115.946 102.33C115.946 102.33 114.645 107.182 119.005 108.483C123.365 109.784 126.424 105.073 126.424 105.073C126.424 105.073 127.655 110.101 133.105 108.729C137.113 107.71 138.273 103.42 138.273 103.42C138.273 103.42 140.594 106.338 145.306 104.932C150.018 103.561 150.439 94.2779 139.645 91.0782C130.925 88.5114 118.864 90.375 115.981 92.2737Z" fill="#171819" />
			<path d="M131.205 110.206C130.045 110.206 128.99 109.925 128.076 109.362C127.162 108.8 126.494 108.061 126.037 107.358C124.56 108.835 121.923 110.663 118.582 109.679C115.629 108.8 114.644 106.549 114.468 104.545C113.378 104.897 112.148 104.897 111.022 104.053C110.425 103.596 109.686 102.682 109.757 100.748C109.827 97.7941 111.796 93.4692 115.277 91.2189C118.512 89.1443 130.819 87.1752 139.926 89.8827C147.099 92.0276 149.63 96.5986 149.63 100.185C149.63 103.068 148.048 105.389 145.587 106.092C142.352 107.042 140.066 106.233 138.695 105.389C137.886 106.936 136.269 109.151 133.35 109.89C132.612 110.136 131.909 110.206 131.205 110.206ZM126.353 103.877C126.423 103.877 126.494 103.877 126.564 103.877C127.056 103.947 127.443 104.334 127.549 104.791C127.549 104.791 127.97 106.444 129.377 107.253C130.256 107.78 131.381 107.85 132.752 107.534C136.023 106.69 137.007 103.139 137.042 103.104C137.148 102.646 137.534 102.33 137.992 102.225C138.449 102.119 138.906 102.295 139.187 102.646C139.257 102.717 141.121 104.862 144.918 103.772C146.29 103.385 147.169 101.978 147.204 100.22C147.204 97.5128 145.129 93.9966 139.257 92.2737C130.608 89.7069 119.039 91.7814 116.648 93.3286C113.976 95.0515 112.323 98.4974 112.253 100.853C112.218 101.697 112.429 102.049 112.499 102.084C113.519 102.822 115.066 101.451 115.066 101.451C115.453 101.064 116.086 101.029 116.543 101.31C117 101.592 117.246 102.154 117.105 102.682C117 103.068 116.226 106.444 119.321 107.358C122.767 108.378 125.298 104.51 125.333 104.475C125.579 104.088 125.966 103.877 126.353 103.877Z" fill="#171819" />
			<path d="M132.963 132.675C132.366 132.675 131.838 132.218 131.733 131.62C130.678 123.392 126.318 107.745 126.248 107.569C126.072 106.901 126.459 106.233 127.091 106.057C127.76 105.881 128.428 106.268 128.603 106.901C128.779 107.534 133.069 122.9 134.159 131.303C134.229 131.971 133.772 132.604 133.104 132.675C133.069 132.64 133.034 132.675 132.963 132.675Z" fill="#171819" />
			<path d="M149.876 154.475C148.646 154.475 147.415 154.44 146.219 154.335C145.551 154.264 145.024 153.667 145.094 152.999C145.165 152.33 145.762 151.803 146.43 151.873C158.315 152.893 172.767 148.568 184.124 140.622C184.686 140.235 185.46 140.375 185.847 140.938C186.234 141.501 186.093 142.274 185.53 142.661C174.841 150.115 161.515 154.475 149.876 154.475Z" fill="#171819" />
			<path d="M81.6622 126.732C81.2754 126.732 80.8887 126.556 80.6425 126.205C77.478 121.669 66.2262 106.549 53.7789 103.385C41.1206 100.15 38.9054 101.415 35.2134 103.49C34.6156 103.842 33.9827 104.193 33.2795 104.58C32.6817 104.897 31.9434 104.65 31.6269 104.053C31.3104 103.455 31.5566 102.716 32.1543 102.4C32.8224 102.048 33.4202 101.697 34.0179 101.38C38.4483 98.8487 41.4019 97.6883 54.4118 101.029C67.6678 104.439 79.4119 120.122 82.7171 124.833C83.1039 125.396 82.9632 126.169 82.4006 126.556C82.1545 126.662 81.9084 126.732 81.6622 126.732Z" fill="#171819" />
			<path d="M59.4754 155.952C59.0886 155.952 58.6667 155.741 58.4557 155.39C57.9283 154.616 56.8031 153.42 55.9944 153.385C55.8889 153.385 45.7975 152.752 37.7454 147.091C37.1828 146.705 37.0421 145.931 37.4641 145.368C37.8509 144.806 38.6244 144.665 39.187 145.087C45.4458 149.482 53.744 150.819 56.135 150.924C58.4909 151.029 60.3193 153.737 60.5302 154.053C60.917 154.616 60.7412 155.39 60.1786 155.741C59.9325 155.882 59.6864 155.952 59.4754 155.952Z" fill="#171819" />
			<path d="M32.2607 135.031C53.4633 136.754 44.4619 148.814 31.3114 146.318C18.8641 143.927 22.8725 134.257 32.2607 135.031Z" fill="#FFFEF2" />
			<path d="M34.8974 147.9C33.7019 147.9 32.4009 147.794 31.0648 147.513C23.9973 146.177 21.8875 142.59 22.2743 139.707C22.7314 136.437 26.5992 133.343 32.3306 133.8C41.7891 134.574 44.8834 137.246 45.7976 139.391C46.4657 140.938 46.1844 142.59 44.9889 144.032C43.3714 146.107 39.7146 147.9 34.8974 147.9ZM31.2406 136.226C27.5134 136.226 24.9818 138.125 24.7357 140.059C24.4192 142.309 27.0915 144.278 31.5219 145.122C36.9016 146.142 41.4375 144.595 43.0901 142.52C43.6879 141.782 43.8285 141.043 43.5472 140.375C43.2308 139.602 41.4024 137.035 32.1548 136.296C31.8735 136.226 31.5571 136.226 31.2406 136.226Z" fill="#171819" />
			<path d="M32.6823 125.01C54.7991 124.939 46.5361 137.738 32.6823 136.332C19.5669 134.996 22.8722 125.045 32.6823 125.01Z" fill="#FFFEF2" />
			<path d="M34.8268 137.668C34.0884 137.668 33.35 137.633 32.5413 137.562C25.1573 136.824 22.6608 133.448 22.8015 130.53C22.9421 127.26 26.4583 123.814 32.6468 123.779C32.7171 123.779 32.7874 123.779 32.8578 123.779C40.5582 123.779 45.4809 125.326 47.028 128.244C47.8016 129.686 47.6609 131.303 46.6412 132.815C45.0589 135.277 40.734 137.668 34.8268 137.668ZM32.682 126.24C28.2516 126.24 25.3683 128.526 25.2628 130.635C25.1573 132.886 28.1109 134.644 32.7875 135.101C38.5188 135.664 43.1602 133.624 44.637 131.444C45.1292 130.706 45.2347 130.002 44.8831 129.405C44.4963 128.666 42.4218 126.24 32.8929 126.24C32.8226 126.24 32.7523 126.24 32.682 126.24Z" fill="#171819" />
			<path d="M33.6706 134.439C42.7093 129.317 46.2027 118.399 41.4733 110.053C36.7439 101.707 25.5826 99.0932 16.5438 104.215C7.50508 109.337 4.0117 120.255 8.74112 128.601C13.4706 136.947 24.6318 139.561 33.6706 134.439Z" fill="#2D23E4" />
			<path d="M25 127.333C29.1421 127.333 32.5 123.975 32.5 119.833C32.5 115.691 29.1421 112.333 25 112.333C20.8579 112.333 17.5 115.691 17.5 119.833C17.5 123.975 20.8579 127.333 25 127.333Z" stroke="white" strokeWidth="1.25" />
			<path d="M25.0003 116.5V119.833L27.0837 121.917M17.917 112.75L21.2503 110.667M32.0837 112.75L28.7503 110.667" stroke="white" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M47.3446 107.217C36.8312 112.597 30.8185 117.871 33.2095 121.282C36.0225 125.29 49.595 119.243 49.595 119.243C49.595 119.243 49.8059 134.644 61.6555 138.16" fill="#FFFEF2" />
			<path d="M61.655 139.355C61.5495 139.355 61.4089 139.355 61.3034 139.32C51.1064 136.296 48.9264 125.396 48.4693 121.036C44.3202 122.724 34.9671 125.924 32.1893 121.985C31.4861 120.966 31.2752 119.805 31.5916 118.54C32.6816 114.109 40.4524 109.362 46.7815 106.127C47.3793 105.811 48.1177 106.057 48.4341 106.655C48.7506 107.253 48.5045 107.991 47.9067 108.307C36.3033 114.215 34.3342 117.836 34.0178 119.137C33.8771 119.7 33.9475 120.157 34.2639 120.579C35.7759 122.759 44.0389 120.403 49.1374 118.118C49.5242 117.942 49.9461 117.977 50.2977 118.188C50.6493 118.399 50.8603 118.786 50.8603 119.208C50.8603 119.348 51.2119 133.729 62.0418 136.964C62.7098 137.175 63.0615 137.843 62.8857 138.511C62.6747 139.004 62.1824 139.355 61.655 139.355Z" fill="#171819" />
			<path d="M107.436 194.454C106.804 194.454 106.276 193.997 106.206 193.364C104.096 175.044 94.0046 141.535 84.5813 127.4C83.9484 126.451 83.5264 126.099 83.3506 125.994C82.999 126.416 82.5419 127.857 82.3309 128.596L82.2606 128.807C79.9048 136.472 73.8569 150.185 60.0031 155.881C59.6163 156.338 58.9835 158.729 59.0538 162.808C59.1241 166.746 59.7922 168.68 60.1086 168.997C62.0074 170.122 65.2071 172.583 65.3829 174.693C65.4884 176.029 64.961 185.277 64.8555 187.105C64.8203 187.773 64.2226 188.301 63.5545 188.265C62.8864 188.23 62.359 187.632 62.3942 186.964C62.6403 182.78 63.0271 175.818 62.9567 174.939C62.8864 174.341 61.2338 172.513 58.8428 171.106C55.9947 169.419 56.6628 159.89 56.6979 159.784C57.0496 154.967 58.2099 153.983 59.0186 153.631C71.9933 148.357 77.6896 135.347 79.9399 128.103L80.0102 127.892C80.608 125.959 81.276 123.814 83.0341 123.532C84.6164 123.286 85.8823 124.869 86.6558 126.064C97.2044 141.992 106.733 176.416 108.667 193.118C108.737 193.786 108.245 194.419 107.577 194.489C107.507 194.454 107.472 194.454 107.436 194.454Z" fill="#171819" />
			<path d="M207.894 203.737C202.092 203.737 175.193 187.632 169.04 183.026C165.102 184.151 142.809 190.375 120.2 193.505C119.532 193.61 118.899 193.118 118.829 192.45C118.723 191.782 119.215 191.149 119.884 191.078C144.462 187.668 168.723 180.565 168.97 180.495C169.356 180.389 169.778 180.459 170.095 180.706C176.811 186.085 202.233 200.291 207.296 201.24C206.487 197.021 197.732 179.194 193.829 171.282C190.7 164.918 190.207 163.863 190.137 163.406C189.926 162.316 190.77 161.648 191.684 160.98C193.442 159.644 197.064 156.831 199.033 148.251C199.455 146.423 199.842 143.926 200.193 140.832C200.264 140.164 200.896 139.672 201.565 139.742C202.233 139.812 202.725 140.445 202.655 141.113C202.303 144.278 201.881 146.88 201.424 148.814C199.279 158.237 195.165 161.402 193.196 162.949C193.055 163.054 192.88 163.195 192.739 163.336C193.231 164.426 194.638 167.274 196.079 170.227C208.808 196.071 210.531 201.17 209.617 202.858C209.476 203.104 209.124 203.561 208.421 203.701C208.21 203.701 208.07 203.737 207.894 203.737Z" fill="#ed7627" />
			<path d="M201.388 142.203C200.896 142.203 200.404 141.887 200.228 141.395C200.157 141.219 193.899 124.2 186.796 104.615C179.447 84.3269 175.509 69.5941 175.439 69.4534C175.298 68.9612 175.509 68.4337 175.896 68.1173C176.001 68.0469 185.038 61.5068 185.565 55.4942C185.706 53.8064 185.143 52.3648 183.878 51.0638C182.612 49.7979 181.276 49.2354 179.764 49.3408C175.544 49.6925 171.536 55.248 171.501 55.3184C171.114 55.8809 170.34 56.0216 169.778 55.5997C169.215 55.2129 169.074 54.4393 169.496 53.8767C169.672 53.5954 174.173 47.3015 179.553 46.8443C181.803 46.6685 183.842 47.4773 185.6 49.2705C187.394 51.0989 188.202 53.2438 187.991 55.6348C187.464 61.753 180.326 67.7656 178.005 69.5237C178.92 72.8289 182.717 86.1553 189.046 103.666C196.114 123.216 202.408 140.269 202.478 140.445C202.724 141.078 202.373 141.781 201.74 142.027C201.669 142.168 201.529 142.203 201.388 142.203Z" fill="#171819" />
			<path d="M119.426 193.575C119.355 193.575 119.25 193.575 119.18 193.54C118.511 193.399 118.09 192.731 118.23 192.098C120.973 178.983 131.556 156.936 133.455 153.033C132.4 150.959 128.884 143.61 128.884 137.246C128.884 130.424 131.697 130.002 139.995 128.666C141.648 128.42 143.512 128.104 145.692 127.717C161.268 124.904 180.959 118.645 189.89 110.945C190.417 110.488 191.191 110.558 191.613 111.085C192.07 111.613 192 112.386 191.472 112.808C181.557 121.353 160.811 127.506 146.078 130.143C143.898 130.53 142 130.846 140.347 131.092C131.943 132.429 131.31 132.64 131.31 137.246C131.31 143.997 135.846 152.33 135.881 152.436C136.057 152.787 136.092 153.209 135.917 153.561C135.776 153.807 123.504 178.807 120.621 192.626C120.516 193.188 120.023 193.575 119.426 193.575Z" fill="#171819" />
		</svg>


	)
}

export default TimeoutIllus