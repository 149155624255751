import axios from 'axios';
import {useFormik} from 'formik';
import React, {useState} from 'react'
import {useNavigate} from 'react-router';
import {toast} from 'react-toastify';
import Swal from 'sweetalert2';
import {BURL} from '../../services/base';
import {corporateRegInputs, corporateRegSchema, getDefault} from '../../services/reg-inputs';
import CustomButton from '../CustomButton';
import CustomInput from '../CustomInput';
import RightArrIcon from '../Icons/RightArrIcon';
import SuccessIllus from '../Illustrations/SuccessIllus';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const CoporateReg = () => {
	const navigate = useNavigate()
	const [inProgress, setInProgress] = useState(false)

	const formik = useFormik({
		validationSchema: corporateRegSchema,
		initialValues: getDefault(corporateRegInputs),
		onSubmit: (values) => {
			const body = {...values, label: 'CORP'}
			setInProgress(true);
			axios.post(`${BURL}register`, body).then(res => {
				if (!res?.data?.success) throw new Error(res);
				setInProgress(false);
				registrationSuccess();
			}).catch(err => {
				setInProgress(false);
				if (err?.response?.data) {
					toast.error(err?.response?.data?.message)
				} else {
					toast.error("An error occured")
				}
			})
		},
	})

	const registrationSuccess = () => {
		MySwal.fire({
			title: 'Signup Successful',
			text: 'Kindly proceed to login to your account',
			iconHtml: <SuccessIllus />,
			didClose: () => navigate("/login"),
			confirmButtonText: 'Continue',
			customClass: {
				icon: 'swal-reg-success-icon'
			}
		})
	}


	return (
		<form onSubmit={formik.handleSubmit} className="">
			{corporateRegInputs.map((each) => (
				<CustomInput className='' key={each.name} inputProps={each} {...each} formik={formik} />
			))}
			<div className="fm-group pt-7">
				<CustomButton loading={inProgress} type="submit" className="py-3 font-medium justify-center text-md text-white " >
					<span>Get Started</span>
					{!inProgress && <RightArrIcon />}
				</CustomButton>
			</div>
		</form>
	)
}

export default CoporateReg