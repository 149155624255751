import React from 'react'

const BookIcon = () => {
	return (
		<svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M1.33337 6.1665C1.33337 3.80984 1.33337 2.63067 2.06587 1.899C2.79754 1.1665 3.97671 1.1665 6.33337 1.1665H9.66671C12.0234 1.1665 13.2025 1.1665 13.9342 1.899C14.6667 2.63067 14.6667 3.80984 14.6667 6.1665V12.8332C14.6667 15.1898 14.6667 16.369 13.9342 17.1007C13.2025 17.8332 12.0234 17.8332 9.66671 17.8332H6.33337C3.97671 17.8332 2.79754 17.8332 2.06587 17.1007C1.33337 16.369 1.33337 15.1898 1.33337 12.8332V6.1665Z" className='stroke-current' strokeWidth="1.25" />
			<path d="M14.5817 12.833H4.58171C3.80671 12.833 3.41921 12.833 3.10087 12.918C2.67697 13.0317 2.29045 13.2549 1.98019 13.5654C1.66992 13.8758 1.44684 14.2624 1.33337 14.6863" className='stroke-current' strokeWidth="1.25" />
			<path d="M4.66675 5.33301H11.3334M4.66675 8.24967H8.83341M14.2501 15.333H4.66675" className='stroke-current' strokeWidth="1.25" strokeLinecap="round" />
		</svg>

	)
}

export default BookIcon