import {Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material'
import React, {useEffect, useRef, useState} from 'react'
import Swal from 'sweetalert2'
import CustomButton from '../CustomButton'
import RightArrIcon from '../Icons/RightArrIcon'
import axios from 'axios'
import {BURL} from '../../services/base'
import CurrencyFormat from 'react-currency-format'
import {useDispatch} from 'react-redux'
import {setTransactions} from '../../slices/userSlice'
import BankNoteIcon from '../Icons/BankNoteIcon'
import UploadIcon from '../Icons/UploadIcon'
import LayersIcon from '../Icons/LayersIcon'
import WalletWarning from '../WalletWarning'

const OldFund = ({setOpen, open, wallet}) => {
	const [inProgress, setInProgress] = useState(false)
	const [amount, setAmount] = useState(0);
	const [receipt, setReceipt] = useState(null);
	const [err, setErr] = useState(null);
	const [fileErr, setFileErr] = useState(null);
	const receiptRef = useRef(null);

	const successRes = () => {
		setOpen(false)
		Swal.fire({
			title: 'Deposit successful',
			html: `<p>Please note that balance top-up will reflect after confirmation.</p> <br/>
			<p>Also note that funds received after our cut-off time of 2pm daily, on a weekend or public holiday, will receive next working day value in our records</p>`,
			icon: 'success',
			// didClose: () => setOpen(false),
			confirmButtonText: 'Continue',
		})
	}

	const errorRes = (res) => {
		Swal.fire({
			title: 'Can\'t Fund Wallet',
			text: res,
			icon: 'error',
			confirmButtonText: 'Continue',
		})
	}

	const dispatch = useDispatch();
	const submit = (e) => {
		e.preventDefault();
		if (!amount) {
			setErr("Amount is required")
			return
		};
		if (!receipt) {
			setFileErr("Upload your receipt");
			return;
		}
		setErr(null)
		setInProgress(true);
		let form = new FormData();
		form.append("amount", Number(amount));
		form.append("image", receipt);
		axios.post(`${BURL}fund-${wallet}-balance`, form).then(res => {
			if (!res?.data?.success) throw new Error(res);
			setInProgress(false);
			successRes();
			axios.get(`${BURL}customer-transactions-table`).then(res => {
				if (!res?.data?.success) throw new Error(res);
				dispatch(setTransactions(res.data.message))
			})
		}).catch(err => {
			setInProgress(false);
			let error = err?.response?.data?.message ?? err?.data?.message;
			if (error) {
				errorRes(error);
			} else {
				errorRes("An error occured, please try again.")
			}
		})
	}

	const handleChange = (values) => {
		setAmount(values.value);
	}

	return (
		<>
			<Dialog fullWidth maxWidth={'sm'} PaperProps={{sx: {maxWidth: 550, borderRadius: 4}, className: "bg-[#F7F7F7] dark:bg-trov-dark dark:text-white"}} open={open} onClose={() => {setOpen(false); setReceipt(null)}}>
				<DialogTitle className="text-center">
					<h3 className='dialog-title capitalize'>Fund {wallet} Wallet</h3>
				</DialogTitle>
				<form onSubmit={submit}>
					<DialogContent sx={{paddingX: 0, paddingTop: 0}}>
						<div className='dark:text-white'>
							<div className='text-black bg-[#FFF8F4] dark:bg-dark px-4 dark:text-white text-xs py-7 mb-7 text-center w-full leading-6'>
								{
									wallet === 'naira' ?
										<AccountDetails accountName="TRO-VEST ASSET MANAGEMENT LTD" accountType="Corporate Account" currency="NGN" accountNumber="5401374822" bankName="Providus Bank" /> :
										<AccountDetails accountName="TRO-VEST ASSET MANAGEMENT LTD" accountType="Corporate Account" currency="USD" accountNumber="5401374822" bankName="Providus Bank" />
								}
							</div>
							<div className='px-4'>
								<div className='flex flex-col space-y-1 w-full max-w-[25rem] mx-auto'>
									<label className='text-sm font-semibold'>Amount</label>
									<div className='relative flex items-center pb-4'>
										<span className='absolute left-3'>
											<BankNoteIcon />
										</span>
										<CurrencyFormat thousandSeparator={true} prefix={wallet === 'naira' ? '₦ ' : '$ '} onValueChange={handleChange} placeholder='Enter Amount' className={`w-full border border-black dark:border-white rounded-full bg-transparent h-9 pr-4 pl-9 outline-none transition text-sm ${err && 'border-red-500'}`} />
									</div>
								</div>
								<input accept='image/*' ref={receiptRef} className='hidden' type="file" onChange={(e) => {
									setReceipt(e.target.files[0]);
									setFileErr(null);
								}} />
								<div className='flex flex-col space-y-1 w-full max-w-[25rem] mx-auto'>
									<label className='text-sm font-semibold'>Transaction Receipt</label>
									<div className='relative flex items-center'>
										<span className='absolute left-3'>
											<LayersIcon />
										</span>
										<div className={`flex rounded-full w-full ${fileErr && 'border-red-500'} border border-black dark:border-white h-9 items-center pl-9 pr-1 mx-auto text-gray-400 justify-between text-sm`}>
											<span>
												{receipt ? receipt.name : 'Upload proof of payment'}
											</span>
											<button type="button" onClick={() => {receiptRef?.current?.click()}} className='rounded-full flex items-center h-7 w-7 hover:bg-gray-300 transition justify-center'>
												<UploadIcon />
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</DialogContent>
					<DialogActions sx={{justifyContent: 'center'}}>
						<CustomButton loading={inProgress} className="flex space-x-3 items-center w-full mx-2 max-w-[25rem] rounded-full mb-5 md:mb-7 justify-center" type="submit">
							<span>Submit</span>
							<RightArrIcon className="fill-white" />
						</CustomButton>
					</DialogActions>
				</form>
			</Dialog>
		</>
	)
}
export const OldFundC = OldFund

const Fund = ({setOpen, open, wallet: currency}) => {
	const [fetchingAccount, setFetchingAccount] = useState(false);
	const [err2, setErr2] = useState(null);
	const [wallet, setWallet] = useState(null);
	// const [amount, setAmount] = useState(0);

	useEffect(() => {
		setErr2(null);
		if (open) {
			setFetchingAccount(true);
			axios.get(`${BURL}get-wallet-info`).then(res => {
				if (!res?.data?.success) throw new Error(res);
				setFetchingAccount(false);
				setWallet(res.data.data);
			}).catch(err => {
				setFetchingAccount(false);
				let error = err?.response?.data?.message ?? err?.data?.message;
				if (error) {
					setErr2(error);
				} else {
					setErr2("An error occured, please try again.")
				}
			})
		}
	}, [open])


	return (
		<>
			<Dialog fullWidth maxWidth={'sm'} PaperProps={{sx: {maxWidth: 550, borderRadius: 4}, className: "bg-[#F7F7F7] dark:bg-trov-dark dark:text-white"}} open={open} onClose={() => {setOpen(false);}}>
				<DialogTitle className="text-center">
					<h3 className='dialog-title capitalize'>Fund {currency} Wallet</h3>
				</DialogTitle>
				<div>
					<DialogContent sx={{paddingX: 0, paddingTop: 0}}>
						<div className='dark:text-white'>
							{/* {!fetchingAccount && !err2 &&
								<div className='flex items-center flex-col justify-center'>
									<CurrencyFormat thousandSeparator={true} prefix={currency === 'naira' ? '₦ ' : '$ '} onValueChange={(e) => setAmount(e.value)} placeholder='Enter Amount' className={`border mb-2 text-center border-black dark:border-gray-400 rounded-full bg-transparent h-9 outline-none transition text-sm`} />
									<span className='text-xs dark:text-gray-200'>Settled Amount: </span>
									<b className='text-lg'>
										{currency === 'naira' ? '₦ ' : '$ '}
										{formatNum(
											amount - ((amount * 0.004) <= 2000 ? ((amount * 0.004)) : (2000))
										)}
									</b>
								</div>
							} */}
							<div className='text-black mt-4 bg-[#FFF8F4] dark:bg-dark px-4 dark:text-white text-xs py-7 mb-7 text-center w-full leading-6'>
								<>
									{fetchingAccount ? <AccountDetails loading /> :
										err2 ? <WalletWarning err={err2} /> :
											<>
												{
													currency === 'naira' ?
														<AccountDetails accountName={`${wallet?.first_name} ${wallet?.last_name}`} accountType="Virtual Account" currency="NGN" accountNumber={wallet?.virtual_account_number} bankName="GTBank Plc" /> :
														<AccountDetails accountName="TRO-VEST ASSET MANAGEMENT LTD" accountType="Corporate Account" currency="USD" accountNumber="5401374822" bankName="Providus Bank" />
												}
											</>
									}
								</>

							</div>

							{!err2 && <p className='text-gray-600 dark:text-[#BCBCBC] text-xs font-medium mx-auto max-w-xs text-center'>Note: Please confirm the account number and name before transferring funds into the account. </p>}
						</div>
					</DialogContent>
				</div>
			</Dialog>
		</>
	)
}

const AccountDetails = ({accountName, bankName, accountType, currency, accountNumber, loading}) => {
	return (loading ?
		<>
			<div className='bg-[#BCBCBC]/20 mx-auto animate-pulse h-2 w-20 rounded-md'></div>
			<p className='h-3 rounded-md bg-[#BCBCBC]/20 mx-auto animate-pulse w-52 mt-2'></p>
			<div className='flex justify-center text-left pt-5 gap-2 md:gap-7 flex-wrap'>
				<div className='grid'>
					<small className='bg-[#BCBCBC]/20 h-2 rounded-md w-16 animate-pulse'></small>
					<div className='h-3 rounded-md bg-[#BCBCBC]/20 mx-auto animate-pulse w-32 mt-2'></div>
				</div>
				<div className='grid'>
					<small className='bg-[#BCBCBC]/20 h-2 rounded-md w-14 animate-pulse'></small>
					<div className='h-3 rounded-md bg-[#BCBCBC]/20  animate-pulse w-8 mt-2'></div>
				</div>
				<div className='grid'>
					<small className='bg-[#BCBCBC]/20 h-2 rounded-md w-24 animate-pulse'></small>
					<div className='h-3 rounded-md bg-[#BCBCBC]/20 animate-pulse w-20 mt-2'></div>
				</div>
				<div className='grid'>
					<small className='bg-[#BCBCBC]/20 h-2 rounded-md w-16 animate-pulse'></small>
					<div className='h-3 rounded-md bg-[#BCBCBC]/20 mx-auto animate-pulse w-24 mt-2'></div>
				</div>
			</div>
		</> :
		<>
			<h5 className='text-[#BCBCBC] text-xs font-medium'>Account Name</h5>
			<p className='text-sm font-semibold'>{accountName}</p>
			<div className='flex justify-center text-left pt-5 gap-2 md:gap-7 flex-wrap'>
				{/* <div className='grid'>
					<small className='text-[#BCBCBC] text-xs font-medium'>Account Type</small>
					<h4 className='text-sm font-semibold text-primary'>{accountType}</h4>
				</div> */}
				<div className='grid'>
					<small className='text-[#BCBCBC] text-xs font-medium'>Currency</small>
					<h4 className='text-sm font-semibold text-primary'>{currency}</h4>
				</div>
				<div className='grid'>
					<small className='text-[#BCBCBC] text-xs font-medium'>Account Number</small>
					<h4 className='text-sm font-semibold text-primary'>{accountNumber}</h4>
				</div>
				<div className='grid'>
					<small className='text-[#BCBCBC] text-xs font-medium'>Bank Name</small>
					<h4 className='text-sm font-semibold text-primary'>{bankName}</h4>
				</div>
			</div>
		</>
	)
}

export default Fund
