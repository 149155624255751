import axios from 'axios';
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
// import {toast} from 'react-toastify';
import ProductCard from '../../components/ProductCard';
import {alertOptions, BURL, formatNum} from '../../services/base';
import {setInvestments} from '../../slices/userSlice';
import Swal from 'sweetalert2';
import ReactPaginate from 'react-paginate';
import Greeting from '../../components/Greeting';
import {Link} from 'react-router-dom';
import TopUpInvestment from '../../components/Dialogs/TopUpInvestment';
import PartLiquidation from '../../components/Dialogs/PartLiquidation';

const Investment = () => {
	const {products, investments, fetchingProducts, fetchingInvestments} = useSelector(state => state.profile);

	const dispatch = useDispatch();
	const [inProgress, setInProgress] = useState(false);

	const [topUpModalIsOpened, setTopUpModalIsOpened] = useState(false);
	const [partLiquiModalIsOpened, setPartLiquiModalIsOpened] = useState(false);

	const getData = useCallback(async () => {
		axios.get(`${BURL}get-investments`).then(res => {
			if (!res?.data?.success) return
			dispatch(setInvestments(res?.data?.message))
		}).catch(err => {
			// toast.error("Can't update investment list");
		})
	}, [dispatch])

	useEffect(() => {
		getData();
	}, [getData])

	const [activeMenuIndex, setActiveMenuIndex] = useState(null)
	const [currentInvestment, setCurrentInvestment] = useState(null)

	const more = useRef(null)
	const menuBlur = (e) => {
		if (!e.currentTarget.contains(e.relatedTarget)) {
			setActiveMenuIndex(null)
		}
	}

	const liquidate = async (data) => {
		setActiveMenuIndex(null)
		Swal.fire({
			title: 'Are you sure you want to liquidate your investment?',
			showCancelButton: true,
			confirmButtonText: 'Confirm',
			text: "You won't be able to revert this!",
			icon: 'warning',
		}).then((result) => {
			if (result.isConfirmed) {
				setInProgress(true);

				Swal.fire({
					title: "Requesting...",
					allowOutsideClick: () => !Swal.isLoading(),
					...alertOptions,
					didOpen: () => {
						Swal.showLoading()
						return axios.post(`${BURL}action-investment`, data).then(res => {
							if (!res?.data?.success) throw new Error(res);
							Swal.update({icon: 'success', title: 'Liquidation Successful'})
							Swal.hideLoading();
							getData();
							setInProgress(false);
							return res.data;
						}).catch(err => {
							if (err?.response?.data?.message) {
								Swal.update({icon: 'error', title: "Oops...", text: err?.response?.data?.message})
							} else {
								Swal.update({icon: 'error', title: "Oops...", text: 'An error occured, please try again'})
							}
							Swal.hideLoading();
						})
					}
				})
			}
		})

	}



	// for pagination
	const [itemOffset, setItemOffset] = useState(0);
	const itemsPerPage = 10;
	// Simulate fetching items from another resources.
	// (This could be items from props; or items loaded in a local state
	// from an API endpoint with useEffect and useState)
	const endOffset = itemOffset + itemsPerPage;
	// console.log(`Loading items from ${itemOffset} to ${endOffset}`);
	const currentItems = [...investments].reverse().slice(itemOffset, endOffset);
	const pageCount = Math.ceil([...investments].reverse().length / itemsPerPage);

	// Invoke when user click to request another page.
	const handlePageClick = (event) => {
		const newOffset = (event.selected * itemsPerPage) % [...investments].reverse().length;
		// console.log(
		// 	`User requested page number ${event.selected}, which is offset ${newOffset}`
		// );
		setItemOffset(newOffset);
	};

	return (
		<div>
			<Greeting sub="Check out your porfolio and some cools stuff to invest in today" />

			<div className="flex justify-between">
				<h3 className='w-20 text-lg font-bold'>Products</h3>
			</div>
			{fetchingProducts && <small className='my-3 text-sm block'>Fetching products...</small>}

			<div className='grid gap-5 lg:grid-cols-2 py-6'>
				{Array.isArray(products) && products.map((props) => (<ProductCard full key={props.id} {...props} dark />))}
			</div>
			<div className='pt-5'>
				{fetchingInvestments && 'Fetching Investments...'}
				{!fetchingInvestments && <div className="flex dark:border-[#E8E8E84D] justify-between rounded-t-3xl items-center border py-3 px-5">
					<h3 className='text-lg font-bold'>Investments</h3>
				</div>}
				{investments && investments[0] && <table className='w-full max-w-full overflow-auto'>
					<tbody>
						{
							currentItems.map((investment, i) => (
								<tr key={investment.id}>
									<td className='border-b dark:border-[#E8E8E84D] border-l text-left text-sm py-3 px-5 flex items-center space-x-3'>
										<span className='flex flex-col space-y-1'>
											<span>{investment.product.name}</span>
											<span className='text-primary text-xs'>
												{/* {new Date(each.created_at).toDateString()} */}
												<span className={`capitalize ${investment.status === "ACTIVE" ? 'text-[#17E383]' : investment.status === "PENDING" ? "text-[#f6b21b]" : 'text-gray-500'} `}>
													<span>
														{investment.status.toLowerCase()}
													</span>
												</span>
											</span>
										</span>
									</td>
									<td className='border-b dark:border-[#E8E8E84D] relative text-sm py-3 px-5 border-r text-right'>
										<span className='grid'>
											<span className=' font-semibold'>
												<span className='font-chivo'>{investment.product.currency === 'NGN' ? '₦' : '$'}</span>
												{formatNum(investment.principal)}
											</span>
											<button onClick={() => {
												setActiveMenuIndex(i); setTimeout(() => {more.current.focus();}, 0);
											}} className='bg-gray-200 rounded-full space-x-1 px-2 flex items-center justify-center w-fit place-self-end text-xs'>
												<span className='dark:text-trov-dark'>More</span>
												<svg width="8" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path fillRule="evenodd" clipRule="evenodd" d="M0.691649 1.09334C0.745089 1.03099 0.810293 0.979778 0.883533 0.942637C0.956774 0.905497 1.03662 0.883153 1.11849 0.876885C1.20037 0.870616 1.28269 0.880545 1.36073 0.906104C1.43877 0.931662 1.51101 0.972349 1.57332 1.02584L6.99998 5.67667L12.4266 1.02584C12.5532 0.924281 12.7142 0.8759 12.8758 0.890926C13.0373 0.905952 13.1867 0.983206 13.2923 1.10636C13.3979 1.22951 13.4515 1.38891 13.4418 1.55085C13.432 1.7128 13.3596 1.8646 13.24 1.97417L7.40665 6.97417C7.29338 7.07123 7.14914 7.12457 6.99998 7.12457C6.85082 7.12457 6.70658 7.07123 6.59332 6.97417L0.759983 1.97417C0.634304 1.86627 0.556599 1.71289 0.543943 1.54773C0.531286 1.38256 0.584712 1.21913 0.692483 1.09334H0.691649Z" fill="black" />
												</svg>
											</button>
										</span>
										{
											activeMenuIndex === i && <div ref={more} autoFocus onBlur={menuBlur} tabIndex={0} className='absolute bg-white dark:bg-dark z-20 w-48 rounded-lg shadow-lg py-3 right-2 bottom-8 outline-none'>
												<Link
													className='w-full block text-left pl-5 border-y py-2 hover:bg-gray-200 dark:border-gray-500 dark:hover:bg-trov-dark transition'
													to={`/investment/${investment.id}`}
												>Investment Details</Link>
												{
													investment.status === 'ACTIVE' && investment.om_status !== 'LIQUIDATED' &&
													<button
														disabled={inProgress}
														className='w-full border-y text-left pl-5 py-2 hover:bg-gray-200 dark:border-gray-500 dark:hover:bg-trov-dark transition'
														onClick={() => liquidate({
															investment_id: investment.id, "who": "OM",
															"status": "LIQUIDATED"
														})}
													>Liquidate</button>
												}

												{
													investment.status === 'ACTIVE' && investment.om_status !== 'LIQUIDATED' &&
													<button
														className='w-full text-left pl-5 border-y py-2 hover:bg-gray-200 dark:border-gray-500 dark:hover:bg-trov-dark transition'
														onClick={() => {
															setCurrentInvestment(investment);
															setTopUpModalIsOpened(true)
														}}
													>Top-Up Investment</button>
												}
												{/* {
													// investment.status === 'ACTIVE' && investment.om_status !== 'LIQUIDATED' &&
													<button
														className='w-full border-y py-2 hover:bg-gray-200 dark:border-gray-500 dark:hover:bg-trov-dark transition'
														onClick={() => {
															setCurrentInvestment(investment);
															setPartLiquiModalIsOpened(true)
														}}
													>Part Liquidation</button>
												} */}
											</div>
										}
									</td>
								</tr>
							))
						}
					</tbody>
				</table>}

				<div className='flex justify-end'>
					<ReactPaginate
						className='flex space-x-6 items-center my-4'
						breakLabel="..."
						nextLabel={<svg width="10" height="13" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1.39002 0.153566L5.96849 4.36912C6.16452 4.5406 6.16452 4.85498 5.96849 5.02646L1.39002 9.24201C1.09598 9.51352 0.619934 9.31346 0.619934 8.91334L0.619934 0.482237C0.619934 0.0821161 1.09598 -0.117944 1.39002 0.153566Z" fill="#8E8EA1" />
						</svg>
						}
						onPageChange={handlePageClick}
						pageRangeDisplayed={5}
						pageCount={pageCount}
						activeLinkClassName='bg-primary text-white border-none'
						pageLinkClassName='border-2 text-gray-400 rounded-full text-sm h-5 w-5 flex justify-center items-center'
						previousLabel={<svg width="10" height="13" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M5.33664 9.53198L0.758159 5.31643C0.562139 5.14495 0.562139 4.83057 0.758159 4.65909L5.33664 0.44354C5.63067 0.17203 6.10672 0.37209 6.10672 0.77221L6.10672 9.20331C6.10672 9.60343 5.63067 9.80349 5.33664 9.53198Z" fill="#8E8EA1" />
						</svg>
						}
						renderOnZeroPageCount={null}
					/>
				</div>
			</div>
			{
				currentInvestment && <>
					<TopUpInvestment
						open={topUpModalIsOpened}
						setOpen={setTopUpModalIsOpened}
						investment={currentInvestment}
					/>
					<PartLiquidation
						open={partLiquiModalIsOpened}
						setOpen={setPartLiquiModalIsOpened}
						investment={currentInvestment}
					/>
				</>
			}
		</div>
	)
}

export default Investment