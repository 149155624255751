import React from 'react'

const SearchIcon = () => {
	return (
		<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd" d="M1.54167 5.79167C1.54167 3.44779 3.44779 1.54167 5.79167 1.54167C8.13554 1.54167 10.0417 3.44779 10.0417 5.79167C10.0417 8.13554 8.13554 10.0417 5.79167 10.0417C3.44779 10.0417 1.54167 8.13554 1.54167 5.79167ZM12.6675 11.6659L10.2627 9.26038C11.0085 8.30129 11.4583 7.09925 11.4583 5.79167C11.4583 2.66721 8.91612 0.125 5.79167 0.125C2.66721 0.125 0.125 2.66721 0.125 5.79167C0.125 8.91612 2.66721 11.4583 5.79167 11.4583C7.09925 11.4583 8.30129 11.0085 9.26038 10.2627L11.6659 12.6675C11.804 12.8056 11.9853 12.875 12.1667 12.875C12.348 12.875 12.5293 12.8056 12.6675 12.6675C12.9444 12.3905 12.9444 11.9428 12.6675 11.6659Z" className='fill-current' />
		</svg>

	)
}

export default SearchIcon