import React from 'react'

const UserIcon = ({className}) => {
	return (
		<svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M7.99984 7.83329C9.84079 7.83329 11.3332 6.34091 11.3332 4.49996C11.3332 2.65901 9.84079 1.16663 7.99984 1.16663C6.15889 1.16663 4.6665 2.65901 4.6665 4.49996C4.6665 6.34091 6.15889 7.83329 7.99984 7.83329Z" className='stroke-current' strokeWidth="1.25" />
			<path d="M14.6666 14.0833C14.6666 16.1541 14.6666 17.8333 7.99992 17.8333C1.33325 17.8333 1.33325 16.1541 1.33325 14.0833C1.33325 12.0124 4.31825 10.3333 7.99992 10.3333C11.6816 10.3333 14.6666 12.0124 14.6666 14.0833Z" className='stroke-current' strokeWidth="1.25" />
		</svg>
	)
}

export default UserIcon