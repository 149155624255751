import React from 'react'

const TrxArrow = () => {
	return (
		<svg className='w-[1.6rem] min-w-[1.6rem] h-[1.6rem] ' viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M7.14111 20.572L21.2832 6.42983" className='stroke-current' strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M8.90845 6.42944L21.2828 6.42944L21.2828 18.8038" className='stroke-current' strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	)
}

export default TrxArrow