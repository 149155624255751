import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material'
import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import Swal from 'sweetalert2'
import {BURL} from '../../services/base'
import CustomButton from '../CustomButton'
import RightArrIcon from '../Icons/RightArrIcon'
import {useDispatch, useSelector} from 'react-redux'
import {setInvestments} from '../../slices/userSlice'
import CurrencyFormat from 'react-currency-format'
import BankNoteIcon from '../Icons/BankNoteIcon'
import {formatNum} from '../../services/base'


const PartLiquidation = ({
	open, setOpen, investment: {
		product, currency, ref_id, id,
		principal, date_interest,
		maturity_interest, rate, rem_days,
	}}) => {
	const [inProgress, setInProgress] = useState(false)
	const {cash_balance} = useSelector(state => state.profile);
	const [amountFigure, setamountFigure] = useState(0);
	const [err, setErr] = useState(null);
	const [newPrincipal, setNewPrincipal] = useState((parseFloat(principal) + parseFloat(date_interest)));
	const [newMaturityInt, setNewMaturityInt] = useState((maturity_interest))

	// trigger for successful part liquidation
	const successRes = () => {
		setOpen(false)
		Swal.fire({
			title: 'Part Liquidation Successful',
			text: 'Thank you for Investing with us',
			icon: 'success',
			// didClose: () => setOpen(false),
			confirmButtonText: 'Continue',
		})
	}

	// trigger if any error occured
	const errorRes = (res) => {
		Swal.fire({
			title: 'Can\'t Part Liquidate Investment',
			text: res,
			icon: 'error',
			// didClose: () => setOpen(false),
			confirmButtonText: 'Okay',
		})
	}
	const dispatch = useDispatch();

	// submit form
	const onSubmit = (e) => {
		e.preventDefault();
		if (err || !amountFigure) {
			return
		}
		const data = {
			id: id,
			principal: newPrincipal,
			tenure: rem_days,
			maturity_interest: newMaturityInt,
			downsize_amount: amountFigure,
			active_date: new Date(),
		};

		setInProgress(true);
		axios.post(`${BURL}down-size-investment`, data).then(res => {
			if (!res?.data?.success) throw new Error(res);
			setInProgress(false);
			axios.get(`${BURL}get-investments`).then(res => {
				if (!res?.data?.success) throw new Error(res);
				dispatch(setInvestments(res?.data?.message))
			}).catch(err => {
				toast.error("Can't update investment list");
			})
			successRes();
		}).catch(err => {
			setInProgress(false);
			if (err?.response?.data) {
				errorRes(err?.response?.data?.message)
			} else {
				errorRes("An error occured, please try again.")
			}
		})
	}

	// part liquidation amount changes
	const handleChange = (values) => {
		if (values.value) {
			setNewPrincipal(((parseFloat(principal) + parseFloat(date_interest)) - parseFloat(values.value)));
		} else {
			setNewPrincipal((parseFloat(principal) + parseFloat(date_interest)))
		}
		if (!values.value) {
			setErr("Amount is required")
			return
		} else if (Number(values.value) < 1) {
			setErr(`Amount can't be less than 1`)
			return
		} else if (currency === 'NGN' && Number(values.value) > cash_balance?.customerNairaBalance) {
			setErr(`Insufficient balance`)
			return
		} else if (currency === 'USD' && Number(values.value) > cash_balance?.customerDollarBalance) {
			setErr(`Insufficient dollar balance`)
			return
		} else {
			setErr(null);
		}

		setamountFigure(values.value);
	}

	// calculate new maturity interest
	useEffect(() => {
		let part1 = (newPrincipal * rate * rem_days) / 36500;
		let part2 = ((newPrincipal * rate * rem_days) / 36500) * 0.1;
		setNewMaturityInt(part1 - part2);
	}, [rate, newPrincipal, rem_days])

	return (
		<>
			<Dialog fullWidth maxWidth={'sm'} PaperProps={{sx: {maxWidth: 500, borderRadius: 4}, className: "bg-[#F7F7F7] dark:bg-trov-dark dark:text-white"}} open={open} onClose={() => setOpen(false)}>
				<DialogTitle className="">
					<h3 className='dialog-title text-xl md:mt-2 mb-0'>Part Liquidate Investment - {ref_id}</h3>
					<h3 className='dialog-sub text-sm mt-0'>{product.name}</h3>
				</DialogTitle>
				<form onSubmit={onSubmit}>
					<DialogContent>
						<DialogContentText className=''>
							<div className='w-full dark:text-white max-w-[26rem] mx-auto'>
								<div className=''>
									<label className='ml-2 text-xs font-bold'>Part Liquidate Amount</label>
									<div className='flex items-center relative flex-wrap'>
										<span className='absolute left-3'>
											<BankNoteIcon />
										</span>
										<CurrencyFormat autoFocus thousandSeparator={true} prefix={product.currency === "NGN" ? '₦ ' : '$ '} onValueChange={handleChange} placeholder='Enter Amount' className={`w-full max-w-md border border-black dark:border-white rounded-full h-9 pr-4 pl-9 outline-none bg-transparent focus:border-primary transition text-sm ${err && 'border-red-500 dark:border-red-500'}`} />
									</div>
									{err && <small className='absolute block ml-2 text-red-500'>{err}</small>}
								</div>
							</div>
						</DialogContentText>
					</DialogContent>
					<div className='bg-light dark:bg-dark text-sm py-4 px-3'>
						<div className='max-w-[26rem] mx-auto grid grid-cols-2 gap-2'>
							<div className=''>New Principal:</div>
							<b>{`${product.currency === "NGN" ? '₦' : '$'}${formatNum(newPrincipal)}`}</b>
							<div className=''>New Tenure (From Today):</div>
							<b>{`${(rem_days)} Days`}</b>
							<div className=''>New Maturity Interest:</div>
							<b>{`${product.currency === "NGN" ? '₦' : '$'}${formatNum(newMaturityInt)}`}</b>
						</div>
					</div>
					<DialogActions sx={{justifyContent: 'center'}}>
						<CustomButton dark loading={inProgress} className="w-full max-w-[26rem] my-4 justify-center mx-2" type="submit">
							<span>Continue</span>
							{!inProgress && <RightArrIcon className="fill-white" />}
						</CustomButton>
					</DialogActions>
				</form>

			</Dialog>
		</>
	)
}

export default PartLiquidation