import axios from 'axios'
import {useFormik} from 'formik'
import React, {useState} from 'react'
import {Link, useNavigate, useSearchParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import logo from '../assets/logo.png'
import CustomButton from '../components/CustomButton'
import CustomInput from '../components/CustomInput'
import {BURL} from '../services/base'
import * as Yup from 'yup'
import Swal from 'sweetalert2'

const ResetPassword = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [inProgress, setInProgress] = useState(false);

	const passwordSuccess = () => {
		Swal.fire({
			title: "Password Reset Successful",
			text: 'Your password has been reset, you can now login',
			icon: 'success',
			didClose: () => navigate("/login"),
			confirmButtonText: 'Continue',
		})
	}

	const formik = useFormik({
		validationSchema: Yup.object({
			password: Yup.string().required("Password is a required field"),
			confirm_password: Yup.string().label('Confirm Password').required().oneOf([Yup.ref('password'), null], 'Passwords must match'),
		}),
		initialValues: {password: "", confirm_password: ""},
		onSubmit: (values) => {
			setInProgress(true);
			axios.post(`${BURL}password-reset`, {
				...values,
				...Object.fromEntries([...searchParams])
			}).then(res => {
				if (!res?.data?.success) throw new Error(res);
				setInProgress(false);
				passwordSuccess();
			}).catch(err => {
				setInProgress(false);
				if (err?.response?.data) {
					toast.error(err?.response?.data?.message)
				} else {
					toast.error("An error occured, please try again.")
				}
			})
		}
	})
	return (
		<div className="relative min-h-screen bg-fixed flex bg-blend-color-burn bg-[url('./assets/authPattern.svg')] dark:bg-[url('./assets/authPattern2.svg')] bg-cover">
			<div className="max-w-[65rem] px-10 lg:px-5 mx-auto flex flex-col h-full w-full relative">
				<div className="flex justify-between flex-wrap gap-x-10 gap-y-5 py-10 items-center">
					<img alt="logo" src={logo} className="w-40 dark:[filter:invert(100%)_brightness(1000%)]" />
					<p className="text-sm text-[#8B8B8C]">
						<Link to="/register">
							Don’t have an account? <span className="text-primary">Sign Up</span>
						</Link>
					</p>
				</div>
				<div className='w-full relative flex h-[70vh] flex-col items-center justify-center'>
					<form onSubmit={formik.handleSubmit} className='m-auto max-w-[30rem] w-full p-5 lg:px-10 rounded-lg'>
						<h3 className='text-2xl font-semibold'>Create New Password</h3>
						<p className='text-xs mb-5 text-[#8B8B8C]'>Please create a strong password to secure your account.</p>
						<CustomInput name="password" label="New Password" type="password" formik={formik} />
						<CustomInput name="confirm_password" label="Confirm Password" type="password" formik={formik} />
						<div className='fm-group mt-3'>
							<CustomButton loading={inProgress} type='submit' className='py-3 font-medium justify-center text-md text-white '>
								<span>Update Password</span>
							</CustomButton>
						</div>
					</form>
					{/* <p className='text-center text-gray-400 text-sm bg-white w-full max-w-md mt-5 rounded-lg p-3 bg-opacity-20'> <Link to="/login" >  Remember your password? <span className='text-white'>Sign In</span></Link> </p> */}
				</div>
			</div>
		</div>
	)
}

export default ResetPassword