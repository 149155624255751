import axios from 'axios'
import React, {useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import {BURL} from '../../services/base'
import {setProfile} from '../../slices/userSlice'
import {Link} from 'react-router-dom';
import EditIconAlt from '../../components/Icons/EditIconAlt'
import profileImg from '../../assets/profile.png'
import UserIcon from '../../components/Icons/UserIcon'
import CardIcon from '../../components/Icons/CardIcon'
import LockIcon from '../../components/Icons/LockIcon'
import BookIcon from '../../components/Icons/BookIcon'
import ComputerIcon from '../../components/Icons/ComputerIcon'
import AuthPattern from '../../components/Patterns/AuthPattern'
import Greeting from '../../components/Greeting'
import NoteIcon from '../../components/Icons/NoteIcon'
import LoadingIcon from '../../components/Icons/LoadingIcon'
import SupportIcon from '../../components/Icons/SupportIcon'

const Profile = () => {
	const {data, customer, profileCompletion} = useSelector(state => state.profile);
	const [inProgress, setInProgress] = useState(false);
	const isCORP = (customer?.label === "CORP");
	const dispatch = useDispatch();
	const dp = useRef(null);

	const update = () => {
		const form = new FormData();
		form.append('imagePassport', dp.current.files[0]);
		setInProgress(true);
		axios.post(`${BURL}upload-customer-passport`, form).then(res => {
			if (!res?.data?.success) throw new Error(res);
			axios.post(`${BURL}get-customer`).then(res => {
				if (!res?.data?.success) throw new Error(res);
				setInProgress(false);
				toast.success("Updated successfully");
				dispatch(setProfile(res.data.message));
			}).catch(err => {
				toast.error("Can't fetch updated user profile")
			})
		}).catch(err => {
			setInProgress(false);
			if (err?.response?.data) {
				toast.error(err?.response?.data?.message)
			} else {
				toast.error("An error occured")
			}
		})
	}


	return (
		<div className='w-full'>
			<Greeting sub={'Manage your account information'} noPre head={'My Account'} />
			{!(profileCompletion.personal && profileCompletion.banks && profileCompletion.employment && profileCompletion.docs) && <div className='flex items-center py-3 px-5 rounded-2xl mb-5 dark:shadow-none shadow-[1px_2px_20px_5px_#f3f3f3] dark:bg-dark'>
				<NoteIcon />
				<div className='mx-3'>
					<h3 className='font-bold text-lg'>Complete KYC</h3>
					<p className='text-[.65rem]'>You have a pending actions to complete </p>
				</div>
				{<span className='px-3 bg-[#FDEAB7] ml-auto text-[#CA9715] text-xs py-1 rounded-full'>Pending</span>}
			</div>}
			<div className='flex flex-col md:flex-row gap-4'>
				<div className='w-full md:w-1/2 relative rounded-2xl space-y-3 dark:shadow-none dark:bg-dark shadow-[1px_2px_20px_5px_#f3f3f3] p-5 lg:px-8'>
					<button onClick={() => dp.current?.click()} className='ml-auto relative text-xs w-fit flex space-x-2 text-primary items-center'>
						<span>edit</span>
						<EditIconAlt />
					</button>
					<input ref={dp} type='file' accept='image/*' onChange={update} className='hidden' />
					<div className='relative w-max'>
						<img className='rounded-full object-cover h-20 w-20' alt='profileimage' src={customer.passport ?? profileImg} onError={(e) => {e.target.src = profileImg}} />
						{inProgress && <div className='absolute inset-0 flex justify-center items-center bg-black rounded-full bg-opacity-50 text-white'>
							<LoadingIcon />
						</div>}
					</div>
					<ProfileLine title={'Name'} value={isCORP ? data.business_name : `${data?.firstname} ${data?.lastname}`} />
					{customer?.phone && <ProfileLine title={'Phone Number'} value={customer?.phone} />}
					<ProfileLine title={'Email Address'} value={customer?.email} />
					{data?.bvn && <ProfileLine title={'BVN'} value={`${data?.bvn?.slice(0, 5)}*****`} />}
				</div>
				<div className='w-full md:w-1/2 grid gap-4'>
					<ProfileTab icon={<UserIcon />} pending={!profileCompletion.personal} text={isCORP ? 'Company Details' : "Personal Details"} to="/profile/personal" />
					<ProfileTab icon={<CardIcon />} pending={!profileCompletion.banks} text="Banks Details" to="/profile/banks" />
					<ProfileTab icon={isCORP ? <UserIcon /> : <ComputerIcon />} pending={!profileCompletion.employment} text={isCORP ? 'Contact Details' : "Employment & Others"} to="/profile/others" />
					<ProfileTab icon={<BookIcon />} pending={!profileCompletion.docs} text="Documents" to="/profile/docs" />
					<ProfileTab icon={<LockIcon />} nobadge text="Reset Password" to="/forgot-password" />
					<ProfileTab icon={<SupportIcon />} nobadge text="Help & Support" to="/help" />
				</div>
			</div>
		</div>

	)
}

const ProfileTab = ({text, icon, to, pending, nobadge}) => {
	return (
		<Link to={to} className='rounded-2xl group overflow-hidden relative dark:bg-dark dark:shadow-none shadow-[1px_2px_20px_5px_#f3f3f3] flex items-center p-6 lg:px-10'>
			{icon}
			<AuthPattern />
			<h3 className='text-sm font-medium ml-3'>{text}</h3>
			<div className='flex ml-auto items-center justify-between space-x-6'>
				{pending ?
					<span className='px-3 bg-[#FDEAB7] text-[#CA9715] text-xs py-1 rounded-full'>Pending</span> :
					(!nobadge && <span className='px-3 bg-[#b7fddf] text-[#15ca30] text-xs py-1 rounded-full'>Updated</span>)
				}
				<svg className='ml-auto group-hover:translate-x-2 transition duration-300' width="8" height="14" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path fillRule="evenodd" clipRule="evenodd" d="M0.930512 0.66803C1.10677 0.51729 1.33566 0.442693 1.5669 0.460631C1.79813 0.478569 2.01278 0.587576 2.16368 0.763697L9.16368 8.93036C9.29955 9.08894 9.37424 9.29087 9.37424 9.4997C9.37424 9.70852 9.29955 9.91046 9.16368 10.069L2.16368 18.2357C2.01028 18.4032 1.79776 18.5045 1.57103 18.5182C1.34431 18.5318 1.12115 18.4568 0.948736 18.3089C0.776324 18.1611 0.668168 17.952 0.647132 17.7258C0.626096 17.4996 0.693828 17.2742 0.836011 17.097L7.34718 9.4997L0.836011 1.90236C0.684993 1.7264 0.609997 1.49769 0.627497 1.26647C0.644998 1.03525 0.753563 0.820426 0.929345 0.669197L0.930512 0.66803Z" className="fill-primary dark:fill-white" />
				</svg>
			</div>
		</Link>
	)
}

const ProfileLine = ({title, value}) => {
	return (
		<div className='w-full max-w-full '>
			<span className='text-xs'>{title}</span>
			<h3 className='text-2xl break-words'>{value}</h3>
		</div>
	)
}

export default Profile