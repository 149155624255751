import React from 'react'
import {Link} from 'react-router-dom'

const ProfileGoTo = ({to, next, prev}) => {
	return (
		<Link to={to} className='flex items-center gap-2 text-gray-600 hover:text-gray-800 dark:text-primary dark:hover:opacity-80 transition duration-300 text-sm'>
			{prev && <>
				<svg width="8" height="10" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M6.5 1.1665L1.5 6.99984L6.5 12.8332" className='stroke-current' strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
				</svg>
				<span>Previous</span>
			</>}

			{next && <>
				<span>Next</span>
				<svg className='rotate-180' width="8" height="10" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M6.5 1.1665L1.5 6.99984L6.5 12.8332" className='stroke-current' strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
				</svg>
			</>
			}
		</Link>
	)
}

export default ProfileGoTo