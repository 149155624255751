import React, {useEffect, useState} from 'react'
import Greeting from '../../components/Greeting'
import {useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router';
import LoadingIcon from '../../components/Icons/LoadingIcon';
import DashboardTopPattern from '../../components/Patterns/DashboardTopPattern';
import {BURL, formatNum} from '../../services/base';
import CustomPDF from '../../components/InvestmentCert';
import CustomMFMPDF from '../../components/InvestmentStmt';
import axios from 'axios';

const EachInvestment = () => {
	const {investments, fetchingInvestments,
		// data, customer
	} = useSelector(state => state.profile);
	const [investment, setInvestment] = useState(null);
	// const isCORP = (customer?.label === "CORP");
	// const [detailsInArray, setDetailsInArray] = useState([]);
	const [sign, setSign] = useState(null);
	const [pdfCurrency, setPdfCurrency] = useState(null);
	const [items, setItems] = useState([])

	const {investment_id} = useParams();
	const navigate = useNavigate();
	useEffect(() => {
		const found = investments[0] ? investments.find(each => each.id.toString() === investment_id.toString()) : null;
		if (found) {
			setInvestment(found);
			setSign(found?.product?.currency === 'NGN' ? '₦' : '$')
			setPdfCurrency(found?.product?.currency === 'NGN' ? '\u20A6' : '$')

			// fetch

			axios.post(`${BURL}get-investment`, {investment_id}).then(resp => {
				// console.log(resp.data.message.investment.items);
				setItems(resp.data.message.investment.items)
			}).catch(err => {

			})
			// const details = [
			// 	{title: "Principal", value: found.principal}
			// ]
			// setDetailsInArray(details)
		} else if (!fetchingInvestments) {
			navigate("/investment")
		}
	}, [investments, investment_id, navigate, fetchingInvestments])


	return (
		<div>
			{fetchingInvestments && <div className='min-h-[30rem] flex justify-center items-center'>
				<LoadingIcon />
			</div>}
			{investment &&
				<div className='flex flex-col'>
					<Greeting pre={''} showBack head={`${investment.product.name}`} />
					<div className='bg-trov-dark dark:bg-white relative overflow-hidden flex p-5 rounded-2xl text-white dark:text-black items-center justify-between'>
						<div className='absolute w-full opacity-40'>
							<DashboardTopPattern />
						</div>
						<div className='relative'>
							<p>Principal</p>
							<h3 className='font-bold text-3xl'><span className='font-chivo'>{sign}</span>{formatNum(investment.principal)}</h3>
						</div>
						<div className='text-right relative'>
							<span className={`text-sm capitalize rounded-full py-1 px-3 ${investment.status === "ACTIVE" ? 'text-[#17e37d] bg-[#17e38439]' : investment.status === "PENDING" ? "text-[#CA9715] bg-[#FDEAB7]" : 'text-[#cbcbcb] bg-[#6b727e52] dark:text-black'} `}>
								{investment.status.toLowerCase()}
							</span>
							<p className='mt-7 text-sm'>Maturity Date - {investment.maturity_date ? new Date(investment.maturity_date).toDateString() : '-'}</p>
						</div>
					</div>
					{investment.status === "ACTIVE" &&
						<span className='ml-auto w-fit'>
							{investment.product.money_market ? <CustomMFMPDF product={investment.product} details={items.map(each => ({
								...each,
								pdfCurrency: '₦',
								date: each.created_at,
								principal: formatNum(Number(each.money_principal)),
								earned: formatNum(Number(each.money_amount)),
								interest: formatNum(Number(each.money_bal))
							}))} /> : <CustomPDF product={investment.product} details={[
								{title: 'INVESTMENT TYPE', value: investment.product.name},
								{title: "PRINCIPAL", value: formatNum(investment.principal), pdfCurrency, isMoney: true},
								// {title: "Account Name", value: isCORP ? data.business_name : `${data?.firstname} ${data?.lastname}`},
								{title: "START DATE", value: investment.active_date ? new Date(investment.active_date).toDateString().slice(4) : '-'},

								...[!investment.product.money_market ? {title: "Tenure", value: investment.tenure + ' Days'} : null],

								{title: "Maturity Date", value: investment.maturity_date ? new Date(investment.maturity_date).toDateString().slice(4) : '-'},
								{title: "Interest Rate", value: investment.rate + '% p.a NET'},
								{title: "GROSS INTEREST AMOUNT", value: formatNum((Number(investment.principal) * Number(investment.rate) * Number(investment.tenure)) / 36500), pdfCurrency, isMoney: true},
								{title: "WITHHOLDING TAX AMOUNT", value: formatNum(((Number(investment.principal) * Number(investment.rate) * Number(investment.tenure)) / 36500) * 0.1), pdfCurrency, isMoney: true},
								{title: "NET INTEREST AMOUNT ", value: formatNum(investment.maturity_interest), pdfCurrency, isMoney: true},
								{title: "AMOUNT AT MATURITY ", value: formatNum(Number(investment.maturity_interest) + Number(investment.principal)), pdfCurrency, isMoney: true},
								// {title: "Status", value: investment.status.toLowerCase()},
								// {title: "Remaining Days", value: investment.rem_days ? `${investment.rem_days} Days` : '-'},
								// {title: "Liquidated Amount", value: pdfCurrency + formatNum(investment.liquidated_amount)},
								// {title: "Date Liquidated", value: investment.liquidated_date ? new Date(investment.liquidated_date).toDateString() : '-'},
							]} />
							}
						</span>
					}
					<div className='border-x dark:border-[#E8E8E84D] text-sm rounded-2xl mt-10'>
						{/* <div className='flex justify-between space-x-3 border-y dark:border-[#E8E8E84D] py-3 px-5 rounded-t-2xl'>
							<span className='text-left'>Principal</span>
							<span className='font-semibold text-right'><span className='font-chivo'>{sign}</span>{formatNum(investment.principal)}</span>
						</div> */}
						<div className='flex justify-between space-x-3 border-y rounded-t-2xl dark:border-[#E8E8E84D] py-3 px-5'>
							<span className='text-left'>Accrued Today</span>
							<span className='font-semibold text-right'><span className='font-chivo'>{sign}</span>{formatNum(investment.date_interest)}</span>
						</div>
						{!investment.product.money_market && <div className='flex justify-between space-x-3 border-b dark:border-[#E8E8E84D] py-3 px-5'>
							<span className='text-left'>Remaining Days</span>
							<span className='font-semibold text-right'>{investment.rem_days ? `${investment.rem_days} Days` : '-'}</span>
						</div>}
						<div className='flex justify-between space-x-3 border-b dark:border-[#E8E8E84D] py-3 px-5'>
							<span className='text-left'>Maturity Interest</span>
							<span className='font-semibold text-right'><span className='font-chivo'>{sign}</span>{formatNum(investment.maturity_interest)}</span>
						</div>
						<div className='flex justify-between space-x-3 border-b dark:border-[#E8E8E84D] py-3 px-5'>
							<span className='text-left'>Active Date</span>
							<span className='font-semibold text-right'>{investment.active_date ? new Date(investment.active_date).toDateString() : '-'}</span>
						</div>
						<div className='flex justify-between space-x-3 border-b dark:border-[#E8E8E84D] py-3 px-5'>
							<span className='text-left'>Liquidated Amount</span>
							<span className='font-semibold text-right'><span className='font-chivo'>{sign}</span>{formatNum(investment.liquidated_amount)}</span>
						</div>
						<div className='flex justify-between space-x-3 border-b dark:border-[#E8E8E84D] py-3 px-5'>
							<span className='text-left'>Date Liquidated</span>
							<span className='font-semibold text-right'>{investment.liquidated_date ? new Date(investment.liquidated_date).toDateString() : '-'}</span>
						</div>
						{!investment.product.money_market && <div className='flex justify-between space-x-3 border-b dark:border-[#E8E8E84D] py-3 px-5 rounded-b-2xl'>
							<span className='text-left'>Tenure</span>
							<span className='font-semibold text-right'>{investment.tenure} Days</span>
						</div>}
					</div>
				</div>}

		</div>
	)
}

export default EachInvestment