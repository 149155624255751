import React from 'react'
import {formatNum} from '../services/base'
import TrxIcon from '../components/Icons/TrxArrow'

const TrxTable = ({transactions, ref}) => {
	return (
		<table ref={ref} className='w-full'>
			<tbody>
				{
					Array.isArray(transactions) && transactions?.map((each, i) => (
						<tr key={i}>
							<td className='border-b dark:border-[#E8E8E84D] border-l text-left text-sm py-3 px-5 flex items-center space-x-3'>
								<span className={`${each.action === 'DEPOSIT' ? 'text-green-700 rotate-180' : 'text-red-500'}`}>
									<TrxIcon />
								</span>
								<span className='flex flex-col space-y-1'>
									<span>{each.action}</span>
									<span className='text-primary text-xs'>
										{new Date(each.created_at).toDateString()}
									</span>
								</span>
							</td>
							<td className='border-b dark:border-[#E8E8E84D] text-sm py-3 px-5 border-r text-right'>
								<span className='grid'>
									<span className='font-semibold'>
										<span className='font-chivo'>{each.currency === 'USD' ? '$' : '₦'}</span>{formatNum(each.amount)}
									</span>
									<span className={`${each.status.toLowerCase() === "pending" ? 'text-yellow-700' :
										(each.status.toLowerCase() === "approved" || each.status.toLowerCase() === "completed") ? 'text-green-700' :
											each.status.toLowerCase() === "rejected" ? 'text-red-700' :
												'text-gray-700'
										} capitalize text-xs`
									}>
										{each.status.toLowerCase() === "approved" ? "Completed" : each.status.toLowerCase()}
									</span>
								</span>
							</td>
						</tr>
					))
				}
			</tbody>
		</table>
	)
}

export default TrxTable