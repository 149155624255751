import React from 'react'

const ComputerIcon = () => {
	return (
		<svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M1 14.5H19M3 2.5C3 2.23478 3.10536 1.98043 3.29289 1.79289C3.48043 1.60536 3.73478 1.5 4 1.5H16C16.2652 1.5 16.5196 1.60536 16.7071 1.79289C16.8946 1.98043 17 2.23478 17 2.5V10.5C17 10.7652 16.8946 11.0196 16.7071 11.2071C16.5196 11.3946 16.2652 11.5 16 11.5H4C3.73478 11.5 3.48043 11.3946 3.29289 11.2071C3.10536 11.0196 3 10.7652 3 10.5V2.5Z" className='stroke-current' strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		</svg>

	)
}

export default ComputerIcon