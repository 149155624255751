import axios from 'axios';
import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux';
import {Navigate, useNavigate} from 'react-router';
import {toast} from 'react-toastify';
import Main from '../Pages/user/Index';
import {BURL} from '../services/base';
import {reset, setCashBal, setFetchingBalance, setFetchingInterest, setFetchingInvest, setFetchingInvestments, setFetchingProducts, setFetchingTransactions, setInvestments, setProducts, setProfile, setTotalInterest, setTotalInvest, setTransactions} from '../slices/userSlice';
import Swal from 'sweetalert2';
import LoadingIcon from '../components/Icons/LoadingIcon';
import TimeoutIllus from '../components/Illustrations/TimeoutIllus';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const UserGuard = () => {
	const navigate = useNavigate();
	// LNOTE uncomment
	axios.interceptors.response.use(res => res, (err) => {
		if (err?.response && err.response.status === 401) {
			localStorage.removeItem("token");
			navigate("/login");
			try {
				toast.dismiss();
			} catch (error) {

			}
			MySwal.fire({
				title: err?.response?.data?.message,
				text: 'Kindly login again',
				iconHtml: <TimeoutIllus />,
				didClose: () => navigate("/login"),
				confirmButtonText: 'Login',
				customClass: {
					icon: 'swal-reg-success-icon'
				}
			})
		}
		return Promise.reject(err);
	});

	const dispatch = useDispatch();

	const loader = <div className='flex text-primary h-screen items-center justify-center'>
		<LoadingIcon className='fill-primary' />
	</div>

	// const tryAgain = useMemo(() =>
	// 	<div className='flex h-screen items-center justify-center'>
	// 		<button class='outline-none rounded-lg py-2 px-4 bg-light text-sm text-primary' onClick={() => window.location.reload()}>Try Again</button>
	// 	</div>, []
	// )

	const [currentView, setCurrentView] = useState(loader);
	const getInvestments = useCallback(async () => {
		dispatch(setFetchingInvestments(true));
		try {
			const res = await axios.get(`${BURL}get-investments`)
			dispatch(setFetchingInvestments(false));
			if (!res?.data?.success) return
			dispatch(setInvestments(res.data.message));
		} catch (err) {
			dispatch(setFetchingInvestments(false));
			toast.error("Can't fetch Investments")
		}
	}, [dispatch])

	const getProducts = useCallback(async () => {
		dispatch(setFetchingProducts(true));
		try {
			const res = await axios.get(`${BURL}get-products`)
			dispatch(setProducts(res.data.message));
			dispatch(setFetchingProducts(false));
		} catch (err) {
			dispatch(setFetchingProducts(false));
			toast.error("Can't fetch Products")
		}
	}, [dispatch])

	const getBal = useCallback(async () => {
		dispatch(setFetchingBalance(true));
		try {
			const res = await axios.get(`${BURL}get-cash-balance`)
			dispatch(setCashBal(res.data.message));
			dispatch(setFetchingBalance(false));
		} catch (err) {
			dispatch(setFetchingBalance(false));
			toast.error("Can't get Cash Balance")
		}
	}, [dispatch])

	const getTotalInvestment = useCallback(() => {
		dispatch(setFetchingInvest(true));
		dispatch(setFetchingInterest(true));
		axios.get(`${BURL}total-investment`).then(res => {
			if (!res?.data?.success) throw new Error(res);
			dispatch(setTotalInvest(res.data.message))
			dispatch(setFetchingInvest(false));
		}).catch(() => {
			dispatch(setFetchingInvest(false));
		})
		axios.get(`${BURL}total-accrued`).then(res => {
			if (!res?.data?.success) throw new Error(res);
			dispatch(setTotalInterest(res.data.message))
			dispatch(setFetchingInterest(false));
		}).catch(() => {
			dispatch(setFetchingInterest(false));
		})
	}, [dispatch])

	const getTransactions = useCallback(() => {
		dispatch(setFetchingTransactions(true));
		axios.get(`${BURL}customer-transactions-table`).then(res => {
			if (!res?.data?.success) throw new Error(res);
			dispatch(setTransactions(res.data.message))
			dispatch(setFetchingTransactions(false));
		}).catch(() => {
			dispatch(setFetchingTransactions(false));
		})
	}, [dispatch])

	const getData = useCallback(async () => {
		dispatch(reset())
		try {
			const res = await axios.post(`${BURL}get-customer`)
			if (!res?.data?.success) throw new Error(res);
			dispatch(setProfile(res.data.message));
			setCurrentView(<Main />)
			getInvestments();
			getBal();
			getTotalInvestment();
			getProducts();
			getTransactions()
		} catch (err) {
			setCurrentView(<Navigate to="/login" />)
		}
	}, [getBal,
		getProducts, getTotalInvestment,
		dispatch, getTransactions,
		getInvestments,
	])


	useEffect(() => {
		if (!localStorage.token) {
			setCurrentView(<Navigate to="/login" />)
			return;
		}
		getData();

	}, [getData,])


	return currentView // LNOTE change back to currentView
}

export default UserGuard