import React from 'react'

const DashboardTopPattern = () => {
  return (

    <svg className='h-full w-full rounded-3xl' viewBox="0 0 558 162" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.18">
        <path d="M242.971 247.406C247.547 264.873 254.639 281.417 263.977 296.405C271.439 308.591 280.348 319.62 290.468 329.199C303.451 341.787 318.239 351.913 334.231 359.164C353.283 367.836 373.719 372.209 394.332 372.025C399.188 371.909 404.041 372.384 408.803 373.439C425.782 377.006 441.231 386.61 452.783 400.78C464.334 414.95 471.35 432.903 472.755 451.888H487.693C486.287 428.268 477.533 405.904 462.907 388.568C448.281 371.232 428.677 359.984 407.402 356.72C402.572 355.947 397.696 355.56 392.815 355.563C367.075 355.598 341.825 347.814 319.837 333.067C297.85 318.32 279.975 297.18 268.178 271.97C254.782 245.851 249.149 215.762 252.073 185.933C253.832 159.251 261.885 133.538 275.436 111.342C288.987 89.1451 307.565 71.2351 329.329 59.3856C350.444 46.9964 374.163 41.0052 398.066 42.0239C429.699 42.8791 460.173 55.3126 484.684 77.364C509.195 99.4155 526.348 129.83 533.439 163.813C535.852 175.416 537.104 187.276 537.174 199.179C537.256 223.322 545.04 246.663 559.164 265.119C573.287 283.575 592.85 295.97 614.43 300.134C617.464 300.134 620.498 301.163 623.532 301.42V284.83C613.55 284.065 603.818 281.044 594.941 275.957C585.423 270.611 577.003 263.17 570.2 254.094C563.546 245.14 558.479 234.888 555.262 223.872C552.845 212.969 551.631 201.786 551.645 190.563C551.645 184.904 551.645 179.117 550.361 173.587C548.344 157.903 544.419 142.589 538.691 128.061C528.305 102.128 512.27 79.4223 492.011 61.9577C475.64 48.0808 456.969 37.8586 437.044 31.8641C428.805 29.0816 420.312 27.3132 411.72 26.5913C404.438 25.667 397.097 25.4519 389.78 25.9483C383.129 26.8485 376.36 26.9771 369.708 28.1346C348.278 31.7421 327.791 40.3347 309.607 53.3411C295.415 63.2626 282.766 75.6386 272.146 89.9936C260.878 105.099 252.073 122.243 246.122 140.664C241.683 154.509 238.745 168.884 237.369 183.49C236.533 195.842 236.885 208.257 238.419 220.528C239.344 229.597 240.865 238.58 242.971 247.406Z" fill="white" fillOpacity="0.3" />
        <path d="M368.774 342.189C377.419 344.098 386.216 345.046 395.032 345.019C418.929 345.14 442.047 354.401 460.436 371.218C478.826 388.036 491.347 411.368 495.862 437.228C496.758 442.103 497.304 447.048 497.496 452.018H512.317C511.409 436.037 507.932 420.361 502.047 405.72C496.986 393.644 490.384 382.428 482.441 372.411C472.77 360.358 461.132 350.412 448.131 343.089C437.619 336.759 426.219 332.412 414.404 330.229C405.876 328.729 397.25 327.998 388.614 328.043C376.34 327.713 364.214 325.014 352.786 320.069C339.229 314.485 326.697 306.251 315.792 295.763C306.033 286.453 297.853 275.305 291.635 262.84C284.903 249.974 280.244 235.919 277.864 221.3C277.008 216.552 276.501 211.735 276.347 206.897C275.999 196.057 276.585 185.206 278.098 174.488C281.677 153.769 289.677 134.285 301.438 117.645C313.765 100.461 329.818 86.9716 348.118 78.4201C358.877 73.5155 370.249 70.4369 381.845 69.2892C386.836 68.5853 391.871 68.3273 396.899 68.5176C411.303 69.1195 425.492 72.5605 438.795 78.6774C447.964 82.8349 456.628 88.235 464.586 94.753C472.718 101.324 480.052 109.018 486.409 117.645C495.436 129.855 502.322 143.811 506.715 158.798C509.291 167.845 511.05 177.15 511.967 186.577C512.667 194.165 511.967 201.752 512.783 209.34C513.796 222.374 516.589 235.164 521.069 247.279C526.22 261.371 533.478 274.412 542.542 285.86C555.035 301.498 570.825 313.511 588.522 320.841C599.706 325.674 611.533 328.454 623.533 329.072V312.996C600.338 311.235 578.359 300.91 561.144 283.689C543.929 266.469 532.483 243.357 528.655 218.085C527.527 210.216 526.98 202.26 527.021 194.293C526.182 166.776 518.317 140.077 504.328 117.264C490.339 94.451 470.795 76.4493 447.939 65.3268C425.084 54.2043 399.845 50.4121 375.122 54.3859C350.399 58.3598 327.195 69.9384 308.175 87.7918C289.155 105.645 275.09 129.049 267.597 155.315C260.104 181.581 259.487 209.643 265.816 236.278C272.144 262.913 285.163 287.041 303.375 305.889C321.588 324.738 344.255 337.542 368.774 342.832V342.189Z" fill="white" fillOpacity="0.1" />
        <path d="M291.635 263.353C297.853 275.819 306.033 286.967 315.792 296.276C326.697 306.764 339.229 314.998 352.786 320.583C364.214 325.527 376.34 328.226 388.614 328.556C397.25 328.511 405.876 329.243 414.405 330.742C426.219 332.926 437.619 337.273 448.131 343.603C461.132 350.926 472.77 360.872 482.441 372.925C490.384 382.941 496.986 394.158 502.047 406.234C507.932 420.874 511.409 436.55 512.317 452.531H527.021C526.936 442.026 525.482 431.588 522.703 421.537C516.421 395.011 503.435 370.995 485.255 352.281C467.076 333.567 444.449 320.925 420.006 315.824C411.516 313.952 402.874 313.047 394.215 313.123C368.324 313.097 343.385 302.367 324.326 283.055C305.267 263.743 293.477 237.254 291.285 208.825C287.916 179.583 294.962 150.04 310.943 126.402C326.923 102.764 350.594 86.8698 376.984 82.0589C403.373 77.2481 430.428 83.8945 452.465 100.602C474.501 117.31 489.805 142.778 495.162 171.658C497.104 180.591 498.122 189.737 498.196 198.922C498.218 233.292 509.225 266.555 529.286 292.871C549.347 319.188 577.178 336.876 607.895 342.831C613.225 343.841 618.601 344.528 623.999 344.889V328.427C612 327.81 600.173 325.03 588.989 320.197C571.292 312.867 555.501 300.854 543.009 285.216C533.945 273.768 526.687 260.727 521.536 246.635C517.056 234.52 514.263 221.73 513.25 208.696C512.55 201.108 513.25 193.521 512.433 185.933C511.517 176.506 509.758 167.201 507.182 158.154C502.789 143.167 495.903 129.211 486.876 117.001C480.771 108.664 473.755 101.192 465.986 94.752C457.864 88.3314 449.043 83.0602 439.729 79.0622C426.426 72.9453 412.237 69.5043 397.833 68.9024C392.804 68.7121 387.77 68.9701 382.779 69.674C371.182 70.8217 359.811 73.9004 349.052 78.805C330.752 87.3565 314.699 100.846 302.372 118.03C290.611 134.669 282.611 154.153 279.031 174.873C277.519 185.59 276.933 196.442 277.281 207.281C277.434 212.12 277.942 216.937 278.798 221.685C280.873 236.284 285.216 250.38 291.635 263.353Z" fill="white" fillOpacity="0.3" />
        <path d="M378.461 300.264C383.586 301.245 388.781 301.719 393.983 301.678C425.955 301.61 457.022 313.374 482.204 335.085C507.386 356.795 525.223 387.195 532.857 421.41C535.089 431.448 536.456 441.696 536.941 452.018H551.412C550.866 430.972 546.667 410.236 539.042 390.93C525.96 357.568 503.823 329.489 475.673 310.552C460.101 300.067 442.945 292.749 425.025 288.947C414.972 286.951 404.781 285.919 394.566 285.86C377.811 285.743 361.502 279.901 347.886 269.141C331.623 256.317 320.498 237.051 316.843 215.384C315.729 209.68 315.182 203.858 315.21 198.023C315.33 190.763 316.271 183.549 318.01 176.546C321.138 163.531 326.99 151.506 335.097 141.433C343.203 131.361 353.339 123.522 364.691 118.545C376.229 113.252 388.794 111.262 401.218 112.758C416.104 114.196 430.292 120.361 442.063 130.505C456.258 142.259 466.386 159.001 470.772 177.96C472.315 184.25 473.1 190.736 473.106 197.251C472.817 221.287 477.111 245.121 485.71 267.212C491.204 281.764 498.638 295.332 507.766 307.466C511.821 313.073 516.186 318.398 520.837 323.413C541.999 345.611 568.273 361 596.809 367.91C603.394 369.464 610.058 370.58 616.765 371.254H623.533V355.564C608.797 355.224 594.241 351.911 580.587 345.79C557.492 336.509 537.003 320.728 521.157 300.017C505.311 279.305 494.659 254.384 490.261 227.731C488.554 218.377 487.771 208.848 487.927 199.309C487.994 179.703 482.976 160.484 473.464 143.916C463.952 127.347 450.342 114.119 434.237 105.788C418.131 97.4571 400.201 94.3709 382.558 96.8928C364.915 99.4147 348.292 107.44 334.649 120.023C321.005 132.605 310.908 149.223 305.546 167.917C300.185 186.612 299.782 206.605 304.386 225.544C308.989 244.482 318.407 261.576 331.531 274.814C344.654 288.052 360.937 296.882 378.461 300.264Z" fill="white" fillOpacity="0.1" />
        <path d="M348.003 269.141C361.619 279.901 377.928 285.743 394.683 285.86C404.898 285.919 415.089 286.951 425.142 288.947C443.062 292.749 460.217 300.067 475.79 310.552C503.94 329.489 526.077 357.568 539.159 390.93C546.784 410.236 550.983 430.972 551.529 452.018H566.467C565.305 420.836 557.296 390.43 543.127 363.409C544.294 362.252 545.11 363.409 545.927 363.409C566.666 376.227 589.597 384.154 613.264 386.686C616.531 386.686 619.916 387.458 623.183 387.587V371.254H616.415C609.708 370.58 603.044 369.464 596.459 367.91C568.048 360.934 541.902 345.55 520.837 323.413C516.175 318.274 511.81 312.819 507.766 307.08C498.638 294.946 491.204 281.379 485.71 266.827C477.111 244.736 472.817 220.901 473.106 196.865C473.1 190.35 472.315 183.864 470.772 177.575C466.313 158.756 456.192 142.161 442.063 130.505C430.456 120.475 416.485 114.316 401.802 112.758C389.378 111.262 376.812 113.252 365.274 118.545C353.923 123.522 343.787 131.361 335.68 141.433C327.574 151.506 321.722 163.531 318.594 176.546C316.855 183.549 315.914 190.763 315.793 198.023C315.765 203.858 316.313 209.68 317.427 215.385C320.965 236.971 331.915 256.224 348.003 269.141ZM330.381 194.808C330.783 176.048 337.931 158.233 350.253 145.282C362.575 132.33 379.06 125.303 396.083 125.747C413.106 126.19 429.272 134.068 441.025 147.646C452.778 161.225 459.154 179.392 458.752 198.151C458.785 231.348 466.539 263.98 481.275 292.933C481.761 293.932 482.19 294.963 482.559 296.02C477.307 293.062 473.106 290.49 468.671 288.304C445.756 276.211 420.674 269.927 395.266 269.913C386.424 270.076 377.648 268.217 369.491 264.453C361.334 260.688 353.974 255.101 347.876 248.043C341.779 240.984 337.076 232.609 334.064 223.446C331.053 214.284 329.798 204.532 330.381 194.808Z" fill="white" fillOpacity="0.3" />
        <path d="M392.814 355.692C397.696 355.689 402.571 356.075 407.402 356.849C428.677 360.112 448.28 371.36 462.906 388.696C477.532 406.033 486.286 428.396 487.692 452.017H497.495C497.303 447.047 496.757 442.102 495.861 437.227C491.347 411.366 478.826 388.035 460.436 371.217C442.046 354.399 418.929 345.139 395.032 345.017C369.027 345.099 343.576 336.743 321.852 320.992C300.128 305.241 283.092 282.791 272.868 256.442C262.643 230.093 259.683 201.01 264.357 172.819C269.03 144.629 281.13 118.578 299.148 97.9143C317.165 77.2509 340.304 62.8893 365.679 56.6201C391.053 50.3509 417.541 52.4514 441.84 62.6598C466.138 72.8682 487.173 90.7329 502.32 114.026C517.468 137.32 526.058 165.012 527.02 193.649C526.98 201.615 527.526 209.572 528.654 217.441C532.483 242.713 543.929 265.824 561.144 283.045C578.358 300.266 600.337 310.59 623.532 312.352V301.549C620.498 301.549 617.464 300.777 614.429 300.263C592.85 296.098 573.287 283.704 559.163 265.248C545.04 246.792 537.256 223.45 537.173 199.308C537.104 187.404 535.851 175.545 533.439 163.941C526.348 129.959 509.194 99.544 484.683 77.4925C460.172 55.441 429.698 43.0074 398.066 42.1523C374.163 41.1335 350.443 47.1248 329.329 59.5141C307.564 71.3635 288.986 89.2736 275.436 111.47C261.885 133.667 253.831 159.379 252.073 186.061C249.174 215.848 254.805 245.888 268.178 271.97C279.959 297.204 297.826 318.37 319.815 333.141C341.804 347.911 367.063 355.714 392.814 355.692Z" fill="white" fillOpacity="0.6" />
        <path d="M322.911 281.102C342.038 301.456 367.627 312.763 394.216 312.61C402.874 312.533 411.516 313.438 420.007 315.311C444.449 320.411 467.076 333.054 485.256 351.768C503.436 370.481 516.422 394.498 522.704 421.024C525.482 431.074 526.936 441.512 527.022 452.018H537.058C536.573 441.696 535.205 431.448 532.973 421.41C525.34 387.195 507.502 356.795 482.321 335.085C457.139 313.374 426.072 301.61 394.099 301.678C388.858 301.726 383.625 301.253 378.461 300.264C360.927 296.905 344.629 288.093 331.488 274.867C318.347 261.64 308.911 244.55 304.29 225.61C299.67 206.67 300.058 186.669 305.409 167.964C310.761 149.259 320.852 132.63 334.494 120.035C348.136 107.44 364.761 99.404 382.41 96.8747C400.058 94.3454 417.995 97.4278 434.107 105.759C450.219 114.089 463.834 127.322 473.349 143.896C482.863 160.47 487.881 179.696 487.81 199.309C487.654 208.848 488.437 218.377 490.144 227.73C494.542 254.384 505.194 279.305 521.04 300.016C536.886 320.728 557.375 336.509 580.47 345.79C594.124 351.911 608.68 355.224 623.416 355.564V344.89C618.018 344.529 612.642 343.842 607.312 342.832C576.595 336.877 548.764 319.189 528.703 292.872C508.642 266.555 497.635 233.293 497.613 198.923C497.73 189.762 496.908 180.616 495.162 171.659C488.904 143.884 473.391 119.722 451.707 103.974C430.023 88.2272 403.756 82.0485 378.127 86.6665C352.499 91.2845 329.385 106.361 313.382 128.899C297.379 151.437 289.657 179.786 291.752 208.311C293.564 236 304.673 261.952 322.911 281.102Z" fill="white" fillOpacity="0.6" />
        <path d="M623.533 95.91C608.18 95.4904 593.029 91.9592 578.836 85.4931C555.609 76.0294 535.114 59.866 519.492 38.6914C503.87 17.5168 493.694 -7.89215 490.027 -34.8813C483.643 -75.0365 491.764 -116.351 512.655 -149.995C533.546 -183.639 565.55 -206.944 601.827 -214.928C609.014 -216.101 616.268 -216.702 623.533 -216.729V-233.319C614.438 -233.367 605.377 -232.114 596.575 -229.589C575.5 -224.278 555.64 -214.254 538.225 -200.139C526.506 -190.814 515.952 -179.831 506.832 -167.473C494.74 -150.398 485.638 -130.991 479.991 -110.244C476.309 -97.0439 474.114 -83.395 473.455 -69.6047C472.823 -61.0451 472.823 -52.4432 473.455 -43.8836C474.389 -35.9101 475.556 -27.9366 477.19 -20.0917C480.416 -5.10881 485.522 9.30516 492.361 22.7338C501.377 40.6467 513.234 56.6324 527.371 69.9319C538.808 80.8129 551.527 89.9407 565.182 97.0675C583.569 106.221 603.375 111.416 623.533 112.371V95.91Z" fill="white" fillOpacity="0.3" />
        <path d="M623.533 68.7744C600.261 67.597 577.869 58.624 559.347 43.0533C536.638 24.3682 520.875 -2.78497 515.001 -33.338C512.264 -46.3142 511.554 -59.7093 512.9 -72.9484C514.859 -96.6959 522.96 -119.325 536.24 -138.151C546.777 -153.737 560.383 -166.488 576.035 -175.447C588.058 -182.224 600.977 -186.868 614.313 -189.207C617.464 -189.207 620.615 -190.365 623.883 -190.622V-206.44C613.405 -206.145 603.024 -204.152 593.074 -200.525C569.195 -193.44 547.681 -178.906 531.119 -158.67C514.557 -138.433 503.651 -113.356 499.713 -86.452C493.572 -49.5948 500.599 -11.5482 519.326 19.7307C538.053 51.0096 567.028 73.0985 600.192 81.3777C607.866 83.3492 615.671 84.6394 623.533 85.2359V68.7744Z" fill="white" fillOpacity="0.1" />
        <path d="M623.533 53.3418C615.931 52.8025 608.412 51.292 601.127 48.8407C582.367 43.086 565.535 31.4352 552.686 15.3112C539.837 -0.812668 531.527 -20.7116 528.772 -41.9546C526.526 -56.6752 526.954 -71.7406 530.031 -86.2777C533.108 -100.815 538.772 -114.534 546.697 -126.64C554.621 -138.747 564.648 -148.999 576.195 -156.803C587.742 -164.607 600.579 -169.807 613.964 -172.103C617.12 -172.792 620.319 -173.222 623.533 -173.389V-191.008C620.266 -191.008 617.115 -190.108 613.964 -189.593C600.627 -187.254 587.709 -182.61 575.686 -175.832C560.034 -166.874 546.428 -154.123 535.891 -138.537C522.61 -119.711 514.51 -97.0816 512.551 -73.3341C511.204 -60.095 511.915 -46.6999 514.651 -33.7238C520.526 -3.1707 536.289 23.9825 558.998 42.6676C577.52 58.2382 599.912 67.2113 623.183 68.3886L623.533 53.3418Z" fill="white" fillOpacity="0.3" />
        <path d="M623.534 85.2353C615.673 84.6389 607.867 83.3486 600.193 81.3771C567.115 72.9857 538.252 50.8499 519.62 19.5835C500.988 -11.6828 494.024 -49.6674 500.181 -86.4526C504.119 -113.356 515.025 -138.434 531.587 -158.67C548.149 -178.907 569.663 -193.441 593.542 -200.525C603.231 -204.067 613.331 -206.059 623.534 -206.441V-216.729C616.24 -216.278 608.986 -215.246 601.827 -213.643C565.551 -205.658 533.547 -182.353 512.656 -148.709C491.765 -115.065 483.643 -73.751 490.028 -33.5959C493.695 -6.6067 503.871 18.8022 519.493 39.9768C535.115 61.1515 555.61 77.3148 578.837 86.7785C593.029 93.2447 608.181 96.7759 623.534 97.1956V85.2353Z" fill="white" fillOpacity="0.6" />
        <path d="M623.533 25.5633C616.32 24.7855 609.223 23.0141 602.41 20.2906C589.537 14.8024 578.182 5.71388 569.465 -6.07997C560.748 -17.8738 554.968 -31.9664 552.695 -46.9699C550.819 -60.2067 551.762 -73.7429 555.448 -86.5096C559.134 -99.2762 565.463 -110.924 573.935 -120.532C584.917 -133.535 599.255 -142.513 615.014 -146.253C617.823 -146.922 620.669 -147.395 623.533 -147.668V-162.972C618.632 -162.525 613.761 -161.752 608.945 -160.657C588.186 -155.047 569.803 -141.758 556.865 -123.009C543.928 -104.26 537.219 -81.1862 537.861 -57.6427C538.504 -34.0992 546.457 -11.5114 560.393 6.34773C574.328 24.2068 593.403 36.2557 614.43 40.4816C617.464 40.4816 620.499 41.5104 623.533 41.7676V25.5633Z" fill="white" fillOpacity="0.1" />
        <path d="M623.533 9.23077C608.444 7.3851 594.446 -0.326324 584.051 -12.5196C573.656 -24.7129 567.544 -40.5897 566.814 -57.3005C566.083 -74.0113 570.78 -90.4622 580.063 -103.701C589.346 -116.939 602.606 -126.098 617.464 -129.534C619.448 -129.534 621.549 -129.534 623.533 -130.306V-147.024C620.669 -146.751 617.823 -146.279 615.013 -145.61C599.255 -141.87 584.917 -132.892 573.935 -119.889C565.463 -110.28 559.134 -98.6329 555.448 -85.8663C551.761 -73.0996 550.819 -59.5634 552.695 -46.3266C554.968 -31.3231 560.747 -17.2305 569.465 -5.43668C578.182 6.35718 589.536 15.4457 602.41 20.9339C609.223 23.6574 616.319 25.4288 623.533 26.2066V9.23077Z" fill="white" fillOpacity="0.3" />
        <path d="M623.533 41.8957C620.498 41.8957 617.464 41.1241 614.43 40.6097C593.344 36.4438 574.2 24.4135 560.208 6.53699C546.217 -11.3395 538.229 -33.9747 537.586 -57.5714C536.943 -81.1681 543.683 -104.291 556.676 -123.06C569.669 -141.83 588.124 -155.104 608.945 -160.657C613.761 -161.752 618.632 -162.525 623.533 -162.972V-174.032C620.318 -173.865 617.12 -173.435 613.963 -172.746C600.579 -170.451 587.741 -165.25 576.194 -157.446C564.647 -149.642 554.621 -139.39 546.696 -127.284C538.772 -115.177 533.107 -101.458 530.03 -86.921C526.953 -72.384 526.525 -57.3184 528.771 -42.5978C531.527 -21.3549 539.836 -1.45601 552.685 14.6679C565.535 30.7918 582.367 42.4427 601.126 48.1974C608.388 50.8669 615.908 52.5934 623.533 53.3416V41.8957Z" fill="white" fillOpacity="0.6" />
        <path d="M623.533 258.082H620.615C608.062 255.531 596.734 248.165 588.638 237.29C580.542 226.414 576.202 212.731 576.386 198.666C576.157 175.242 572.207 152.042 564.716 130.119C564.716 129.091 563.432 128.062 564.716 126.776C583.417 134.472 603.117 138.814 623.066 139.636V128.705C596.479 127.305 570.551 119.116 547.327 104.784C546.16 104.784 545.227 102.727 543.593 103.37C543.862 104.879 544.463 106.292 545.343 107.485C558.659 134.116 565.945 163.895 566.583 194.293C566.464 200.716 566.933 207.136 567.983 213.456C570.802 227.921 577.687 241.039 587.66 250.944C597.632 260.849 610.185 267.036 623.533 268.627V258.082Z" fill="white" fillOpacity="0.6" />
        <path d="M166.765 112.628C70.8371 112.628 -0.117041 35.465 0.466463 -61.7604C0.834763 -95.8507 10.3027 -129.067 27.6892 -157.266C45.0758 -185.466 69.6115 -207.4 98.2359 -220.333C126.86 -233.266 158.307 -236.626 188.652 -229.993C218.998 -223.36 246.9 -207.029 268.879 -183.035C283.836 -167.224 295.695 -148.203 303.723 -127.146C311.752 -106.089 315.78 -83.4421 315.559 -60.603C316.138 -17.7048 301.626 23.7749 275.064 55.1415C261.474 72.2908 244.871 86.2265 226.257 96.1069C207.643 105.987 187.405 111.608 166.765 112.628ZM157.663 96.1666C185.797 96.1663 213.3 86.9804 236.701 69.7685C260.102 52.5565 278.35 28.0902 289.143 -0.541748C299.936 -29.1737 302.79 -60.6877 297.343 -91.1053C291.897 -121.523 278.395 -149.48 258.542 -171.448C238.688 -193.416 213.374 -208.41 185.795 -214.536C158.215 -220.663 129.607 -217.647 103.581 -205.87C77.5555 -194.094 55.2795 -174.084 39.5652 -148.367C23.8509 -122.65 15.4028 -92.3785 15.2874 -61.3746C15.1951 -40.7117 18.8106 -20.2325 25.9257 -1.11476C33.0408 18.003 43.5153 35.382 56.7468 50.0229C69.9783 64.6638 85.7057 76.2779 103.025 84.1969C120.344 92.1159 138.912 96.1838 157.663 96.1666Z" fill="white" fillOpacity="0.3" />
        <path d="M306.689 35.4652C322.192 6.19145 330.361 -27.0945 330.361 -60.9886C330.361 -94.8826 322.192 -128.169 306.689 -157.442C333.301 -140.511 363.487 -131.598 394.215 -131.598C424.943 -131.598 455.13 -140.511 481.741 -157.442C466.437 -128.258 458.38 -95.1457 458.38 -61.4387C458.38 -27.7318 466.437 5.38046 481.741 34.565C455.058 17.7705 424.834 9.01201 394.106 9.17003C363.378 9.32806 333.229 18.397 306.689 35.4652ZM327.696 12.5735C370.585 -5.62628 418.079 -5.62628 460.968 12.5735C444.341 -34.5345 444.341 -86.7996 460.968 -133.908C439.741 -124.894 417.197 -120.23 394.449 -120.147C371.633 -120.209 349.017 -124.828 327.696 -133.779C344.27 -86.7045 344.27 -34.501 327.696 12.5735Z" fill="white" fillOpacity="0.6" />
        <path d="M245.072 103.498C229.896 132.543 221.908 165.471 221.908 198.987C221.908 232.503 229.896 265.431 245.072 294.476C218.434 277.633 188.258 268.77 157.546 268.77C126.835 268.77 96.6585 277.633 70.0205 294.476C85.3817 265.313 93.4723 232.193 93.4723 198.473C93.4723 164.752 85.3817 131.632 70.0205 102.469C96.5692 119.494 126.705 128.549 157.422 128.729C188.138 128.91 218.36 120.21 245.072 103.498ZM224.766 271.97C216.446 248.528 212.171 223.58 212.162 198.408C212.259 173.466 216.491 148.754 224.649 125.489C203.385 134.519 180.801 139.182 158.013 139.25C135.191 139.132 112.577 134.47 91.2601 125.489C95.3684 137.294 98.5314 149.472 100.713 161.884C102.769 174.143 103.785 186.585 103.747 199.051C103.731 211.344 102.716 223.611 100.713 235.704C98.4106 247.959 95.3696 260.032 91.6102 271.842C134.485 253.753 181.92 253.799 224.766 271.97Z" fill="white" fillOpacity="0.6" />
        <path d="M481.975 295.376C476.724 292.418 472.522 289.846 468.088 287.66C445.172 275.567 420.09 269.283 394.683 269.269C382.131 269.317 369.846 265.278 359.369 257.659C348.893 250.04 340.692 239.181 335.795 226.445C330.898 213.709 329.524 199.662 331.845 186.068C334.166 172.474 340.078 159.939 348.84 150.034C357.602 140.13 368.824 133.298 381.096 130.396C393.369 127.494 406.146 128.651 417.824 133.722C429.502 138.793 439.561 147.552 446.739 158.9C453.916 170.247 457.892 183.679 458.168 197.508C458.201 230.704 465.956 263.337 480.691 292.29C481.177 293.288 481.606 294.319 481.975 295.376ZM460.152 271.97C460.152 270.298 460.152 269.141 459.335 267.855C452.145 246.044 448.433 223.028 448.365 199.823C448.454 187.929 445.315 176.277 439.352 166.363C433.389 156.449 424.874 148.725 414.899 144.182C404.924 139.64 393.944 138.486 383.37 140.869C372.795 143.251 363.108 149.062 355.552 157.555C347.996 166.049 342.915 176.836 340.962 188.534C339.009 200.232 340.273 212.306 344.592 223.207C348.91 234.108 356.086 243.337 365.199 249.712C374.311 256.086 384.944 259.314 395.733 258.981C416.056 259.051 436.22 262.921 455.367 270.427C456.817 271.382 458.464 271.913 460.152 271.97Z" fill="white" fillOpacity="0.6" />
        <path d="M157.662 96.1676C129.474 96.0134 101.962 86.6415 78.6114 69.2396C55.2611 51.8377 37.1238 27.1889 26.4976 -1.58337C15.8715 -30.3557 13.2348 -61.9565 18.9217 -92.3818C24.6085 -122.807 38.363 -150.687 58.4422 -172.49C78.5214 -194.292 104.022 -209.036 131.712 -214.852C159.402 -220.668 188.035 -217.296 213.985 -205.161C239.934 -193.027 262.03 -172.677 277.474 -146.691C292.918 -120.704 301.015 -90.2498 300.738 -59.1873C300.337 -17.72 285.068 21.8891 258.263 50.995C231.458 80.1008 195.293 96.3398 157.662 96.1676ZM157.662 -206.569C131.467 -206.416 105.9 -197.715 84.1888 -181.564C62.4772 -165.413 45.5942 -142.535 35.6709 -115.819C25.7475 -89.1033 23.2287 -59.7464 28.4323 -31.4542C33.6359 -3.16202 46.3287 22.7972 64.9085 43.1467C83.4883 63.4962 107.122 77.3239 132.827 82.8842C158.532 88.4446 185.155 85.4883 209.337 74.3887C233.519 63.2891 254.174 44.5436 268.697 20.5185C283.219 -3.50668 290.958 -31.7345 290.935 -60.602C290.811 -99.3397 276.769 -136.446 251.88 -163.802C226.99 -191.158 193.281 -206.535 158.129 -206.569H157.662Z" fill="white" fillOpacity="0.6" />
        <path d="M327.696 12.574C344.271 -34.5006 344.271 -86.7041 327.696 -133.779C349.017 -124.828 371.634 -120.209 394.449 -120.146C417.198 -120.23 439.741 -124.893 460.969 -133.907C444.341 -86.7992 444.341 -34.534 460.969 12.574C418.08 -5.62587 370.586 -5.62587 327.696 12.574ZM351.036 -108.186V-106.643C353.128 -96.3144 354.494 -85.8234 355.121 -75.2633C356.143 -55.4376 354.809 -35.5497 351.153 -16.105C350.453 -12.3755 350.453 -12.3754 353.954 -13.1471C368.408 -16.5361 383.179 -18.0042 397.95 -17.5197C400.751 -17.5197 403.552 -17.5197 406.353 -17.5197C409.154 -17.5197 414.872 -16.748 419.073 -16.105C423.274 -15.462 430.743 -14.1759 436.111 -12.8899C437.979 -12.8899 438.562 -12.8899 438.329 -15.0761C438.095 -17.2624 437.629 -20.4775 437.162 -23.1782C435.183 -35.0253 434.012 -47.0192 433.661 -59.0591C433.639 -64.9013 433.912 -70.7405 434.478 -76.5494C434.478 -82.0794 435.528 -87.4808 436.228 -93.0108C436.928 -98.5408 437.512 -101.113 438.212 -105.871C438.912 -110.63 438.212 -109.858 434.828 -109.344C434.362 -109.402 433.893 -109.402 433.427 -109.344C420.844 -106.648 408.08 -105.1 395.266 -104.714C390.715 -104.714 386.163 -105.486 381.612 -105.614C374.027 -105.614 366.674 -107.543 359.206 -108.572C356.171 -107.672 353.604 -109.601 350.57 -108.186H351.036Z" fill="white" fillOpacity="0.3" />
        <path d="M224.766 271.97C181.905 253.84 134.471 253.84 91.6099 271.97C95.3693 260.16 98.4103 248.087 100.713 235.832C102.715 223.739 103.731 211.472 103.747 199.179C103.785 186.713 102.769 174.271 100.713 162.012C98.5311 149.6 95.3681 137.422 91.2598 125.617C112.577 134.598 135.191 139.26 158.013 139.378C180.801 139.31 203.385 134.647 224.649 125.617C216.491 148.882 212.259 173.595 212.162 198.536C212.185 223.665 216.46 248.568 224.766 271.97ZM201.425 246.249C201.509 245.738 201.509 245.216 201.425 244.705C201.579 243.511 201.579 242.299 201.425 241.104C198.978 227.455 197.572 213.602 197.224 199.694C197.224 194.292 197.224 189.019 198.041 183.618C198.858 178.217 199.091 171.658 199.792 165.742C199.792 161.755 200.959 157.897 201.776 154.039C202.592 150.181 201.776 149.924 198.508 150.309H197.108C186.393 152.844 175.499 154.35 164.548 154.81C158.603 155.259 152.638 155.259 146.693 154.81C137.016 154.037 127.39 152.62 117.868 150.567C114.833 150.567 114.367 150.567 114.833 153.782C116.707 163.706 117.955 173.761 118.568 183.875C119.745 203.696 118.449 223.601 114.717 243.034C114.016 246.892 114.717 246.892 117.401 246.12C128.914 243.412 140.64 241.948 152.411 241.748C157.896 241.748 163.381 241.748 168.866 241.748C174.342 241.959 179.799 242.56 185.204 243.548L201.425 246.249Z" fill="white" fillOpacity="0.3" />
        <path d="M460.152 271.971C458.464 271.913 456.817 271.382 455.367 270.427C436.22 262.921 416.055 259.051 395.733 258.981C384.976 259.289 374.381 256.049 365.305 249.679C356.23 243.308 349.087 234.097 344.792 223.224C340.497 212.352 339.245 200.314 341.198 188.653C343.15 176.992 348.218 166.238 355.751 157.771C363.284 149.304 372.94 143.508 383.481 141.126C394.022 138.745 404.969 139.886 414.918 144.403C424.866 148.921 433.365 156.609 439.324 166.482C445.283 176.356 448.431 187.965 448.365 199.823C448.433 223.028 452.144 246.045 459.335 267.855C460.152 268.755 460.152 269.913 460.152 271.971ZM355.121 199.309C355.108 209.419 358.331 219.21 364.223 226.959C367.635 231.769 371.99 235.667 376.961 238.362C381.932 241.056 387.392 242.478 392.932 242.52C406.864 242.859 420.752 244.364 434.478 247.021C438.329 247.793 438.912 247.021 438.212 243.034C438.28 242.566 438.28 242.088 438.212 241.62C436.388 231.309 435.257 220.863 434.828 210.369C434.828 204.324 433.777 198.28 434.011 192.235C434.067 191.723 434.067 191.205 434.011 190.692C432.034 180.136 426.578 170.76 418.697 164.376C410.816 157.992 401.068 155.052 391.335 156.123C381.602 157.194 372.574 162.2 365.994 170.175C359.414 178.151 355.748 188.53 355.704 199.309H355.121Z" fill="white" fillOpacity="0.1" />
        <path d="M158.129 -206.569C184.339 -206.442 209.927 -197.756 231.659 -181.608C253.391 -165.461 270.292 -142.577 280.226 -115.848C290.16 -89.1193 292.682 -59.7451 287.472 -31.4375C282.263 -3.12985 269.556 22.8408 250.957 43.1928C232.359 63.5449 208.703 77.3649 182.98 82.9065C157.256 88.448 130.62 85.4624 106.435 74.327C82.2512 63.1917 61.6049 44.4062 47.1054 20.3444C32.606 -3.71735 24.9042 -31.9755 24.9731 -60.8593C25.019 -80.0666 28.5013 -99.0754 35.2207 -116.798C41.9401 -134.521 51.7648 -150.609 64.1326 -164.143C76.5003 -177.677 91.1684 -188.39 107.297 -195.67C123.426 -202.95 140.7 -206.654 158.129 -206.569ZM158.129 68.9032C181.403 68.8269 204.134 61.1517 223.452 46.847C242.77 32.5422 257.808 12.2495 266.667 -11.4681C275.526 -35.1858 277.808 -61.2644 273.225 -86.4104C268.642 -111.556 257.4 -134.642 240.918 -152.751C224.436 -170.86 203.454 -183.18 180.622 -188.156C157.791 -193.132 134.133 -190.54 112.637 -180.707C91.141 -170.874 72.7714 -154.242 59.8482 -132.911C46.9249 -111.58 40.0277 -86.5075 40.0275 -60.8593C39.8863 -43.7319 42.8512 -26.746 48.7481 -10.8985C54.6449 4.94905 63.355 19.3388 74.367 31.4263C85.3791 43.5137 98.4713 53.0553 112.875 59.4909C127.279 65.9265 142.704 69.1265 158.246 68.9032H158.129Z" fill="white" fillOpacity="0.1" />
        <path d="M201.192 247.021L184.504 244.192C179.099 243.204 173.641 242.602 168.166 242.391C162.681 242.391 157.196 242.391 151.711 242.391C139.94 242.592 128.214 244.056 116.701 246.764C113.433 247.536 113.316 247.536 114.017 243.677C117.749 224.245 119.044 204.34 117.868 184.519C117.254 174.405 116.007 164.35 114.133 154.426C114.133 151.082 114.133 150.567 117.167 151.21C126.689 153.264 136.315 154.681 145.993 155.454C151.937 155.902 157.903 155.902 163.848 155.454C174.799 154.994 185.693 153.487 196.407 150.953H197.808C200.725 150.953 201.659 150.953 201.076 154.683C200.492 158.412 199.675 162.399 199.092 166.386C198.391 172.302 197.808 178.346 197.341 184.262C196.874 190.178 196.407 194.936 196.524 200.338C196.872 214.246 198.277 228.098 200.725 241.748C200.879 242.943 200.879 244.155 200.725 245.349C200.982 245.866 201.14 246.435 201.192 247.021ZM184.154 169.858C178.669 169.858 173.884 170.887 168.983 171.144C157.655 171.701 146.306 171.357 135.023 170.115C131.755 170.115 131.638 170.115 132.105 173.202C133.914 190.033 133.914 207.041 132.105 223.872C132.105 227.216 132.105 227.216 135.256 226.959C140.508 226.959 145.876 225.93 151.244 225.673C161.14 225.199 171.055 225.629 180.886 226.959C184.037 226.959 184.154 226.959 183.92 223.872C183.687 220.786 183.22 214.227 182.987 209.34C182.422 196.337 182.813 183.306 184.154 170.373V169.858Z" fill="white" fillOpacity="0.1" />
        <path d="M355.121 198.923C355.164 188.144 358.83 177.765 365.41 169.79C371.99 161.815 381.018 156.808 390.751 155.737C400.484 154.666 410.232 157.607 418.113 163.991C425.994 170.375 431.45 179.75 433.427 190.307C433.483 190.819 433.483 191.338 433.427 191.85C433.427 197.895 434.127 203.939 434.244 209.983C434.673 220.478 435.804 230.923 437.628 241.234C437.696 241.703 437.696 242.181 437.628 242.649C438.328 246.765 437.628 247.408 433.894 246.636C420.169 243.979 406.28 242.474 392.348 242.135C386.808 242.093 381.349 240.671 376.377 237.976C371.406 235.282 367.052 231.384 363.64 226.574C357.957 218.736 354.943 208.954 355.121 198.923ZM420.94 227.86C419.656 217.057 419.773 207.026 419.189 197.123C418.898 192.005 417.297 187.083 414.572 182.927C411.847 178.771 408.109 175.551 403.791 173.64C399.473 171.729 394.752 171.205 390.175 172.13C385.599 173.054 381.353 175.388 377.931 178.863C374.508 182.337 372.049 186.808 370.838 191.759C369.628 196.71 369.714 201.938 371.089 206.837C372.463 211.736 375.069 216.105 378.605 219.439C382.14 222.773 386.46 224.934 391.065 225.673C400.401 226.574 410.087 226.445 420.59 227.86H420.94Z" fill="white" fillOpacity="0.3" />
        <path d="M158.246 68.9028C134.922 68.8508 112.138 61.167 92.7825 46.8257C73.4271 32.4845 58.3725 12.1319 49.5277 -11.6509C40.683 -35.4338 38.4465 -61.5756 43.1021 -86.7611C47.7577 -111.947 59.0955 -135.041 75.6777 -153.116C92.2599 -171.191 113.34 -183.432 136.243 -188.286C159.147 -193.141 182.843 -190.391 204.327 -180.384C225.81 -170.378 244.113 -153.566 256.914 -132.08C269.715 -110.595 276.438 -85.404 276.23 -59.7023C275.984 -25.4329 263.433 7.33452 241.324 31.4345C219.214 55.5345 189.344 69.0058 158.246 68.9028ZM158.246 52.9558C178.611 52.8795 198.5 46.1547 215.4 33.6305C232.3 21.1062 245.453 3.34433 253.199 -17.4123C260.944 -38.169 262.935 -60.9896 258.92 -82.9923C254.904 -104.995 245.062 -125.193 230.637 -141.036C216.212 -156.879 197.85 -167.656 177.871 -172.006C157.891 -176.357 137.19 -174.085 118.381 -165.479C99.5718 -156.873 83.4987 -142.318 72.1912 -123.652C60.8836 -104.986 54.8487 -83.0458 54.8486 -60.6026C54.8793 -30.4376 65.7508 -1.51405 85.0844 19.8399C104.418 41.1938 130.64 53.2396 158.012 53.3416L158.246 52.9558Z" fill="white" fillOpacity="0.3" />
        <path d="M158.013 53.3425C137.652 53.19 117.789 46.394 100.931 33.8123C84.0721 21.2307 70.9735 3.42731 63.2884 -17.3507C55.6032 -38.1286 53.6759 -60.9499 57.7497 -82.9338C61.8235 -104.918 71.7157 -125.079 86.1779 -140.873C100.64 -156.667 119.024 -167.386 139.009 -171.676C158.994 -175.966 179.685 -173.636 198.469 -164.98C217.253 -156.323 233.29 -141.728 244.554 -123.037C255.818 -104.346 261.805 -82.3962 261.76 -59.9585C261.699 -45.012 258.967 -30.2252 253.72 -16.4423C248.473 -2.65933 240.814 9.84976 231.18 20.3708C221.546 30.8918 210.126 39.2187 197.572 44.876C185.018 50.5333 171.576 53.4102 158.013 53.3425ZM158.013 42.4111C176.478 42.4111 194.528 36.377 209.881 25.072C225.234 13.7669 237.2 -2.30141 244.267 -21.101C251.333 -39.9006 253.182 -60.5871 249.579 -80.5447C245.977 -100.502 237.085 -118.834 224.029 -133.223C210.972 -147.612 194.337 -157.41 176.226 -161.38C158.116 -165.35 139.344 -163.313 122.285 -155.525C105.225 -147.738 90.6445 -134.552 80.3859 -117.632C70.1273 -100.713 64.6518 -80.8215 64.6518 -60.473C64.744 -33.32 74.5729 -7.30826 91.9958 11.8919C109.419 31.0921 133.023 41.9237 157.662 42.0253L158.013 42.4111Z" fill="white" fillOpacity="0.6" />
        <path d="M157.663 42.024C139.198 42.024 121.147 35.99 105.794 24.685C90.441 13.3799 78.4747 -2.68845 71.4085 -21.4881C64.3422 -40.2877 62.4934 -60.9742 66.0957 -80.9317C69.698 -100.889 78.5898 -119.222 91.6466 -133.61C104.703 -147.999 121.339 -157.797 139.449 -161.767C157.559 -165.737 176.331 -163.7 193.39 -155.913C210.45 -148.125 225.031 -134.939 235.289 -118.019C245.548 -101.1 251.023 -81.2086 251.023 -60.86C251.023 -33.5735 241.187 -7.40453 223.679 11.89C206.17 31.1845 182.423 42.024 157.663 42.024ZM236.669 -60.86C236.669 -83.883 228.37 -105.963 213.597 -122.243C198.824 -138.523 178.788 -147.668 157.896 -147.668C137.004 -147.668 116.968 -138.523 102.195 -122.243C87.4221 -105.963 79.1228 -83.883 79.1228 -60.86C79.1228 -37.837 87.4221 -15.757 102.195 0.522736C116.968 16.8025 137.004 25.9484 157.896 25.9484C178.788 25.9484 198.824 16.8025 213.597 0.522736C228.37 -15.757 236.669 -37.837 236.669 -60.86Z" fill="white" fillOpacity="0.1" />
        <path d="M236.669 -60.8587C236.761 -43.6517 232.211 -26.8026 223.596 -12.4512C214.981 1.90021 202.691 13.1064 188.285 19.7442C173.879 26.3821 158.008 28.1522 142.687 24.8296C127.366 21.507 113.287 13.2416 102.238 1.08328C91.1883 -11.0751 83.6671 -26.5779 80.6292 -43.4565C77.5914 -60.335 79.174 -77.8276 85.1759 -93.7129C91.1779 -109.598 101.329 -123.16 114.339 -132.675C127.349 -142.19 142.632 -147.229 158.246 -147.153C168.552 -147.221 178.768 -145.036 188.303 -140.725C197.838 -136.414 206.503 -130.063 213.796 -122.038C221.089 -114.013 226.866 -104.474 230.792 -93.9724C234.718 -83.4712 236.715 -72.2162 236.669 -60.8587ZM157.779 -130.82C145.187 -130.718 132.905 -126.507 122.483 -118.718C112.061 -110.93 103.967 -99.9129 99.2229 -87.0586C94.4786 -74.2043 93.297 -60.0891 95.827 -46.4953C98.3571 -32.9014 104.485 -20.4384 113.438 -10.68C122.391 -0.921539 133.767 5.69475 146.129 8.3336C158.492 10.9724 171.287 9.51546 182.899 4.14662C194.51 -1.22223 204.418 -10.2622 211.371 -21.8319C218.324 -33.4015 222.01 -46.982 221.965 -60.8587C221.934 -70.1017 220.248 -79.2472 217.003 -87.7704C213.758 -96.2937 209.018 -104.027 203.054 -110.527C197.091 -117.027 190.022 -122.165 182.253 -125.648C174.483 -129.13 166.166 -130.888 157.779 -130.82Z" fill="white" fillOpacity="0.3" />
        <path d="M157.779 -130.821C170.372 -130.795 182.675 -126.659 193.135 -118.933C203.596 -111.208 211.745 -100.24 216.553 -87.4145C221.361 -74.5891 222.613 -60.4811 220.151 -46.872C217.689 -33.263 211.623 -20.7631 202.718 -10.9507C193.814 -1.1382 182.471 5.54683 170.122 8.26033C157.773 10.9738 144.971 9.5941 133.332 4.29538C121.694 -1.00333 111.741 -9.98347 104.731 -21.5111C97.7206 -33.0387 93.9669 -46.5969 93.9438 -60.4738C93.9129 -69.7214 95.543 -78.8844 98.7401 -87.4347C101.937 -95.9849 106.638 -103.753 112.572 -110.292C118.506 -116.832 125.555 -122.012 133.314 -125.535C141.073 -129.058 149.388 -130.855 157.779 -130.821ZM157.779 -1.05832C168.465 -0.982269 178.932 -4.39993 187.856 -10.8792C196.78 -17.3585 203.76 -26.6085 207.913 -37.4593C212.066 -48.3101 213.207 -60.2744 211.19 -71.8394C209.173 -83.4044 204.089 -94.0505 196.582 -102.432C189.074 -110.813 179.48 -116.552 169.013 -118.925C158.545 -121.297 147.675 -120.196 137.775 -115.76C127.876 -111.324 119.392 -103.752 113.398 -94.0031C107.403 -84.2539 104.166 -72.7646 104.097 -60.9883C104.064 -45.2604 109.656 -30.1518 119.66 -18.9346C129.664 -7.71748 143.275 -1.29504 157.546 -1.05832H157.779Z" fill="white" fillOpacity="0.6" />
      </g>
    </svg>


  )
}

export default DashboardTopPattern