import React from 'react'

const UploadIcon = () => {
  return (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.46083 0.520442C9.40227 0.456397 9.33102 0.40525 9.25161 0.370257C9.17219 0.335264 9.08636 0.317192 8.99958 0.317192C8.9128 0.317192 8.82697 0.335264 8.74756 0.370257C8.66815 0.40525 8.59689 0.456397 8.53833 0.520442L5.205 4.16627C5.09518 4.28902 5.03825 4.45015 5.04659 4.61464C5.05492 4.77913 5.12785 4.93369 5.24951 5.0447C5.37118 5.15571 5.53175 5.21422 5.69631 5.20749C5.86088 5.20076 6.01613 5.12935 6.12833 5.00877L8.375 2.55044V11.7754C8.375 11.9412 8.44085 12.1002 8.55806 12.2174C8.67527 12.3346 8.83424 12.4004 9 12.4004C9.16576 12.4004 9.32473 12.3346 9.44194 12.2174C9.55915 12.1002 9.625 11.9412 9.625 11.7754V2.55211L11.8725 5.01044C11.9844 5.13277 12.1404 5.20562 12.3061 5.21297C12.4717 5.22031 12.6335 5.16155 12.7558 5.04961C12.8782 4.93766 12.951 4.78171 12.9584 4.61605C12.9657 4.4504 12.9069 4.28861 12.795 4.16627L9.46083 0.520442Z" className='fill-current' />
      <path d="M2.125 10.9421C2.125 10.7763 2.05915 10.6174 1.94194 10.5002C1.82473 10.383 1.66576 10.3171 1.5 10.3171C1.33424 10.3171 1.17527 10.383 1.05806 10.5002C0.940848 10.6174 0.875 10.7763 0.875 10.9421V10.9879C0.875 12.1271 0.875 13.0463 0.9725 13.7688C1.0725 14.5188 1.28917 15.1504 1.79 15.6521C2.29167 16.1538 2.92333 16.3688 3.67333 16.4704C4.39583 16.5671 5.315 16.5671 6.45417 16.5671H11.5458C12.685 16.5671 13.6042 16.5671 14.3267 16.4704C15.0767 16.3688 15.7083 16.1538 16.21 15.6521C16.7117 15.1504 16.9267 14.5188 17.0283 13.7688C17.125 13.0463 17.125 12.1271 17.125 10.9879V10.9421C17.125 10.7763 17.0592 10.6174 16.9419 10.5002C16.8247 10.383 16.6658 10.3171 16.5 10.3171C16.3342 10.3171 16.1753 10.383 16.0581 10.5002C15.9408 10.6174 15.875 10.7763 15.875 10.9421C15.875 12.1379 15.8733 12.9721 15.7892 13.6021C15.7067 14.2138 15.5558 14.5371 15.3258 14.7679C15.095 14.9988 14.7717 15.1488 14.1592 15.2313C13.53 15.3154 12.6958 15.3171 11.5 15.3171H6.5C5.30417 15.3171 4.46917 15.3154 3.84 15.2313C3.22833 15.1488 2.905 14.9979 2.67417 14.7679C2.44333 14.5371 2.29333 14.2138 2.21083 13.6013C2.12667 12.9721 2.125 12.1379 2.125 10.9421Z" className='fill-current' />
    </svg>

  )
}

export default UploadIcon