import React, {useState} from "react"
import {useSelector} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom';
import axios from "axios"
import ProductCard from '../../components/ProductCard';
import {BURL, formatNum} from '../../services/base';
import DashboardIllustration from '../../components/Illustrations/DashboardIllustration'
import InvestPortfolioIll from '../../components/Illustrations/InvestPortfolioIll'
import DashboardTopPattern from '../../components/Patterns/DashboardTopPattern'
import RightArrAlt from '../../components/Icons/RightArrAlt';
import NoteIcon from '../../components/Icons/NoteIcon';
import InvestmentIcon from '../../components/Icons/InvestmentIcon';
import CashIcon from '../../components/Icons/CashIcon';
import InvPattern from '../../components/Patterns/InvPattern';
import Greeting from '../../components/Greeting';
import CustomButton from "../../components/CustomButton";
import {toast} from "react-toastify"

const Dashboard = () => {
	const navigate = useNavigate()
	const [inProgress, setInProgress] = useState(false)

	const {products, fetchingProducts, cash_balance, fetchingBalance, totalInvest, totalInterest, fetchingInvest, fetchingInterest, profileCompletion
	} = useSelector(state => state.profile);


	const closeAccount = () => {

		axios.post(`${BURL}close-account`).then(res => {
			if (!res?.data?.success) return
			localStorage.removeItem("token");
			localStorage.removeItem("remember_token");
			toast.success("Account Closed successfully");
			navigate("/login")

		}).catch(err => {
			if (err?.response?.data) {
				toast.error(err?.response?.data?.message)
			} else {
				toast.error("An error occured, please try again.")
			}
		})

	}

	return (
		<div className=''>
			<Greeting pre="Welcome" sub="How’re you doing today?" />
			{!(profileCompletion.personal && profileCompletion.banks && profileCompletion.employment && profileCompletion.docs) &&
				<div className='flex items-center py-3 px-5 rounded-2xl mb-5 bg-light dark:bg-dark'>
					<NoteIcon />
					<div className='mx-3'>
						<h3 className='font-bold text-lg'>Complete KYC</h3>
						<p className='text-[.65rem]'>You have a pending action to complete - click on the button to complete the action</p>
					</div>
					<Link to="/profile" className='ml-auto p-2 rounded-xl'>
						<svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fillRule="evenodd" clipRule="evenodd" d="M0.930512 0.66803C1.10677 0.51729 1.33566 0.442693 1.5669 0.460631C1.79813 0.478569 2.01278 0.587576 2.16368 0.763697L9.16368 8.93036C9.29955 9.08894 9.37424 9.29087 9.37424 9.4997C9.37424 9.70852 9.29955 9.91046 9.16368 10.069L2.16368 18.2357C2.01028 18.4032 1.79776 18.5045 1.57103 18.5182C1.34431 18.5318 1.12115 18.4568 0.948736 18.3089C0.776324 18.1611 0.668168 17.952 0.647132 17.7258C0.626096 17.4996 0.693828 17.2742 0.836011 17.097L7.34718 9.4997L0.836011 1.90236C0.684993 1.7264 0.609997 1.49769 0.627497 1.26647C0.644998 1.03525 0.753563 0.820426 0.929345 0.669197L0.930512 0.66803Z" className="fill-current" />
						</svg>
					</Link>

				</div>
			}
			<div className='grid gap-7 grid-cols-12'>
				<div className='col-span-12 flex flex-col justify-end lg:col-span-8'>
					<div className="bg-primary relative text-white max-w-full w-full flex rounded-3xl">
						<div className='w-full xl:w-1/2 p-5 md:p-10 flex items-center'>
							<h3 className="font-semibold text-2xl md:text-2xl max-w-sm">Achieve your Financial
								Goals without Hassles</h3>
						</div>
						<div className='absolute h-full w-full'>
							<DashboardTopPattern />
						</div>
						<div className='relative hidden xl:block w-1/2 lg:w-2/3'>
							<div className='absolute h-full w-fit right-10'>
								<DashboardIllustration />
							</div>
						</div>
					</div>
					<div className='grid gap-7 pb-5 col-span-12 lg:col-span-8 pt-5 md:grid-cols-2 xl:grid-cols-5'>
						<div className='flex relative flex-col px-6 py-6 md:row-span-2 xl:col-span-3 bg-white shadow-md dark:bg-dark rounded-2xl dark:text-white'>
							<InvPattern />
							<p className=''>Total Investment Portfolio</p>
							<h3 className='font-bold text-2xl font-chivo'>
								₦ {fetchingInvest ? '...' : formatNum(totalInvest)}
							</h3>
							<div className='flex mt-auto justify-end pt-6'>
								<InvestPortfolioIll />
							</div>
						</div>
						<div className='flex relative flex-col px-6 py-6 bg-white dark:bg-dark xl:col-span-2 rounded-2xl dark:text-white shadow-md'>
							<InvPattern />
							<span className='pb-10 flex justify-end'>
								<InvestmentIcon />
							</span>
							<p className=''>Total Interest Accrued</p>
							<h3 className='font-bold text-2xl font-chivo'>
								₦ {fetchingInterest ? '...' : formatNum(totalInterest)}
							</h3>
						</div>
						<div className='flex relative flex-col px-6 py-6 bg-white dark:bg-dark xl:col-span-2 rounded-2xl dark:text-white shadow-md'>
							<InvPattern />
							<span className='pb-10 flex justify-end'>
								<CashIcon />
							</span>
							<p className=''>Wallet Balance</p>
							<h3 className='font-bold text-2xl font-chivo'>
								₦ {fetchingBalance ? '...' : formatNum(cash_balance?.customerNairaBalance)}
							</h3>
						</div>
					</div>
				</div>
				<div className='w-full col-span-12 grid lg:col-span-4'>
					<div className="flex justify-between py-5 mt-auto">
						<h3 className='text-lg font-bold'>Products</h3>
						<Link to="/investment" className='text-xs flex space-x-3 group items-center'>
							<span>view all</span>
							<span className='-translate-x-1 group-hover:translate-x-0 transition-all'><RightArrAlt /></span>
						</Link>
					</div>
					{fetchingProducts && <small className='my-3 text-sm block'>Fetching products...</small>}
					<div className='grid gap-5 pb-5'>
						{Array.isArray(products) && products.filter((each, i) => i <= 1).map((props) => (<ProductCard isDark key={props.id} {...props} />))}
					</div>
				</div>
			</div>
			<div>
				{/* {cash_balance?.customerNairaBalance ==0 &&
				 	<CustomButton  loading={inProgress}  onClick={closeAccount}  className="py-3 font-medium justify-center text-md text-white " >
						<span>Close Account</span>
					</CustomButton>	 
				} */}

			</div>
		</div>
	)
}

export default Dashboard