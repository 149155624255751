import React from 'react'

const RightArrIcon = ({className}) => {
	return (
		<svg width="14" height="11" viewBox="0 0 16 13" className={className} xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd" d="M9.22504 1.05833C9.34223 0.94129 9.50108 0.875549 9.66671 0.875549C9.83233 0.875549 9.99119 0.94129 10.1084 1.05833L15.1084 6.05833C15.2254 6.17552 15.2912 6.33437 15.2912 6.5C15.2912 6.66562 15.2254 6.82448 15.1084 6.94167L10.1084 11.9417C10.0512 12.0031 9.98215 12.0523 9.90549 12.0865C9.82882 12.1206 9.74606 12.139 9.66214 12.1405C9.57822 12.142 9.49487 12.1265 9.41704 12.0951C9.33922 12.0637 9.26852 12.0169 9.20918 11.9575C9.14983 11.8982 9.10304 11.8275 9.0716 11.7497C9.04017 11.6718 9.02473 11.5885 9.02621 11.5046C9.02769 11.4206 9.04606 11.3379 9.08022 11.2612C9.11438 11.1846 9.16364 11.1156 9.22504 11.0583L13.1584 7.125H1.33337C1.16761 7.125 1.00864 7.05915 0.891432 6.94194C0.774222 6.82473 0.708374 6.66576 0.708374 6.5C0.708374 6.33424 0.774222 6.17527 0.891432 6.05806C1.00864 5.94085 1.16761 5.875 1.33337 5.875H13.1584L9.22504 1.94167C9.108 1.82448 9.04226 1.66562 9.04226 1.5C9.04226 1.33437 9.108 1.17552 9.22504 1.05833Z" className='fill-current' />
		</svg>

	)
}

export default RightArrIcon