import React from 'react'

const EditIconAlt = ({className = ''}) => {
  return (
    <svg className={`${className}`} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.3334 8.99996C17.3334 12.9283 17.3334 14.8925 16.1126 16.1125C14.8934 17.3333 12.9284 17.3333 9.00008 17.3333C5.07175 17.3333 3.10758 17.3333 1.88675 16.1125C0.666748 14.8933 0.666748 12.9283 0.666748 8.99996C0.666748 5.07163 0.666748 3.10746 1.88675 1.88663C3.10841 0.666626 5.07175 0.666626 9.00008 0.666626" className='stroke-current' strokeWidth="1.25" strokeLinecap="round" />
      <path d="M14.4684 1.44584L14.8159 1.09834C15.0935 0.826859 15.467 0.67582 15.8553 0.678002C16.2437 0.680183 16.6155 0.83541 16.89 1.11C17.1646 1.38458 17.3199 1.75637 17.322 2.14469C17.3242 2.53301 17.1732 2.90652 16.9017 3.18418L16.5542 3.53168M14.4684 1.44584C14.4684 1.44584 14.5117 2.18501 15.1634 2.83668C15.815 3.48834 16.5542 3.53168 16.5542 3.53168M14.4684 1.44584L11.2725 4.64168C11.0559 4.85834 10.9475 4.96668 10.8559 5.08584C10.7457 5.22654 10.6513 5.37883 10.5742 5.54001C10.5092 5.67668 10.4609 5.82168 10.3642 6.11168L10.0542 7.04168M16.5542 3.53168L13.3584 6.72751C13.1417 6.94418 13.0334 7.05251 12.9142 7.14418C12.7735 7.25431 12.6212 7.34876 12.46 7.42584C12.3234 7.49084 12.1784 7.53918 11.8884 7.63584L10.9584 7.94584M10.0542 7.04168L9.85336 7.64334C9.83003 7.71341 9.82669 7.7886 9.8437 7.86046C9.86071 7.93233 9.8974 7.99803 9.94967 8.05021C10.0019 8.10239 10.0677 8.13897 10.1396 8.15587C10.2115 8.17276 10.2867 8.16929 10.3567 8.14584L10.9584 7.94584M10.0542 7.04168L10.9584 7.94584" className='stroke-current' strokeWidth="1.25" />
    </svg>

  )
}

export default EditIconAlt