import axios from 'axios';
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import LoadingIcon from '../../components/Icons/LoadingIcon';
import {BURL, formatNum} from '../../services/base';
import {setTransactions} from '../../slices/userSlice';
// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';
import {Menu, MenuItem} from '@mui/material';
import ReactPaginate from 'react-paginate';
import TrxIcon from '../../components/Icons/TrxArrow'
import SearchIcon from '../../components/Icons/SearchIcon'
import Greeting from '../../components/Greeting';
import TrxStatement from '../../components/TrxStatement'

const Transactions = () => {
	const {transactions, fetchingTransactions, customer, data, cash_balance} = useSelector(state => state.profile);
	const isCORP = (customer?.label === "CORP");

	const dispatch = useDispatch();
	const getTransactions = useCallback(() => {
		axios.get(`${BURL}customer-transactions-table`).then(res => {
			if (!res?.data?.success) throw new Error(res);
			dispatch(setTransactions(res.data.message));
		}).catch(err => {

		})
	}, [dispatch])

	const [filteredTransactions, setfilteredTransactions] = useState(transactions ?? []);
	const [filter, setFilter] = useState({start_date: "", end_date: ""})

	const filterTrx = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		setFilter(current => ({...current, [name]: value}));
	}

	const search = () => {
		// use slice to slice away time
		const filtered = transactions ? transactions.filter(
			(each, i) => (
				new Date(each.created_at.slice(0, 10)).getTime() >=
				new Date(filter.start_date).getTime() &&
				new Date(each.created_at.slice(0, 10)).getTime() <=
				new Date(filter.end_date).getTime()
			) || (
					new Date(each.created_at.slice(0, 10)).getTime() <=
					new Date(filter.start_date).getTime() &&
					new Date(each.created_at.slice(0, 10)).getTime() >=
					new Date(filter.end_date).getTime()
				)
		) : []
		setfilteredTransactions(filtered)
	}

	useEffect(() => {
		setfilteredTransactions(transactions);
	}, [transactions])

	useEffect(() => {
		getTransactions()
	}, [getTransactions])

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};


	const trx = useRef()
	const trxTable = useRef()
	// const generatePDF = async () => {
	// 	handleClose()
	// 	const element = trx.current;
	// 	const canvas = await html2canvas(element);
	// 	const data = canvas.toDataURL('image/png');

	// 	const pdf = new jsPDF();
	// 	const imgProperties = pdf.getImageProperties(data);
	// 	const pdfWidth = pdf.internal.pageSize.getWidth();
	// 	const pdfHeight =
	// 		(imgProperties.height * pdfWidth) / imgProperties.width;

	// 	pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
	// 	pdf.save('transactions.pdf');
	// }



	// for pagination
	const [itemOffset, setItemOffset] = useState(0);
	const itemsPerPage = 10;
	// Simulate fetching items from another resources.
	// (This could be items from props; or items loaded in a local state
	// from an API endpoint with useEffect and useState)
	const endOffset = itemOffset + itemsPerPage;
	// console.log(`Loading items from ${itemOffset} to ${endOffset}`);
	const currentItems = filteredTransactions.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(filteredTransactions.length / itemsPerPage);

	// Invoke when user click to request another page.
	const handlePageClick = (event) => {
		const newOffset = (event.selected * itemsPerPage) % filteredTransactions.length;
		// console.log(
		// 	`User requested page number ${event.selected}, which is offset ${newOffset}`
		// );
		setItemOffset(newOffset);
	};

	return (
		<div>
			<Greeting sub="Here is your transaction history so far" />
			<div className='pb-5'>
				<div className='flex flex-wrap'>
					<input onChange={filterTrx} value={filter.start_date} name='start_date' className='mb-2 rounded-full md:mr-5 p-3 focus:border outline-none transition-all dark:bg-transparent dark:border dark:border-[#E8E8E84D] duration-500 text-sm w-full md:w-60 bg-light' placeholder='Start Date' type="date" />
					<input onChange={filterTrx} value={filter.end_date} name='end_date' className='mb-2 rounded-full md:mr-5 p-3 focus:border outline-none transition-all dark:bg-transparent dark:border dark:border-[#E8E8E84D] duration-500 text-sm w-full md:w-60 bg-light' placeholder='Stop Date' type="date" />
					<button onClick={search} className="font-medium px-7 text-sm py-3 mb-2 rounded-lg text-white flex justify-center items-center space-x-2 bg-trov-dark dark:bg-white dark:text-trov-dark ">
						<span>Search</span>
						<SearchIcon />
					</button>
					<button id="export-button"
						aria-controls={open ? 'export-options' : undefined}
						aria-haspopup="true"
						aria-expanded={open ? 'true' : undefined}
						onClick={handleClick} className='bg-primary text-white rounded-full h-10 w-10 ml-auto flex items-center justify-center p-1'>
						<svg width="14" height="16" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M16.2219 17.3342H1.78377C1.34282 17.3342 0.984314 17.6956 0.984314 18.1337V19.2006C0.984314 19.6415 1.34564 20 1.78377 20H16.2162C16.6572 20 17.0156 19.6386 17.0156 19.2006V18.1337C17.0242 17.6899 16.6629 17.3342 16.2219 17.3342Z" className='fill-current' />
							<path d="M8.44381 15.9033C8.6003 16.0598 8.80513 16.1394 9.00711 16.1394C9.21194 16.1394 9.41678 16.0598 9.57041 15.9033L15.0384 10.4353C15.3485 10.1252 15.3485 9.6188 15.0384 9.30299L14.2817 8.54623C13.9715 8.23611 13.4651 8.23611 13.1494 8.54623L10.3385 11.3599V0.79945C10.3385 0.358464 9.97722 0 9.53913 0H8.47225C8.0313 0 7.6728 0.3556 7.6728 0.79945V11.3627L4.85916 8.54906C4.54904 8.23898 4.03981 8.23898 3.72687 8.54906L2.97011 9.30582C2.66003 9.6159 2.66003 10.1252 2.97011 10.4381L8.44381 15.9033Z" className='fill-current' />
						</svg>
					</button>
					<Menu
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						id="export-options"
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
						MenuListProps={{
							'aria-labelledby': 'export-button',
						}}
					>
						{/* <DownloadTableExcel filename="Transactions"
							sheet="transactions"
							currentTableRef={trxTable.current}><MenuItem onClick={handleClose}>Export to XLSX</MenuItem></DownloadTableExcel> */}
						<MenuItem>
							<TrxStatement account={{
								'Print Date': new Date().toDateString(),
								'Account ID': customer.ref_id,
								'Address': data.address,
								'Account Type': isCORP ? 'Corperate Account' : 'Individial Account',
								'Currency': 'Naira',
								'Balance': `₦ ${formatNum(cash_balance?.customerNairaBalance)}`,
								// 'Dollar Balance': `$ ${formatNum(cash_balance?.customerDollarBalance)}`,
							}} details={(transactions && transactions[0]) ? transactions : []} />
						</MenuItem>
					</Menu>
				</div>

				{fetchingTransactions && <div className='min-h-[10rem] flex items-center justify-center'><LoadingIcon className='fill-gray-300' /></div>}
			</div >
			<div className='pb-5' id='transactions' ref={trx}>
				{!fetchingTransactions && <div className="flex justify-between rounded-t-3xl items-center dark:border-[#E8E8E84D] border py-3 px-5">
					<h3 className='text-lg font-bold'>Transactions</h3>
				</div>}
				{transactions && transactions[0] && !fetchingTransactions ?
					<table ref={trxTable} className='w-full'>
						<tbody>
							{
								Array.isArray(filteredTransactions) && currentItems?.map((each, i) => (
									<tr key={i}>
										<td className='border-b dark:border-[#E8E8E84D] border-l text-left text-sm py-3 px-5 flex items-center space-x-3'>
											<span className={`${each.action === 'DEPOSIT' ? 'text-green-700 rotate-180' : 'text-red-500'}`}>
												<TrxIcon />
											</span>
											<span className='flex flex-col space-y-1'>
												<span>{each.action}</span>
												<span className='text-primary text-xs'>
													{new Date(each.created_at).toDateString()}
												</span>
											</span>
										</td>
										<td className='border-b dark:border-[#E8E8E84D] text-sm py-3 px-5 border-r text-right'>
											<span className='grid'>
												<span className='font-semibold'>
													<span className='font-chivo'>{each.currency === 'USD' ? '$' : '₦'}</span>{formatNum(each.amount)}
												</span>
												<span className={`${each.status.toLowerCase() === "pending" ? 'text-yellow-700' :
													(each.status.toLowerCase() === "approved" || each.status.toLowerCase() === "completed") ? 'text-green-700' :
														each.status.toLowerCase() === "rejected" ? 'text-red-700' :
															'text-gray-700'
													} capitalize text-xs`
												}>
													{each.status.toLowerCase() === "approved" ? "Completed" : each.status.toLowerCase()}
												</span>
											</span>
										</td>
									</tr>
								))
							}
						</tbody>
					</table>
					: ''}

				{/* for pagination */}
				<div className='flex justify-end'>
					<ReactPaginate
						className='flex space-x-6 items-center my-4'
						breakLabel="..."
						nextLabel={<svg width="10" height="13" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1.39002 0.153566L5.96849 4.36912C6.16452 4.5406 6.16452 4.85498 5.96849 5.02646L1.39002 9.24201C1.09598 9.51352 0.619934 9.31346 0.619934 8.91334L0.619934 0.482237C0.619934 0.0821161 1.09598 -0.117944 1.39002 0.153566Z" fill="#8E8EA1" />
						</svg>
						}
						onPageChange={handlePageClick}
						pageRangeDisplayed={5}
						pageCount={pageCount}
						activeLinkClassName='bg-primary text-white border-none'
						pageLinkClassName='border-2 text-gray-400 rounded-full text-sm h-5 w-5 flex justify-center items-center'
						previousLabel={<svg width="10" height="13" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M5.33664 9.53198L0.758159 5.31643C0.562139 5.14495 0.562139 4.83057 0.758159 4.65909L5.33664 0.44354C5.63067 0.17203 6.10672 0.37209 6.10672 0.77221L6.10672 9.20331C6.10672 9.60343 5.63067 9.80349 5.33664 9.53198Z" fill="#8E8EA1" />
						</svg>
						}
						renderOnZeroPageCount={null}
					/>
				</div>
			</div>
		</div >
	)
}

export default Transactions