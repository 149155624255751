import React, {useMemo} from 'react'
import BookInvestment from './Dialogs/BookInvestment';
import RightArrIcon from './Icons/RightArrIcon';
import InvPattern from './Patterns/InvPattern'


const ProductCard = (props) => {
	const [investModalIsOpened, setInvestModalIsOpened] = React.useState(false);
	const cc = useMemo(() => ['#FFE8DA', '#DFFFE6', '#FFE8DA', '#FEE0FF', '#A8ABFF', '#FEE0FF', '#FFA9A4'], [])
	const randN = useMemo(() => Math.floor(Math.random() * ((cc.length - 1) + 1)), [cc]);

	return (
		<>
			<div style={{
				backgroundColor: props?.isDark ? '#212223' : cc[randN]
			}} className={`flex ${props.isDark ? 'px-5 pb-7 pt-10 rounded-2xl' : 'p-5 rounded-xl'} relative`}>
				<div className={`${props.full ? 'block' : 'hidden'}`}>
					<InvPattern />
				</div>
				<div className={`flex flex-col relative h-full w-full ${props.isDark ? 'text-white' : 'text-black'}`}>
					<h3 className="font-bold text-xl">{props.name}</h3>
					<p className='text-xs mb-6 mt-1'>{props.description}</p>
					<button onClick={() => setInvestModalIsOpened(true)} className='bg-white mt-auto px-10 py-3 w-fit font-semibold text-sm outline-none rounded-md flex items-center space-x-3 text-dark'>
						<span>Invest</span>
						<RightArrIcon />
					</button>
				</div>
				<div className={`pl-3 lg:pl-6 xl:px-6 flex flex-col h-full ${props.full ? 'md:block' : 'hidden'}`}>
					{/* <div className='flex justify-end pb-5'>
						{[0, 1, 2, 4].map((each, i) => (
							<img key={i} src={profileImg} alt="profile" className='h-8 w-8 rounded-full -ml-3' />
						))}
					</div>
					<div className='mt-auto'>
						<img src={productImg} alt='product' className='rounded-lg h-[5.5rem] min-w-[5.5rem] w-[5.5rem]' />
					</div> */}
				</div>
			</div>
			<BookInvestment
				{...props}
				open={investModalIsOpened}
				setOpen={setInvestModalIsOpened}
			/>
		</>
	)
}

export default ProductCard