import * as React from "react";

function WhatsIcon(props) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 192 192"
			fill="none"
			stroke="currentColor"
			strokeWidth={0.002}
			{...props}
		>
			<path
				className="fill-green-600"
				fillRule="evenodd"
				d="M96 16c-44.183 0-80 35.817-80 80 0 13.12 3.163 25.517 8.771 36.455l-8.608 36.155a6.002 6.002 0 007.227 7.227l36.155-8.608C70.483 172.837 82.88 176 96 176c44.183 0 80-35.817 80-80s-35.817-80-80-80zM28 96c0-37.555 30.445-68 68-68s68 30.445 68 68-30.445 68-68 68c-11.884 0-23.04-3.043-32.747-8.389a6.003 6.003 0 00-4.284-.581l-28.874 6.875 6.875-28.874a6.001 6.001 0 00-.581-4.284C31.043 119.039 28 107.884 28 96zm46.023 21.977c11.975 11.974 27.942 20.007 45.753 21.919 11.776 1.263 20.224-8.439 20.224-18.517v-6.996a18.956 18.956 0 00-13.509-18.157l-.557-.167-.57-.112-8.022-1.58a18.958 18.958 0 00-15.25 2.568 42.144 42.144 0 01-7.027-7.027 18.958 18.958 0 002.569-15.252l-1.582-8.021-.112-.57-.167-.557A18.955 18.955 0 0077.618 52H70.62c-10.077 0-19.78 8.446-18.517 20.223 1.912 17.81 9.944 33.779 21.92 45.754zm33.652-10.179a6.955 6.955 0 016.916-1.743l8.453 1.665a6.957 6.957 0 014.956 6.663v6.996c0 3.841-3.124 6.995-6.943 6.585a63.903 63.903 0 01-26.887-9.232 64.594 64.594 0 01-11.661-9.241 64.592 64.592 0 01-9.241-11.661 63.917 63.917 0 01-9.232-26.888C63.626 67.123 66.78 64 70.62 64h6.997a6.955 6.955 0 016.66 4.957l1.667 8.451a6.956 6.956 0 01-1.743 6.917l-1.12 1.12a5.935 5.935 0 00-1.545 2.669c-.372 1.403-.204 2.921.603 4.223a54.119 54.119 0 007.745 9.777 54.102 54.102 0 009.778 7.746c1.302.806 2.819.975 4.223.603a5.94 5.94 0 002.669-1.545l1.12-1.12z"
				clipRule="evenodd"
			/>
		</svg>
	);
}

const MemoWhatsIcon = React.memo(WhatsIcon);
export default MemoWhatsIcon;
