import React, {useEffect, useState} from 'react'
import {Outlet} from 'react-router'
import LoadingIcon from './Icons/LoadingIcon';

const LoaderWrap = () => {

	const loader = <div className='flex text-primary h-screen items-center justify-center'>
		<LoadingIcon className='fill-primary'/>
	</div>

	const [currentView, setCurrentView] = useState(loader);
	useEffect(() => {
		const timeout = setTimeout(() => {
			setCurrentView(<Outlet />)
		}, 2000);
		return () => {
			clearTimeout(timeout);
		}
	}, [])

	return currentView
}

export default LoaderWrap