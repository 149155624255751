import React from 'react'

const NoteIcon = () => {
	return (
		<svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M9.83366 6.16732C9.83366 5.9463 9.74586 5.73434 9.58958 5.57806C9.4333 5.42178 9.22134 5.33398 9.00033 5.33398C8.77931 5.33398 8.56735 5.42178 8.41107 5.57806C8.25479 5.73434 8.16699 5.9463 8.16699 6.16732C8.16699 6.38833 8.25479 6.60029 8.41107 6.75657C8.56735 6.91285 8.77931 7.00065 9.00033 7.00065C9.22134 7.00065 9.4333 6.91285 9.58958 6.75657C9.74586 6.60029 9.83366 6.38833 9.83366 6.16732ZM9.00033 14.2923C9.16609 14.2923 9.32506 14.2265 9.44227 14.1093C9.55948 13.992 9.62533 13.8331 9.62533 13.6673V8.66732C9.62533 8.50156 9.55948 8.34259 9.44227 8.22538C9.32506 8.10817 9.16609 8.04232 9.00033 8.04232C8.83457 8.04232 8.67559 8.10817 8.55838 8.22538C8.44117 8.34259 8.37533 8.50156 8.37533 8.66732V13.6673C8.37533 14.0123 8.65533 14.2923 9.00033 14.2923Z" fill="#ed7627" />
			<path fillRule="evenodd" clipRule="evenodd" d="M9.04783 0.541992H8.95283C7.02866 0.541992 5.52116 0.541992 4.34449 0.700326C3.14116 0.861992 2.19116 1.20033 1.44533 1.94533C0.699492 2.69116 0.361992 3.64116 0.200326 4.84533C0.0419922 6.02116 0.0419922 7.52866 0.0419922 9.45283V9.54783C0.0419922 11.472 0.0419922 12.9795 0.200326 14.1562C0.361992 15.3595 0.700326 16.3095 1.44533 17.0553C2.19116 17.8012 3.14116 18.1387 4.34533 18.3003C5.52116 18.4587 7.02866 18.4587 8.95283 18.4587H9.04783C10.972 18.4587 12.4795 18.4587 13.6562 18.3003C14.8595 18.1387 15.8095 17.8003 16.5553 17.0553C17.3012 16.3095 17.6387 15.3595 17.8003 14.1553C17.9587 12.9795 17.9587 11.472 17.9587 9.54783V9.45283C17.9587 7.52866 17.9587 6.02116 17.8003 4.84449C17.6387 3.64116 17.3003 2.69116 16.5553 1.94533C15.8095 1.19949 14.8595 0.861992 13.6553 0.700326C12.4795 0.541992 10.972 0.541992 9.04783 0.541992ZM2.32949 2.82949C2.80449 2.35449 3.44616 2.08199 4.51199 1.93866C5.59532 1.79366 7.01866 1.79199 9.00033 1.79199C10.982 1.79199 12.4053 1.79366 13.4887 1.93866C14.5545 2.08199 15.197 2.35533 15.672 2.82949C16.1462 3.30449 16.4187 3.94616 16.562 5.01199C16.707 6.09532 16.7087 7.51866 16.7087 9.50033C16.7087 11.482 16.707 12.9053 16.562 13.9887C16.4187 15.0545 16.1453 15.697 15.6712 16.172C15.1962 16.6462 14.5545 16.9187 13.4887 17.062C12.4053 17.207 10.982 17.2087 9.00033 17.2087C7.01866 17.2087 5.59532 17.207 4.51199 17.062C3.44616 16.9187 2.80366 16.6453 2.32866 16.1712C1.85449 15.6962 1.58199 15.0545 1.43866 13.9887C1.29366 12.9053 1.29199 11.482 1.29199 9.50033C1.29199 7.51866 1.29366 6.09532 1.43866 5.01199C1.58199 3.94616 1.85533 3.30449 2.32949 2.82949Z" fill="#ed7627" />
		</svg>

	)
}

export default NoteIcon