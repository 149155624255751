import React from 'react'

const InvestmentIcon = () => {
	return (
		<svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M13.5715 1H19.2858V6.71429" className='stroke-current' strokeWidth="1.42857" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M19.2858 1L11.2144 9.07143C11.0808 9.2023 10.9013 9.27561 10.7144 9.27561C10.5274 9.27561 10.3479 9.2023 10.2144 9.07143L6.92864 5.78571C6.79512 5.65484 6.61561 5.58153 6.42864 5.58153C6.24168 5.58153 6.06216 5.65484 5.92864 5.78571L0.714355 11" className='stroke-current' strokeWidth="1.42857" strokeLinecap="round" strokeLinejoin="round" />
		</svg>


	)
}

export default InvestmentIcon