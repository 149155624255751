import * as Yup from "yup"
import { states } from "./base"

export const individualRegInputs = [
  {
    name: "firstname",
    label: "Firstname *",
    placeholder: "enter your firstname",
  },
  { name: "lastname", label: "Surname *", placeholder: "enter your surname" },
  {
    name: "middlename",
    label: "Middlename",
    placeholder: "enter your middle name-optional",
  },
  {
    name: "email",
    label: "Email *",
    type: "email",
    placeholder: "email@email.com",
  },
  {
    name: "ref_code",
    label: "Referral Code",
    type: "text",
    placeholder: "referral code",
  },
  {
    name: "password",
    label: "Password *",
    type: "password",
    placeholder: "enter password",
  },
  {
    name: "confirm_password",
    label: "Confirm Password *",
    type: "password",
    placeholder: "confirm password",
  },
]

export const individualRegSchema = Yup.object({
  firstname: Yup.string().required("Firstname is required"),
  lastname: Yup.string().required("Lastname is required"),
  middlename: Yup.string().label("Middlename"),
  ref_code: Yup.string().notRequired().label("Referral Code is requ"),
  email: Yup.string().email().required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .matches(/[a-zA-Z]/, "Password must contain at least one alphabet")
    .matches(/[0-9]/, "Password must contain at least one digit")
    .min(8)
    .label("Password"),
  confirm_password: Yup.string()
    .label("Confirm Password")
    .required()
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
})

export const corporateRegInputs = [
  {
    name: "email",
    label: "Email",
    type: "email",
    placeholder: "email@email.com",
  },
  {
    name: "business_name",
    label: "Business name",
    placeholder: "enter your business name",
  },
  {
    name: "rc_number",
    label: "RC Number",
    placeholder: "enter your rc number",
  },
  {
    name: "registration_date",
    label: "Business Registration date",
    type: "date",
  },
  {
    name: "ref_code",
    label: "Referral Code",
    type: "text",
    placeholder: "referral code",
  },
  {
    name: "password",
    label: "Password",
    type: "password",
    placeholder: "enter password",
  },
  {
    name: "confirm_password",
    label: "Confirm Password",
    type: "password",
    placeholder: "confirm password",
  },
]

export const corporateRegSchema = Yup.object({
  email: Yup.string().email().required("Email is required"),
  business_name: Yup.string().required("Business Name is required"),
  ref_code: Yup.string().notRequired().label("Referral Code is requ"),
  rc_number: Yup.string()
    .required("RC Number is required")
    .length(7, "RC Number must be exactly 7 digits")
    .test("digits", "RC Number can only accept digits", (val) =>
      /^\d+$/.test(val.toString())
    ),
  registration_date: Yup.string().required("Registration date is required"),
  password: Yup.string()
    .required("Password is required")
    .matches(/[a-zA-Z]/, "Password must contain at least one alphabet")
    .matches(/[0-9]/, "Password must contain at least one digit")
    .min(8)
    .label("Password"),
  confirm_password: Yup.string()
    .label("Confirm Password")
    .required()
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
})

export const individualPersonalInfoInputs = [
  { name: "firstname", label: "Firstname", disableUpdate: true },
  { name: "lastname", label: "Surname", disableUpdate: true },
  {
    name: "middlename",
    label: "Middlename",
    optional: true,
    disableUpdate: true,
  },
  { name: "phone", label: "Phone Number", disableUpdate: true },
  {
    name: "email",
    label: "Email",
    type: "email",
    class: "w-full",
    disableUpdate: true,
    rigid: true,
  },
  {
    name: "birth_date",
    label: "Date of Birth",
    type: "date",
    disableUpdate: true,
  },
  { name: "birth_place", label: "Birth Place" },
  {
    name: "gender",
    label: "Gender",
    type: "select",
    options: [
      { value: "Male", text: "Male" },
      { value: "Female", text: "Female" },
    ],
  },
  {
    name: "marital_status",
    label: "Marital Status",
    type: "select",
    options: [
      { value: "Single", text: "Single" },
      { value: "Married", text: "Married" },
      { value: "Divorced", text: "Divorced" },
    ],
  },
  {
    name: "nationality",
    label: "Nationality",
    defaultV: "Nigeria",
    disabled: true,
    disableUpdate: true,
  },
  {
    name: "nin",
    label: "NIN",
    disableUpdate: true,
  },
  {
    name: "state",
    label: "State",
    type: "select",
    disableUpdate: true,
    options: states.map((each) => ({
      value: each.state.name,
      text: each.state.name,
    })),
  },
  { name: "city", label: "City", disableUpdate: true },
  {
    name: "address",
    label: "Address",
    class: "w-full",
    disableUpdate: true,
  },
]

export const individualPersonalInfoSchema = Yup.object({
  firstname: Yup.string().required("Firstname is required"),
  lastname: Yup.string().required("Lastname is required"),
  // middlename: Yup.string().label("Middlename"),
  nin: Yup.string()
    .required("NIN is required")
    .length(11, "NIN must be exactly 11 digits")
    .test("digits", "NIN cannot contain characters other than digits", (val) =>
      /^\d+$/.test(val.toString())
    ),
  address: Yup.string().required("Address is required"),
  phone: Yup.string()
    .required("Phone is required")
    .length(11, "Phone Number must be exactly 11 digits")
    .test("digits", "Phone Number can only accept digits", (val) =>
      /^\d+$/.test(val.toString())
    ),
  city: Yup.string().required("City is required"),
  birth_date: Yup.string().required("Date of birth is required"),
  state: Yup.string().required("State is required"),
  gender: Yup.string().required("Gender is required"),
  nationality: Yup.string().required("nationality is required"),
  marital_status: Yup.string().required("Marital status is required"),
  birth_place: Yup.string().required("Birth place is required"),
  email: Yup.string().email().required("Email is required"),
})

export const individualContactInfoInputs = [
  { name: "occupation", label: "Occupation", disableUpdate: true },
  {
    name: "annual_income",
    label: "Annual Income",
    type: "select",
    options: [
      { value: "Below 1m", text: "Below ₦1m" },
      { value: "1m -5m", text: "₦1m to ₦5m" },
      { value: "5m - 10m", text: "₦5m to ₦10m" },
      { value: "10m - 25m", text: "₦10m to ₦25m" },
      { value: "Above 25m", text: "₦25m and Above" },
    ],
    disableUpdate: true,
  },
  {
    name: "business_nature",
    label: "Nature Of Business",
    type: "select",
    options: [
      { value: "Employed", text: "Employed" },
      { value: "Business Owner", text: "Business Owner" },
      { value: "Unemployed", text: "Unemployed" },
      { value: "Retired", text: "Retired" },
    ],
    class: "w-full",
    disableUpdate: true,
  },
  { name: "next_kin", label: "Next of Kin Name" },
  { name: "kin_relationship", label: "Next of Kin Relationship" },
  { name: "kin_phone", label: "Next of Kin Phone Number", class: "w-full" },
  { name: "kin_address", label: "Next of Kin Address", class: "w-full" },
]
export const individualContactInfoSchema = Yup.object({
  business_nature: Yup.string().required("Business nature is required"),
  occupation: Yup.string().required("Occupation is required"),
  annual_income: Yup.string().required("Annual income is required"),
  next_kin: Yup.string().required("Next of kin is required"),
  kin_relationship: Yup.string().required("This field is required"),
  kin_phone: Yup.string()
    .required("Kin Phone Number is required")
    .length(11, "Kin Phone Number must be exactly 11 digits")
    .test("digits", "Phone Number can only accept digits", (val) =>
      /^\d+$/.test(val.toString())
    ),
  kin_address: Yup.string().required("Next of kin Adress is required"),
  // password: Yup.string().required("password is required"),
  // confirm_password: Yup.string().label('Confirm Password').required().oneOf([Yup.ref('password'), null], 'Passwords must match'),
})

export const individualAccountInfoInputs = [
  {
    name: "bank_code",
    label: "Bank Name",
    type: "select",
    options: [],
    class: "w-full",
    disableUpdate: true,
  },
  { name: "account_number", label: "Account Number", disableUpdate: true },
  {
    name: "account_name",
    label: "Account Name",
    disabled: true,
    disableUpdate: true,
  },
  { name: "bvn", label: "BVN", class: "w-8/12 lg:w-9/12", disableUpdate: true },
]

export const individualAccountInfoSchema = Yup.object({
  bank_code: Yup.string().required("Bank name is required"),
  bvn: Yup.string()
    .required("BVN is required")
    .length(11, "BVN must be exactly 11 digits")
    .test("digits", "BVN cannot contain characters other than digits", (val) =>
      /^\d+$/.test(val.toString())
    ),
  // account_name: Yup.string().optional("Account Name is required"),
  account_number: Yup.string()
    .required("Account Number is required")
    .length(10, "Account Number must exactly be 10 digits")
    .test("digits", "Account Number can only accpets digits", (val) =>
      /^\d+$/.test(val.toString())
    ),
})

export const corporatePersonalInfoInputs = [
  {
    name: "business_name",
    label: "Business Name",
    class: "w-full",
    disableUpdate: true,
  },
  { name: "rc_number", label: "RC Number", disableUpdate: true },
  {
    name: "registration_date",
    label: "Registration Date",
    type: "date",
    disableUpdate: true,
  },
  { name: "industry", label: "Industry", disableUpdate: true },
  { name: "nin", label: "NIN", disableUpdate: true },
  { name: "employee_size", label: "Emplyee Size" },
  {
    name: "annual_revenue",
    label: "Annual Revenue",
    type: "select",
    options: [
      { value: "Below 1m", text: "Below ₦1m" },
      { value: "1m -5m", text: "₦1m to ₦5m" },
      { value: "5m - 10m", text: "₦5m to ₦10m" },
      { value: "10m - 25m", text: "₦10m to ₦25m" },
      { value: "Above 25m", text: "₦25m and Above" },
    ],
    disableUpdate: true,
  },
  // {
  // 	name: 'business_nature', label: "Nature Of Business", type: "select", options: [
  // 		{value: "Employed", text: "Employed"},
  // 		{value: "Business Owner", text: "Business Owner"},
  // 		{value: "Unemployed", text: "Unemployed"},
  // 		{value: "Retired", text: "Retired"},
  // 	], disableUpdate: true
  // },
]

export const corporatePersonalInfoSchema = Yup.object({
  business_name: Yup.string().required("Business name is required"),
  registration_date: Yup.string().required("Registration date is required"),
  rc_number: Yup.string()
    .required("RC Number is required")
    .length(7, "RC Number must be exactly 7 digits")
    .test("digits", "RC Number can only accept digits", (val) =>
      /^\d+$/.test(val.toString())
    ),
  nin: Yup.string()
    .required("NIN is required")
    .length(11, "NIN must be exactly 11 digits")
    .test("digits", "NIN cannot contain characters other than digits", (val) =>
      /^\d+$/.test(val.toString())
    ),
  // business_nature: Yup.string().required("Business nature is required"),
  industry: Yup.string().required("Industry is required"),
  employee_size: Yup.string().required("Employee size is required"),
  annual_revenue: Yup.string().required("Annual revenue is required"),
})

export const corporateContactInfoInputs = [
  { name: "email", label: "Email", type: "email", disableUpdate: true },
  { name: "phone", label: "Phone Number", disableUpdate: true },
  { name: "contact_person", label: "Contact" },
  {
    name: "nationality",
    label: "Nationality",
    defaultV: "Nigeria",
    disabled: true,
    disableUpdate: true,
  },
  { name: "state", label: "State", disableUpdate: true },
  { name: "city", label: "City", disableUpdate: true },
  { name: "address", label: "Address", class: "w-full", disableUpdate: true },
  // {name: 'password', label: "Password", type: "password"},
  // {name: 'confirm_password', label: "Confirm Password", type: "password"},
]

export const corporateContactInfoSchema = Yup.object({
  contact_person: Yup.string().required("Contact Person is required"),
  address: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  // password: Yup.string().required("Password is required"),
  email: Yup.string()
    .email("Must be a valid email")
    .required("Email is required"),
  phone: Yup.string()
    .required("Phone is required")
    .length(11, "Phone Number must be exactly 11 digits")
    .test("digits", "Phone Number can only accept digits", (val) =>
      /^\d+$/.test(val.toString())
    ),
  nationality: Yup.string().required("Nationality is required"),
  // confirm_password: Yup.string().label('Confirm Password').required().oneOf([Yup.ref('password'), null], 'Passwords must match'),
})

export const corporateAccountInfoInputs = [
  {
    name: "bank_name",
    label: "Bank Name",
    type: "select",
    options: [],
    class: "w-full",
    disableUpdate: true,
  },
  {
    name: "account_name",
    label: "Account Name",
    disabled: true,
    disableUpdate: true,
  },
  { name: "account_number", label: "Account Number", disableUpdate: true },
  { name: "bvn", label: "BVN", class: "w-8/12 lg:w-9/12", disableUpdate: true },
]

export const corporateAccountInfoSchema = Yup.object({
  bank_name: Yup.string().required("Bank name is required"),
  bvn: Yup.string()
    .required("BVN is required")
    .length(11, "BVN must be exactly 11 digits")
    .test("digits", "BVN cannot contain characters other than digits", (val) =>
      /^\d+$/.test(val.toString())
    ),
  // account_name: Yup.string().optional("Account Name is required"),
  account_number: Yup.string()
    .required("Account Number is required")
    .length(10, "Account Number must exactly be 10 digits")
    .test("digits", "Account Number can only accpets digits", (val) =>
      /^\d+$/.test(val.toString())
    ),
})

export const getDefault = (inputArr, data = null) => {
  let obj = {}
  inputArr.forEach(({ name, defaultV }) => {
    if (data) {
      obj = { ...obj, [name]: data[name] ?? defaultV ?? "" }
    } else {
      obj = { ...obj, [name]: defaultV ?? "" }
    }
  })
  return obj
}
