import React from 'react'

const LockIcon = () => {
  return (
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.66669 12.8333C1.66669 10.4766 1.66669 9.29742 2.39919 8.56575C3.13085 7.83325 4.31002 7.83325 6.66669 7.83325H13.3334C15.69 7.83325 16.8692 7.83325 17.6009 8.56575C18.3334 9.29742 18.3334 10.4766 18.3334 12.8333C18.3334 15.1899 18.3334 16.3691 17.6009 17.1008C16.8692 17.8333 15.69 17.8333 13.3334 17.8333H6.66669C4.31002 17.8333 3.13085 17.8333 2.39919 17.1008C1.66669 16.3691 1.66669 15.1899 1.66669 12.8333Z" className='stroke-current' strokeWidth="1.76471" />
      <path d="M5 7.83329V6.16663C5 4.84054 5.52678 3.56877 6.46447 2.63109C7.40215 1.69341 8.67392 1.16663 10 1.16663C11.3261 1.16663 12.5979 1.69341 13.5355 2.63109C14.4732 3.56877 15 4.84054 15 6.16663V7.83329" className='stroke-current' strokeWidth="1.25" strokeLinecap="round" />
      <path d="M6.66669 12.8333H6.67419M9.99252 12.8333H10M13.3259 12.8333H13.3334" className='stroke-current' strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  )
}

export default LockIcon