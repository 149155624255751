import React from 'react'

const WalletBgPattern2 = () => {
  return (
    <svg className='absolute inset-0 h-full w-full rounded-xl' viewBox="0 0 420 194" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g style={{mixBlendMode: 'lighten'}} opacity="0.1">
        <path d="M96.4767 270.638C100.648 285.086 107.113 298.77 115.625 311.168C122.426 321.248 130.548 330.371 139.772 338.294C151.606 348.706 165.087 357.082 179.664 363.08C197.03 370.253 215.659 373.87 234.448 373.718C238.874 373.622 243.298 374.015 247.638 374.888C263.116 377.838 277.198 385.782 287.728 397.503C298.257 409.224 304.652 424.074 305.933 439.778H319.549C318.268 420.24 310.288 401.741 296.956 387.402C283.624 373.062 265.755 363.758 246.362 361.059C241.959 360.419 237.515 360.099 233.065 360.101C209.602 360.13 186.586 353.692 166.543 341.493C146.501 329.295 130.208 311.809 119.454 290.956C107.243 269.352 102.109 244.463 104.774 219.79C106.377 197.72 113.718 176.451 126.07 158.091C138.422 139.731 155.356 124.916 175.196 115.115C194.442 104.867 216.064 99.9114 237.852 100.754C266.686 101.461 294.464 111.746 316.807 129.986C339.149 148.226 354.785 173.384 361.249 201.493C363.448 211.091 364.59 220.901 364.653 230.747C364.728 250.716 371.824 270.024 384.698 285.29C397.572 300.556 415.404 310.808 435.075 314.253C437.841 314.253 440.606 315.104 443.372 315.317V301.594C434.273 300.961 425.402 298.462 417.31 294.254C408.635 289.832 400.959 283.677 394.758 276.17C388.693 268.764 384.074 260.284 381.142 251.171C378.938 242.153 377.831 232.903 377.844 223.62C377.844 218.939 377.844 214.152 376.674 209.578C374.836 196.604 371.258 183.938 366.036 171.92C356.569 150.47 341.953 131.689 323.485 117.243C308.563 105.764 291.543 97.3087 273.382 92.3503C265.872 90.0487 258.13 88.5859 250.298 87.9888C243.66 87.2243 236.968 87.0464 230.299 87.457C224.236 88.2016 218.066 88.308 212.002 89.2654C192.468 92.2494 173.793 99.3568 157.218 110.115C144.281 118.322 132.751 128.559 123.071 140.433C112.8 152.927 104.774 167.108 99.3489 182.345C95.3031 193.798 92.6251 205.688 91.3706 217.769C90.6088 227.986 90.9296 238.256 92.328 248.405C93.171 255.907 94.5575 263.337 96.4767 270.638Z" fill="#F1A869" />
        <path d="M211.151 349.039C219.031 350.618 227.049 351.402 235.086 351.379C256.869 351.48 277.941 359.139 294.704 373.05C311.467 386.961 322.881 406.26 326.995 427.651C327.812 431.684 328.31 435.774 328.485 439.885H341.995C341.167 426.666 337.998 413.699 332.633 401.589C328.02 391.6 322.002 382.322 314.762 374.037C305.946 364.067 295.338 355.84 283.487 349.783C273.905 344.547 263.514 340.951 252.744 339.146C244.97 337.905 237.108 337.3 229.235 337.337C218.047 337.064 206.994 334.832 196.577 330.742C184.219 326.123 172.795 319.312 162.856 310.636C153.959 302.936 146.503 293.715 140.836 283.404C134.699 272.761 130.452 261.136 128.283 249.044C127.503 245.116 127.04 241.132 126.9 237.13C126.583 228.164 127.117 219.188 128.496 210.323C131.759 193.185 139.051 177.068 149.771 163.304C161.008 149.09 175.641 137.933 192.322 130.859C202.129 126.802 212.495 124.256 223.065 123.306C227.615 122.724 232.204 122.511 236.788 122.668C249.917 123.166 262.851 126.012 274.977 131.072C283.335 134.511 291.233 138.978 298.486 144.369C305.899 149.805 312.585 156.168 318.379 163.304C326.607 173.405 332.884 184.948 336.889 197.345C339.237 204.828 340.84 212.524 341.676 220.322C342.314 226.599 341.676 232.875 342.42 239.151C343.343 249.932 345.889 260.512 349.973 270.532C354.668 282.189 361.284 292.976 369.546 302.445C380.934 315.381 395.327 325.317 411.459 331.38C421.653 335.378 432.434 337.678 443.372 338.188V324.891C422.229 323.434 402.194 314.894 386.503 300.65C370.811 286.406 360.377 267.288 356.887 246.385C355.859 239.876 355.361 233.294 355.398 226.705C354.634 203.943 347.464 181.859 334.713 162.989C321.961 144.119 304.146 129.229 283.312 120.029C262.479 110.829 239.473 107.692 216.937 110.979C194.401 114.266 173.25 123.844 155.912 138.611C138.575 153.379 125.755 172.737 118.924 194.463C112.094 216.189 111.531 239.402 117.3 261.433C123.069 283.465 134.936 303.422 151.537 319.013C168.138 334.603 188.801 345.195 211.151 349.571V349.039Z" fill="#E17A20" />
        <path d="M140.836 283.829C146.504 294.14 153.96 303.361 162.856 311.062C172.796 319.737 184.22 326.548 196.578 331.167C206.995 335.257 218.048 337.489 229.236 337.762C237.108 337.725 244.971 338.33 252.745 339.571C263.514 341.377 273.905 344.972 283.488 350.208C295.338 356.265 305.947 364.492 314.763 374.462C322.003 382.747 328.021 392.025 332.634 402.014C337.998 414.124 341.168 427.091 341.995 440.31H355.399C355.321 431.62 353.996 422.986 351.463 414.673C345.737 392.731 333.899 372.866 317.328 357.387C300.757 341.907 280.131 331.45 257.851 327.231C250.112 325.682 242.234 324.934 234.342 324.997C210.741 324.975 188.008 316.1 170.635 300.126C153.262 284.152 142.515 262.241 140.517 238.725C137.447 214.537 143.869 190.101 158.436 170.548C173.002 150.996 194.579 137.849 218.634 133.87C242.69 129.89 267.351 135.388 287.438 149.208C307.525 163.028 321.475 184.094 326.358 207.982C328.128 215.371 329.056 222.937 329.124 230.534C329.144 258.963 339.177 286.477 357.463 308.245C375.749 330.013 401.119 344.644 429.118 349.57C433.977 350.406 438.877 350.974 443.798 351.272V337.656C432.86 337.145 422.079 334.846 411.885 330.848C395.753 324.785 381.36 314.848 369.972 301.913C361.71 292.444 355.094 281.657 350.399 270C346.316 259.98 343.769 249.4 342.846 238.619C342.208 232.343 342.846 226.066 342.102 219.79C341.266 211.992 339.663 204.296 337.315 196.813C333.31 184.416 327.033 172.872 318.805 162.772C313.24 155.877 306.845 149.695 299.764 144.369C292.36 139.058 284.319 134.698 275.829 131.391C263.703 126.331 250.769 123.485 237.639 122.987C233.055 122.83 228.466 123.043 223.917 123.625C213.346 124.575 202.981 127.121 193.174 131.178C176.493 138.252 161.86 149.409 150.623 163.623C139.903 177.387 132.61 193.503 129.348 210.642C127.969 219.507 127.435 228.483 127.752 237.449C127.892 241.451 128.354 245.435 129.135 249.363C131.026 261.439 134.985 273.098 140.836 283.829Z" fill="#F1A869" />
        <path d="M219.981 314.36C224.652 315.172 229.387 315.563 234.129 315.53C263.273 315.473 291.592 325.204 314.546 343.162C337.5 361.12 353.759 386.266 360.718 414.567C362.752 422.87 363.998 431.347 364.441 439.885H377.631C377.134 422.476 373.306 405.324 366.356 389.356C354.431 361.759 334.252 338.533 308.593 322.87C294.398 314.197 278.76 308.144 262.425 304.999C253.261 303.348 243.972 302.494 234.661 302.446C219.388 302.348 204.522 297.517 192.11 288.616C177.286 278.008 167.145 262.073 163.814 244.151C162.798 239.432 162.299 234.617 162.324 229.79C162.435 223.785 163.292 217.818 164.877 212.025C167.729 201.26 173.063 191.313 180.452 182.981C187.842 174.65 197.081 168.166 207.428 164.049C217.946 159.671 229.399 158.024 240.724 159.262C254.293 160.452 267.226 165.551 277.956 173.942C290.895 183.665 300.127 197.513 304.125 213.195C305.532 218.398 306.247 223.763 306.252 229.152C305.989 249.033 309.903 268.748 317.741 287.021C322.75 299.058 329.526 310.28 337.846 320.317C341.543 324.955 345.522 329.36 349.761 333.508C369.051 351.869 393.001 364.598 419.012 370.314C425.015 371.599 431.089 372.523 437.203 373.08H443.372V360.102C429.939 359.821 416.671 357.08 404.226 352.017C383.174 344.34 364.497 331.287 350.053 314.155C335.608 297.024 325.899 276.41 321.89 254.363C320.334 246.626 319.62 238.744 319.762 230.854C319.823 214.637 315.249 198.74 306.579 185.035C297.908 171.33 285.502 160.388 270.822 153.497C256.141 146.606 239.797 144.053 223.715 146.139C207.632 148.225 192.481 154.863 180.044 165.271C167.607 175.679 158.403 189.424 153.516 204.888C148.629 220.351 148.262 236.889 152.458 252.554C156.654 268.219 165.239 282.359 177.202 293.309C189.164 304.258 204.006 311.562 219.981 314.36Z" fill="#E17A20" />
        <path d="M192.216 288.617C204.628 297.517 219.494 302.348 234.767 302.446C244.078 302.494 253.368 303.348 262.531 304.999C278.867 308.144 294.504 314.197 308.699 322.87C334.358 338.534 354.537 361.759 366.462 389.356C373.412 405.324 377.24 422.476 377.738 439.885H391.354C390.295 414.092 382.995 388.942 370.079 366.591C371.142 365.634 371.887 366.591 372.632 366.591C391.536 377.194 412.438 383.75 434.011 385.845C436.99 385.845 440.075 386.484 443.053 386.59V373.08H436.883C430.77 372.523 424.696 371.599 418.693 370.314C392.795 364.544 368.962 351.818 349.761 333.508C345.512 329.257 341.533 324.745 337.846 319.998C329.526 309.961 322.75 298.739 317.741 286.702C309.903 268.429 305.989 248.714 306.252 228.833C306.247 223.444 305.532 218.079 304.125 212.876C300.061 197.31 290.835 183.584 277.956 173.942C267.375 165.646 254.64 160.552 241.256 159.262C229.931 158.025 218.478 159.671 207.96 164.049C197.613 168.166 188.374 174.65 180.984 182.981C173.595 191.313 168.261 201.26 165.409 212.025C163.824 217.818 162.966 223.785 162.856 229.79C162.831 234.617 163.33 239.432 164.345 244.151C167.57 262.006 177.552 277.932 192.216 288.617ZM176.153 227.131C176.52 211.614 183.036 196.878 194.268 186.165C205.499 175.452 220.526 169.639 236.044 170.006C251.561 170.373 266.297 176.889 277.009 188.121C287.722 199.352 293.535 214.379 293.168 229.897C293.198 257.355 300.267 284.347 313.699 308.296C314.142 309.122 314.533 309.975 314.869 310.85C310.082 308.403 306.252 306.275 302.21 304.467C281.322 294.464 258.459 289.266 235.299 289.255C227.239 289.39 219.239 287.852 211.804 284.738C204.368 281.625 197.66 277.003 192.101 271.164C186.543 265.326 182.256 258.399 179.511 250.82C176.766 243.24 175.623 235.174 176.153 227.131Z" fill="#F1A869" />
        <path d="M233.065 360.208C237.514 360.205 241.958 360.525 246.362 361.165C265.755 363.864 283.624 373.168 296.956 387.508C310.288 401.848 318.268 420.346 319.549 439.884H328.485C328.31 435.774 327.812 431.683 326.996 427.651C322.881 406.26 311.467 386.961 294.704 373.05C277.941 359.139 256.869 351.479 235.086 351.378C211.382 351.446 188.183 344.534 168.38 331.505C148.578 318.477 133.049 299.907 123.729 278.112C114.409 256.317 111.711 232.261 115.971 208.943C120.231 185.625 131.26 164.076 147.684 146.984C164.108 129.893 185.2 118.013 208.33 112.828C231.459 107.642 255.604 109.379 277.753 117.823C299.902 126.267 319.076 141.044 332.883 160.312C346.691 179.579 354.521 202.485 355.398 226.173C355.361 232.762 355.859 239.344 356.888 245.852C360.378 266.756 370.811 285.873 386.503 300.117C402.195 314.362 422.229 322.902 443.372 324.359V315.423C440.606 315.423 437.84 314.785 435.075 314.359C415.404 310.914 397.572 300.662 384.698 285.396C371.824 270.13 364.728 250.823 364.653 230.853C364.59 221.007 363.448 211.197 361.249 201.599C354.785 173.49 339.149 148.332 316.807 130.092C294.464 111.852 266.686 101.568 237.852 100.86C216.063 100.018 194.442 104.973 175.196 115.221C155.356 125.023 138.422 139.837 126.07 158.197C113.718 176.557 106.377 197.826 104.774 219.896C102.131 244.535 107.264 269.382 119.454 290.956C130.193 311.829 146.48 329.337 166.523 341.554C186.567 353.772 209.591 360.226 233.065 360.208Z" fill="#E17A20" />
        <path d="M169.345 298.509C186.779 315.345 210.105 324.699 234.341 324.572C242.234 324.508 250.111 325.257 257.851 326.806C280.131 331.025 300.756 341.482 317.328 356.961C333.899 372.441 345.736 392.306 351.462 414.248C353.995 422.561 355.321 431.194 355.398 439.884H364.547C364.104 431.347 362.858 422.87 360.824 414.567C353.865 386.265 337.606 361.12 314.652 343.162C291.698 325.204 263.379 315.473 234.235 315.53C229.458 315.569 224.687 315.178 219.98 314.359C203.997 311.581 189.141 304.292 177.163 293.352C165.185 282.412 156.583 268.275 152.371 252.609C148.159 236.943 148.513 220.399 153.391 204.927C158.269 189.455 167.468 175.699 179.903 165.281C192.338 154.863 207.492 148.216 223.58 146.124C239.667 144.032 256.017 146.582 270.703 153.473C285.39 160.363 297.801 171.309 306.474 185.018C315.147 198.728 319.72 214.631 319.656 230.854C319.514 238.744 320.227 246.626 321.783 254.363C325.792 276.41 335.502 297.023 349.946 314.155C364.39 331.287 383.067 344.34 404.119 352.017C416.565 357.08 429.833 359.82 443.266 360.102V351.272C438.345 350.974 433.444 350.406 428.586 349.57C400.586 344.645 375.217 330.013 356.931 308.245C338.645 286.477 328.611 258.964 328.591 230.534C328.698 222.957 327.949 215.392 326.357 207.982C320.653 185.008 306.512 165.022 286.747 151.997C266.981 138.971 243.037 133.86 219.676 137.68C196.315 141.5 175.246 153.971 160.658 172.614C146.071 191.256 139.032 214.705 140.942 238.3C142.594 261.203 152.72 282.669 169.345 298.509Z" fill="#E17A20" />
        <path d="M443.372 145.326C429.378 144.979 415.567 142.058 402.63 136.71C381.457 128.882 362.775 115.512 348.535 97.9974C334.295 80.4826 325.019 59.4653 321.677 37.1409C315.857 3.92616 323.26 -30.2475 342.303 -58.0764C361.346 -85.9054 390.519 -105.182 423.586 -111.787C430.137 -112.757 436.75 -113.254 443.372 -113.276V-126.999C435.082 -127.039 426.822 -126.002 418.799 -123.914C399.589 -119.521 381.486 -111.229 365.611 -99.5536C354.929 -91.8401 345.308 -82.7556 336.995 -72.5338C325.973 -58.4096 317.676 -42.3569 312.528 -25.1961C309.173 -14.2775 307.172 -2.98773 306.571 8.41912C305.995 15.4992 305.995 22.6144 306.571 29.6945C307.422 36.2899 308.486 42.8853 309.975 49.3743C312.916 61.7675 317.571 73.6902 323.805 84.7979C332.023 99.6147 342.831 112.837 355.718 123.838C366.143 132.839 377.736 140.389 390.184 146.284C406.944 153.856 424.998 158.152 443.372 158.943V145.326Z" fill="#F1A869" />
        <path d="M443.372 122.881C422.159 121.907 401.748 114.485 384.865 101.606C364.165 86.1499 349.796 63.6899 344.442 38.4176C341.947 27.6842 341.3 16.6043 342.527 5.65347C344.313 -13.9895 351.696 -32.7077 363.802 -48.2797C373.407 -61.1718 385.809 -71.7191 400.077 -79.129C411.036 -84.7348 422.812 -88.5762 434.968 -90.5114C437.841 -90.5114 440.713 -91.4688 443.691 -91.6815V-104.766C434.141 -104.522 424.678 -102.873 415.608 -99.8725C393.842 -94.0126 374.231 -81.9906 359.134 -65.2517C344.037 -48.5129 334.096 -27.7698 330.506 -5.51615C324.908 24.9706 331.314 56.4413 348.384 82.314C365.454 108.187 391.866 126.458 422.097 133.306C429.092 134.937 436.207 136.004 443.372 136.497V122.881Z" fill="#E17A20" />
        <path d="M443.372 110.115C436.443 109.669 429.589 108.42 422.948 106.392C405.848 101.632 390.505 91.995 378.792 78.6579C367.08 65.3208 359.505 48.8612 356.994 31.2899C354.947 19.1136 355.337 6.65202 358.141 -5.37245C360.946 -17.3969 366.11 -28.745 373.333 -38.7588C380.557 -48.7727 389.696 -57.2531 400.221 -63.7083C410.747 -70.1634 422.449 -74.465 434.649 -76.3637C437.527 -76.9337 440.442 -77.2892 443.372 -77.4274V-92.0011C440.394 -92.0011 437.522 -91.2565 434.649 -90.8309C422.492 -88.8957 410.717 -85.0544 399.758 -79.4486C385.49 -72.0387 373.088 -61.4913 363.483 -48.5992C351.377 -33.0273 343.993 -14.309 342.208 5.33392C340.981 16.2848 341.628 27.3647 344.122 38.0981C349.477 63.3703 363.846 85.8304 384.546 101.286C401.429 114.165 421.84 121.588 443.053 122.561L443.372 110.115Z" fill="#F1A869" />
        <path d="M443.373 136.497C436.207 136.003 429.092 134.936 422.097 133.305C391.945 126.364 365.635 108.055 348.652 82.1922C331.668 56.3299 325.32 24.9106 330.932 -5.51669C334.522 -27.7703 344.463 -48.5134 359.56 -65.2522C374.657 -81.9911 394.267 -94.0131 416.034 -99.8731C424.866 -102.803 434.073 -104.451 443.373 -104.766V-113.277C436.724 -112.903 430.112 -112.05 423.586 -110.724C390.519 -104.119 361.346 -84.8419 342.303 -57.013C323.26 -29.184 315.858 4.98955 321.677 38.2043C325.02 60.5287 334.295 81.5459 348.536 99.0607C362.776 116.576 381.458 129.945 402.63 137.773C415.567 143.122 429.378 146.043 443.373 146.39V136.497Z" fill="#E17A20" />
        <path d="M443.372 87.1382C436.797 86.4948 430.329 85.0296 424.118 82.7767C412.384 78.2371 402.034 70.7195 394.088 60.9641C386.141 51.2087 380.873 39.5519 378.802 27.1415C377.091 16.1925 377.95 4.99593 381.31 -5.56412C384.67 -16.1242 390.44 -25.7584 398.162 -33.7061C408.173 -44.4621 421.242 -51.888 435.607 -54.9815C438.168 -55.5349 440.762 -55.9258 443.372 -56.1517V-68.8105C438.905 -68.4409 434.465 -67.8015 430.075 -66.8958C411.153 -62.2557 394.395 -51.2633 382.602 -35.7548C370.809 -20.2463 364.695 -1.16085 365.28 18.3134C365.865 37.7877 373.115 56.4714 385.818 71.2438C398.521 86.0161 415.908 95.9825 435.075 99.4779C437.841 99.4779 440.607 100.329 443.372 100.542V87.1382Z" fill="#E17A20" />
        <path d="M443.372 73.6285C429.618 72.1018 416.858 65.7232 407.383 55.6374C397.908 45.5516 392.337 32.419 391.671 18.5964C391.005 4.7739 395.287 -8.83362 403.748 -19.7838C412.21 -30.7341 424.297 -38.3101 437.841 -41.1523C439.649 -41.1523 441.564 -41.1523 443.372 -41.7906V-55.6196C440.762 -55.3937 438.168 -55.0029 435.607 -54.4495C421.242 -51.356 408.173 -43.9301 398.162 -33.1741C390.439 -25.2264 384.67 -15.5921 381.31 -5.03202C377.95 5.52803 377.091 16.7246 378.801 27.6736C380.873 40.0839 386.141 51.7407 394.087 61.4962C402.033 71.2516 412.383 78.7692 424.118 83.3088C430.329 85.5616 436.797 87.0268 443.372 87.6703V73.6285Z" fill="#F1A869" />
        <path d="M443.372 100.648C440.607 100.648 437.841 100.01 435.075 99.5841C415.854 96.1382 398.403 86.1872 385.65 71.4005C372.896 56.6137 365.615 37.8908 365.029 18.3726C364.443 -1.14567 370.587 -20.2718 382.43 -35.7972C394.274 -51.3227 411.096 -62.3029 430.075 -66.896C434.465 -67.8018 438.905 -68.4412 443.372 -68.8108V-77.9592C440.442 -77.821 437.527 -77.4655 434.649 -76.8955C422.449 -74.9968 410.747 -70.6952 400.221 -64.24C389.696 -57.7848 380.557 -49.3045 373.333 -39.2907C366.11 -29.2768 360.946 -17.9287 358.141 -5.90427C355.337 6.1202 354.947 18.5818 356.994 30.7581C359.506 48.3295 367.08 64.789 378.793 78.1261C390.505 91.4631 405.848 101.1 422.948 105.86C429.567 108.068 436.422 109.496 443.372 110.115V100.648Z" fill="#E17A20" />
        <path d="M443.372 265.745C435.912 264.375 429.088 260.646 423.905 255.107C418.999 249.889 415.743 243.34 414.544 236.279C413.987 232.762 413.773 229.199 413.906 225.641C413.619 214.925 412.445 204.25 410.395 193.728C409.863 190.43 410.395 190.217 413.587 190.856C421.789 192.559 430.112 193.626 438.479 194.047H443.372V181.282C425.188 180.602 407.23 177.01 390.184 170.644C389.226 171.708 390.184 172.559 390.184 173.41C397.013 191.543 400.613 210.733 400.821 230.109C400.654 241.743 404.61 253.061 411.99 262.057C419.369 271.053 429.696 277.145 441.138 279.255H443.798L443.372 265.745Z" fill="#F1A869" />
        <path d="M443.372 279.468H440.713C429.27 277.358 418.944 271.266 411.564 262.27C404.185 253.274 400.228 241.956 400.396 230.322C400.187 210.946 396.587 191.756 389.758 173.623C389.758 172.772 388.588 171.921 389.758 170.857C406.805 177.223 424.762 180.815 442.946 181.495V172.453C418.711 171.295 395.077 164.522 373.908 152.667C372.844 152.667 371.993 150.965 370.504 151.496C370.749 152.745 371.297 153.914 372.099 154.9C384.237 176.929 390.879 201.561 391.46 226.705C391.352 232.018 391.779 237.328 392.737 242.555C395.306 254.52 401.582 265.371 410.672 273.564C419.763 281.757 431.205 286.875 443.372 288.191V279.468Z" fill="#E17A20" />
        <path d="M443.372 51.0761C436.389 49.8832 429.926 46.6181 424.823 41.7048C419.72 36.7914 416.213 30.4571 414.756 23.5245C413.424 17.8836 413.545 11.9968 415.108 6.41534C416.671 0.833878 419.624 -4.2597 423.692 -8.3886C428.627 -13.8005 435.14 -17.5221 442.308 -19.0263H443.372V-32.8553C432.922 -31.7423 423.118 -27.2583 415.441 -20.0808C407.765 -12.9033 402.633 -3.42222 400.821 6.92972C399.595 13.5362 399.741 20.324 401.247 26.872C402.754 33.42 405.59 39.5886 409.58 44.9949C413.57 50.4012 418.628 54.9298 424.441 58.3C430.254 61.6701 436.698 63.8097 443.372 64.5861V51.0761Z" fill="#E17A20" />
        <path d="M443.373 64.5862C436.698 63.8098 430.255 61.6702 424.442 58.3C418.629 54.9299 413.571 50.4013 409.581 44.995C405.591 39.5887 402.755 33.4201 401.248 26.8721C399.741 20.3241 399.596 13.5363 400.822 6.92981C402.633 -3.42213 407.765 -12.9032 415.442 -20.0807C423.118 -27.2582 432.922 -31.7423 443.373 -32.8553V-41.7909C441.564 -41.7909 439.649 -41.7909 437.841 -41.1527C424.297 -38.3104 412.21 -30.7344 403.748 -19.7842C395.287 -8.83393 391.005 4.77359 391.671 18.5961C392.337 32.4187 397.908 45.5513 407.383 55.6371C416.859 65.7229 429.618 72.1015 443.373 73.6282V64.5862Z" fill="#E17A20" />
        <path d="M443.372 251.916C439.062 250.706 435.23 248.199 432.394 244.735C429.558 241.271 427.857 237.019 427.522 232.555C427.522 224.79 427.522 216.918 426.139 209.152C426.139 207.344 426.139 206.919 428.267 207.025C433.266 207.025 438.266 207.982 443.372 208.089V195.217H438.479C430.111 194.796 421.789 193.729 413.586 192.026C410.076 191.387 409.863 192.026 410.395 194.898C412.445 205.42 413.619 216.095 413.906 226.811C413.773 230.369 413.987 233.932 414.544 237.449C415.743 244.51 418.999 251.059 423.905 256.277C429.088 261.816 435.911 265.545 443.372 266.915V251.916Z" fill="#E17A20" />
        <path d="M443.372 37.1406C439.275 35.9818 435.603 33.6543 432.807 30.4427C430.011 27.2311 428.212 23.2745 427.628 19.0565C426.87 13.7534 428.043 8.35435 430.933 3.84368C433.823 -0.66699 438.237 -3.98898 443.372 -5.51666V-19.452H442.308C435.14 -17.9479 428.627 -14.2262 423.692 -8.81432C419.624 -4.68543 416.671 0.408151 415.108 5.98961C413.545 11.5711 413.424 17.4579 414.757 23.0988C416.136 30.1094 419.61 36.5366 424.719 41.5316C429.828 46.5266 436.332 49.8546 443.372 51.0759V37.1406Z" fill="#F1A869" />
        <path d="M27.0122 159.155C-60.4297 159.155 -125.107 95.3289 -124.575 14.9079C-124.239 -13.2902 -115.609 -40.7655 -99.7606 -64.0908C-83.9121 -87.4162 -61.547 -105.559 -35.4548 -116.257C-9.36258 -126.955 19.3018 -129.734 46.963 -124.248C74.6243 -118.761 100.058 -105.252 120.092 -85.4057C133.726 -72.3272 144.536 -56.5939 151.854 -39.1763C159.172 -21.7587 162.844 -3.02629 162.643 15.8653C163.171 51.349 149.942 85.6594 125.73 111.605C113.342 125.79 98.2079 137.317 81.2408 145.49C64.2737 153.662 45.8261 158.311 27.0122 159.155ZM18.7148 145.539C44.3602 145.539 69.4306 137.94 90.7611 123.703C112.092 109.466 128.726 89.2288 138.564 65.5456C148.402 41.8624 151.003 15.7953 146.039 -9.36496C141.074 -34.5252 128.766 -57.6505 110.669 -75.8216C92.5726 -93.9926 69.4977 -106.395 44.358 -111.462C19.2183 -116.53 -6.8592 -114.035 -30.5825 -104.294C-54.3057 -94.5529 -74.6111 -78.0016 -88.9352 -56.7294C-103.259 -35.4573 -110.96 -10.4181 -111.065 15.227C-111.149 32.3186 -107.854 49.2582 -101.368 65.0716C-94.8824 80.8851 -85.3346 95.2603 -73.2736 107.371C-61.2126 119.481 -46.8765 129.088 -31.0897 135.638C-15.3029 142.188 1.62303 145.553 18.7148 145.539Z" fill="#F1A869" />
        <path d="M154.558 95.329C168.689 71.1149 176.136 43.582 176.136 15.5462C176.136 -12.4896 168.689 -40.0224 154.558 -64.2366C178.815 -50.2318 206.331 -42.8588 234.341 -42.8588C262.351 -42.8588 289.867 -50.2318 314.124 -64.2366C300.174 -40.0963 292.829 -12.7072 292.829 15.1739C292.829 43.0549 300.174 70.4441 314.124 94.5844C289.802 80.6926 262.251 73.4479 234.241 73.5786C206.232 73.7094 178.75 81.2108 154.558 95.329ZM173.706 76.3939C212.801 61.3397 256.094 61.3397 295.189 76.3939C280.032 37.428 280.032 -5.80367 295.189 -44.7696C275.839 -37.3135 255.29 -33.456 234.554 -33.3872C213.757 -33.4388 193.141 -37.2593 173.706 -44.6632C188.814 -5.72502 188.814 37.4557 173.706 76.3939Z" fill="#E17A20" />
        <path d="M98.3915 151.603C84.5579 175.628 77.2768 202.865 77.2768 230.588C77.2768 258.311 84.5579 285.548 98.3915 309.573C74.11 295.64 46.6034 288.309 18.6087 288.309C-9.38591 288.309 -36.8925 295.64 -61.174 309.573C-47.1718 285.45 -39.7969 258.054 -39.7969 230.162C-39.7969 202.27 -47.1718 174.874 -61.174 150.752C-36.974 164.834 -9.50375 172.324 18.4952 172.473C46.4942 172.623 74.0426 165.426 98.3915 151.603ZM79.8819 290.957C72.2977 271.566 68.4014 250.93 68.3932 230.109C68.4817 209.478 72.3392 189.037 79.7755 169.793C60.3926 177.262 39.8063 181.12 19.0343 181.176C-1.7684 181.078 -22.3822 177.222 -41.8134 169.793C-38.0685 179.558 -35.1854 189.631 -33.1969 199.898C-31.3225 210.037 -30.3965 220.33 -30.4311 230.641C-30.4456 240.809 -31.3712 250.955 -33.1969 260.958C-35.2955 271.096 -38.0674 281.082 -41.4943 290.85C-2.41209 275.888 40.826 275.926 79.8819 290.957Z" fill="#E17A20" />
        <path d="M314.337 310.317C309.55 307.87 305.72 305.743 301.678 303.934C280.79 293.932 257.927 288.734 234.767 288.722C223.325 288.762 212.127 285.421 202.577 279.119C193.028 272.817 185.552 263.835 181.088 253.3C176.625 242.765 175.372 231.146 177.488 219.902C179.603 208.657 184.993 198.288 192.98 190.096C200.967 181.903 211.195 176.252 222.382 173.852C233.569 171.451 245.216 172.408 255.861 176.603C266.506 180.797 275.675 188.042 282.218 197.429C288.76 206.815 292.384 217.925 292.636 229.364C292.666 256.822 299.735 283.815 313.167 307.764C313.61 308.59 314 309.442 314.337 310.317ZM294.444 290.956C294.444 289.573 294.444 288.616 293.7 287.552C287.145 269.512 283.762 250.473 283.7 231.279C283.781 221.441 280.92 211.803 275.485 203.602C270.049 195.402 262.287 189.013 253.194 185.255C244.102 181.498 234.093 180.543 224.455 182.514C214.816 184.485 205.986 189.292 199.098 196.317C192.21 203.342 187.579 212.265 185.799 221.941C184.019 231.617 185.171 241.605 189.107 250.621C193.043 259.638 199.585 267.273 207.891 272.545C216.197 277.818 225.89 280.487 235.724 280.212C254.249 280.27 272.629 283.471 290.083 289.68C291.404 290.47 292.906 290.909 294.444 290.956Z" fill="#E17A20" />
        <path d="M18.7152 145.539C-6.97936 145.412 -32.0579 137.66 -53.3425 123.266C-74.6271 108.871 -91.16 88.4829 -100.846 64.6836C-110.532 40.8842 -112.936 14.7453 -107.752 -10.4213C-102.568 -35.5879 -90.0303 -58.6493 -71.7274 -76.6835C-53.4245 -94.7177 -30.1802 -106.913 -4.93968 -111.724C20.3008 -116.535 46.4014 -113.745 70.0549 -103.708C93.7083 -93.6711 113.85 -76.8385 127.928 -55.3433C142.006 -33.8481 149.386 -8.65781 149.133 17.0359C148.769 51.336 134.85 84.0991 110.416 108.174C85.9823 132.249 53.017 145.682 18.7152 145.539ZM18.7152 -104.872C-5.16246 -104.746 -28.4676 -97.549 -48.2585 -84.1893C-68.0494 -70.8296 -83.4388 -51.9063 -92.4843 -29.8079C-101.53 -7.70948 -103.826 16.5734 -99.0825 39.9756C-94.3393 63.3778 -82.7694 84.8502 -65.8332 101.683C-48.8971 118.515 -27.3539 129.953 -3.923 134.552C19.5079 139.151 43.7761 136.706 65.8185 127.525C87.8609 118.344 106.689 102.838 119.927 82.9653C133.165 63.0927 140.218 39.7437 140.198 15.8657C140.085 -16.1766 127.285 -46.8698 104.597 -69.4974C81.9101 -92.1249 51.1833 -104.844 19.1408 -104.872H18.7152Z" fill="#E17A20" />
        <path d="M173.707 76.3944C188.815 37.4562 188.815 -5.72451 173.707 -44.6627C193.141 -37.2588 213.757 -33.4383 234.554 -33.3867C255.29 -33.4555 275.84 -37.3129 295.189 -44.769C280.033 -5.80316 280.033 37.4285 295.189 76.3944C256.094 61.3402 212.802 61.3402 173.707 76.3944ZM194.982 -23.4936V-22.2172C196.889 -13.6738 198.133 -4.996 198.705 3.73887C199.637 20.1379 198.421 36.5884 195.088 52.6723C194.45 55.7572 194.45 55.7573 197.641 55.119C210.817 52.3157 224.281 51.1014 237.745 51.5021C240.299 51.5021 242.852 51.5021 245.405 51.5021C247.958 51.5021 253.17 52.1404 257 52.6723C260.829 53.2042 267.637 54.2679 272.531 55.3317C274.233 55.3317 274.765 55.3317 274.552 53.5233C274.339 51.7149 273.914 49.0555 273.488 46.8216C271.684 37.0222 270.617 27.1013 270.297 17.1424C270.277 12.3099 270.525 7.48002 271.042 2.67511C271.042 -1.8991 271.999 -6.36693 272.637 -10.9411C273.275 -15.5154 273.807 -17.6429 274.446 -21.5788C275.084 -25.5148 274.446 -24.8766 271.361 -24.4511C270.937 -24.4994 270.508 -24.4994 270.084 -24.4511C258.614 -22.2215 246.979 -20.9406 235.299 -20.6215C231.15 -20.6215 227.001 -21.2597 222.853 -21.3661C215.938 -21.3661 209.236 -22.9617 202.428 -23.8128C199.662 -23.0681 197.322 -24.6638 194.556 -23.4936H194.982Z" fill="#F1A869" />
        <path d="M79.8819 290.956C40.8128 275.96 -2.42532 275.96 -41.4944 290.956C-38.0675 281.188 -35.2956 271.202 -33.197 261.064C-31.3713 251.061 -30.4456 240.915 -30.4312 230.747C-30.3966 220.435 -31.3225 210.143 -33.197 200.004C-35.1855 189.737 -38.0686 179.663 -41.8135 169.899C-22.3823 177.328 -1.76848 181.184 19.0342 181.282C39.8062 181.226 60.3926 177.368 79.7755 169.899C72.3391 189.143 68.4817 209.584 68.3931 230.215C68.4145 251 72.3107 271.599 79.8819 290.956ZM58.6065 269.681C58.6821 269.259 58.6821 268.826 58.6065 268.404C58.7461 267.416 58.7461 266.414 58.6065 265.426C56.3751 254.135 55.094 242.677 54.7769 231.172C54.7769 226.704 54.7769 222.343 55.5215 217.875C56.2661 213.407 56.4789 207.982 57.1172 203.089C57.1172 199.791 58.1809 196.6 58.9256 193.408C59.6702 190.217 58.9256 190.004 55.947 190.324H54.6705C44.9036 192.42 34.9733 193.666 24.9913 194.047C19.5724 194.417 14.1345 194.417 8.71559 194.047C-0.105521 193.407 -8.87991 192.235 -17.5596 190.536C-20.3254 190.536 -20.7508 190.536 -20.3253 193.196C-18.6176 201.405 -17.4802 209.722 -16.9212 218.088C-15.8485 234.483 -17.0297 250.947 -20.4317 267.021C-21.07 270.213 -20.4317 270.213 -17.9851 269.574C-7.49019 267.334 3.19807 266.123 13.928 265.958C18.9278 265.958 23.9275 265.958 28.9272 265.958C33.9184 266.132 38.8931 266.63 43.82 267.447L58.6065 269.681Z" fill="#F1A869" />
        <path d="M294.444 290.956C292.905 290.909 291.404 290.47 290.083 289.68C272.629 283.471 254.249 280.27 235.724 280.212C225.919 280.466 216.261 277.787 207.988 272.518C199.715 267.248 193.204 259.629 189.289 250.635C185.374 241.642 184.234 231.685 186.013 222.039C187.793 212.394 192.412 203.499 199.279 196.495C206.146 189.491 214.948 184.697 224.556 182.727C234.165 180.757 244.143 181.701 253.211 185.438C262.28 189.174 270.027 195.534 275.459 203.701C280.891 211.868 283.76 221.471 283.7 231.279C283.762 250.473 287.145 269.512 293.699 287.552C294.444 288.297 294.444 289.254 294.444 290.956ZM198.705 230.853C198.693 239.216 201.631 247.315 207.002 253.724C210.112 257.703 214.081 260.927 218.613 263.156C223.144 265.385 228.121 266.561 233.171 266.596C245.87 266.877 258.53 268.121 271.041 270.319C274.552 270.957 275.083 270.319 274.445 267.022C274.507 266.634 274.507 266.239 274.445 265.851C272.783 257.322 271.752 248.682 271.36 240.002C271.36 235.002 270.403 230.002 270.616 225.003C270.667 224.579 270.667 224.15 270.616 223.726C268.813 214.994 263.84 207.239 256.656 201.959C249.472 196.678 240.587 194.246 231.715 195.132C222.843 196.017 214.614 200.158 208.616 206.755C202.618 213.352 199.277 221.937 199.237 230.853H198.705Z" fill="#E17A20" />
        <path d="M19.1402 -104.872C43.0316 -104.767 66.3556 -97.5823 86.1651 -84.2259C105.975 -70.8694 121.38 -51.9407 130.436 -29.8317C139.491 -7.72269 141.79 16.5745 137.041 39.9894C132.293 63.4044 120.71 84.8863 103.757 101.721C86.8033 118.555 65.2404 129.986 41.7926 134.57C18.3448 139.154 -5.93553 136.684 -27.9803 127.474C-50.0251 118.263 -68.8449 102.724 -82.0617 82.8214C-95.2784 62.9184 -102.299 39.5444 -102.236 15.6529C-102.194 -0.234627 -99.02 -15.958 -92.895 -30.6174C-86.77 -45.2768 -77.8145 -58.5846 -66.5408 -69.7792C-55.2672 -80.9738 -41.8967 -89.8354 -27.1946 -95.8571C-12.4925 -101.879 3.2528 -104.942 19.1402 -104.872ZM19.1402 122.987C40.3552 122.924 61.0758 116.576 78.6848 104.743C96.2938 92.9109 110.001 76.1256 118.076 56.5073C126.151 36.889 128.232 15.3178 124.054 -5.48198C119.877 -26.2818 109.629 -45.3771 94.6052 -60.3562C79.5816 -75.3353 60.4559 -85.5262 39.6439 -89.6419C18.8318 -93.7577 -2.73326 -91.6136 -22.3275 -83.4804C-41.9217 -75.3472 -58.6662 -61.5898 -70.4462 -43.9457C-82.2262 -26.3016 -88.5133 -5.56228 -88.5134 15.6529C-88.6421 29.82 -85.9396 43.8701 -80.5644 56.9785C-75.1891 70.0869 -67.2496 81.9896 -57.2117 91.9878C-47.1739 101.986 -35.2399 109.879 -22.1103 115.202C-8.98069 120.525 5.08 123.172 19.2465 122.987H19.1402Z" fill="#E17A20" />
        <path d="M194.556 -23.4939C197.322 -24.6641 199.662 -23.494 202.109 -22.7493C208.917 -21.8983 215.619 -20.5154 222.533 -20.3027C226.682 -20.3027 230.831 -19.4517 234.979 -19.5581C246.66 -19.8772 258.294 -21.158 269.764 -23.3876C270.189 -23.436 270.617 -23.436 271.041 -23.3876C273.7 -23.3876 274.658 -23.3876 274.126 -20.5154C273.594 -17.6432 272.743 -13.8137 272.318 -9.8777C271.892 -5.94175 270.935 -0.835665 270.722 3.73855C270.206 8.54345 269.957 13.3733 269.977 18.2058C270.298 28.1648 271.365 38.0856 273.169 47.885C273.169 50.119 273.913 52.3528 274.232 54.5868C274.551 56.8207 274.232 56.7143 272.211 56.3952C267.105 55.3314 261.573 54.4804 256.68 53.7357C251.787 52.9911 249.021 52.8847 245.085 52.5656C241.149 52.2464 239.979 52.5656 237.426 52.5656C223.961 52.1648 210.497 53.3791 197.322 56.1824C194.13 56.8207 194.13 56.8207 194.769 53.7357C198.302 37.3197 199.625 20.5052 198.705 3.73855C198.109 -5.07049 196.828 -13.8198 194.875 -22.4302L194.556 -23.4939ZM258.382 -7.75019C252.425 -7.75019 247 -6.89919 241.575 -6.68644C232.524 -6.38519 223.463 -6.66939 214.448 -7.53739C210.832 -7.53739 210.832 -7.53745 211.257 -4.02701C212.215 4.091 212.535 12.2717 212.214 20.4397C212.214 25.865 212.215 31.0774 211.151 36.609C211.151 39.694 211.151 39.694 214.129 39.3748C227.524 37.8319 241.051 37.8319 254.446 39.3748C258.063 39.3748 258.169 39.3749 257.85 36.3963C256.895 27.4235 256.575 18.3944 256.893 9.37652C257.212 3.73854 257.744 -1.79308 258.063 -7.75019H258.382Z" fill="#E17A20" />
        <path d="M58.3938 270.319L43.1819 267.979C38.255 267.162 33.2804 266.664 28.2892 266.49C23.2895 266.49 18.2897 266.49 13.29 266.49C2.56001 266.655 -8.12825 267.867 -18.6231 270.106C-21.6017 270.745 -21.7081 270.745 -21.0698 267.553C-17.6678 251.479 -16.4867 235.015 -17.5594 218.62C-18.1183 210.254 -19.2558 201.937 -20.9634 193.728C-20.9634 190.962 -20.9634 190.536 -18.1976 191.068C-9.51798 192.767 -0.743583 193.939 8.07753 194.579C13.4964 194.949 18.9343 194.949 24.3532 194.579C34.3353 194.198 44.2655 192.952 54.0324 190.855H55.3089C57.9683 190.855 58.8194 190.855 58.2875 193.94C57.7556 197.025 57.0109 200.323 56.479 203.621C55.8408 208.514 55.309 213.514 54.8834 218.407C54.4579 223.301 54.0324 227.236 54.1388 231.704C54.456 243.209 55.7369 254.667 57.9683 265.958C58.108 266.946 58.108 267.948 57.9683 268.936C58.2018 269.364 58.3466 269.834 58.3938 270.319ZM42.8628 206.493C37.8631 206.493 33.5017 207.344 29.0338 207.557C18.7082 208.018 8.36276 207.733 -1.92194 206.706C-4.9005 206.706 -5.00687 206.706 -4.58137 209.259C-2.9325 223.181 -2.9325 237.249 -4.58137 251.171C-4.58137 253.937 -4.58132 253.937 -1.70914 253.724C3.07783 253.724 7.97112 252.873 12.8645 252.661C21.885 252.269 30.9224 252.625 39.8842 253.724C42.7564 253.724 42.8628 253.724 42.6501 251.171C42.4373 248.618 42.0118 243.193 41.799 239.151C41.2844 228.395 41.6402 217.616 42.8628 206.918V206.493Z" fill="#E17A20" />
        <path d="M198.705 230.535C198.745 221.619 202.086 213.034 208.084 206.437C214.082 199.84 222.312 195.699 231.183 194.813C240.055 193.927 248.941 196.359 256.125 201.64C263.309 206.92 268.282 214.675 270.084 223.407C270.135 223.831 270.135 224.26 270.084 224.684C270.084 229.684 270.722 234.683 270.829 239.683C271.22 248.364 272.251 257.004 273.914 265.533C273.975 265.92 273.975 266.315 273.914 266.703C274.552 270.107 273.914 270.639 270.51 270C257.999 267.803 245.339 266.558 232.639 266.277C227.589 266.242 222.613 265.066 218.081 262.838C213.55 260.609 209.581 257.384 206.471 253.406C201.291 246.923 198.543 238.831 198.705 230.535ZM258.702 254.469C257.532 245.534 257.638 237.236 257.106 229.045C256.841 224.812 255.381 220.741 252.897 217.303C250.413 213.865 247.005 211.202 243.069 209.621C239.134 208.041 234.83 207.607 230.659 208.372C226.487 209.136 222.617 211.067 219.497 213.941C216.378 216.815 214.136 220.513 213.032 224.609C211.929 228.704 212.008 233.028 213.261 237.08C214.513 241.132 216.889 244.747 220.111 247.504C223.334 250.262 227.272 252.05 231.469 252.661C239.979 253.406 248.809 253.299 258.383 254.469H258.702Z" fill="#F1A869" />
        <path d="M19.2468 122.987C-2.01346 122.944 -22.782 116.588 -40.4252 104.726C-58.0683 92.8632 -71.7911 76.0283 -79.8535 56.3561C-87.9158 36.6838 -89.9544 15.0604 -85.7106 -5.77205C-81.4669 -26.6045 -71.1321 -45.7074 -56.0168 -60.6582C-40.9016 -75.609 -21.6868 -85.7342 -0.809151 -89.7498C20.0684 -93.7653 41.6683 -91.4903 61.2512 -83.2133C80.8341 -74.9363 97.5178 -61.03 109.187 -43.2582C120.855 -25.4864 126.984 -4.64954 126.794 16.6099C126.569 44.9562 115.129 72.0601 94.9751 91.9946C74.8213 111.929 47.5938 123.072 19.2468 122.987ZM19.2468 109.796C37.8108 109.733 55.9399 104.171 71.3447 93.8111C86.7496 83.4515 98.7393 68.7596 105.8 51.5905C112.86 34.4214 114.675 15.5451 111.015 -2.65464C107.354 -20.8544 98.3832 -37.5614 85.2341 -50.6659C72.085 -63.7705 55.3477 -72.6849 37.1356 -76.2834C18.9236 -79.882 0.0535612 -78.0033 -17.0915 -70.8846C-34.2365 -63.766 -48.8877 -51.7265 -59.1949 -36.2866C-69.5022 -20.8468 -75.0032 -2.69892 -75.0033 15.8652C-74.9753 40.8165 -65.0655 64.7409 -47.4423 82.4041C-29.819 100.067 -5.91709 110.031 19.034 110.115L19.2468 109.796Z" fill="#F1A869" />
        <path d="M258.063 -7.75065C258.063 -1.79354 257.212 3.73805 257 9.26966C256.682 18.2876 257.001 27.3167 257.957 36.2894C257.957 39.6935 257.957 39.6935 254.553 39.268C241.158 37.7251 227.631 37.7251 214.236 39.268C210.938 39.268 210.832 39.268 211.257 36.5022C211.896 31.1834 212.108 25.8645 212.321 20.3329C212.642 12.1648 212.322 3.98422 211.364 -4.13379C211.364 -7.96336 211.364 -8.06976 214.555 -7.64425C223.43 -6.82994 232.349 -6.58123 241.256 -6.89962C246.681 -6.89962 251.893 -6.89964 258.063 -7.75065ZM220.618 29.5877C229.448 29.5877 237.852 29.5877 246.255 29.5877C248.277 29.5877 248.383 28.7367 248.277 27.2474C247.638 19.7081 247.638 12.1284 248.277 4.58912C248.277 2.35521 247.638 1.82326 245.617 2.03602C238.184 2.67441 230.711 2.67441 223.278 2.03602C221.257 2.03602 220.512 2.03608 220.725 4.58912C221.15 12.6738 220.938 20.8648 220.618 29.5877Z" fill="#E17A20" />
        <path d="M19.0344 110.116C0.475284 109.99 -17.6306 104.368 -32.9978 93.9614C-48.3649 83.5543 -60.3047 68.8281 -67.31 51.6414C-74.3153 34.4546 -76.0721 15.5778 -72.3587 -2.60646C-68.6453 -20.7907 -59.6282 -37.4674 -46.4454 -50.5316C-33.2627 -63.5957 -16.5052 -72.4617 1.71194 -76.0105C19.9291 -79.5594 38.7892 -77.632 55.9119 -70.4717C73.0346 -63.3113 87.6524 -51.2389 97.9201 -35.7783C108.188 -20.3176 113.645 -2.16173 113.604 16.3978C113.548 28.7609 111.058 40.992 106.275 52.3927C101.492 63.7934 94.5106 74.1405 85.729 82.843C76.9474 91.5456 66.5377 98.4333 55.0942 103.113C43.6507 107.792 31.3976 110.172 19.0344 110.116ZM19.0344 101.074C35.8659 101.074 52.3195 96.0828 66.3144 86.7317C80.3093 77.3806 91.2169 64.0895 97.6581 48.5392C104.099 32.9889 105.785 15.8779 102.501 -0.630235C99.2172 -17.1383 91.112 -32.302 79.2103 -44.2037C67.3087 -56.1053 52.145 -64.2105 35.6369 -67.4942C19.1288 -70.7778 2.01769 -69.0925 -13.5326 -62.6514C-29.0829 -56.2103 -42.3739 -45.3026 -51.725 -31.3077C-61.0761 -17.3128 -66.0672 -0.859244 -66.0672 15.9723C-65.9832 38.4321 -57.0238 59.948 -41.1422 75.8296C-25.2605 91.7113 -3.74454 100.671 18.7153 100.755L19.0344 101.074Z" fill="#E17A20" />
        <path d="M220.618 29.5882C220.618 20.8653 220.618 12.6743 220.618 4.58963C220.618 2.46209 221.15 1.82377 223.172 2.03653C230.604 2.67492 238.078 2.67492 245.511 2.03653C247.532 2.03653 248.383 2.03658 248.17 4.58963C247.532 12.1289 247.532 19.7086 248.17 27.2479C248.17 28.7372 248.17 29.5882 246.149 29.5882C237.852 29.1627 229.448 29.2691 220.618 29.5882Z" fill="#F1A869" />
        <path d="M18.7151 100.754C1.8836 100.754 -14.57 95.7631 -28.5649 86.412C-42.5597 77.0609 -53.4674 63.7698 -59.9086 48.2195C-66.3497 32.6692 -68.035 15.5582 -64.7513 -0.949922C-61.4677 -17.458 -53.3625 -32.6217 -41.4608 -44.5234C-29.5591 -56.4251 -14.3955 -64.5302 2.11261 -67.8139C18.6207 -71.0975 35.7318 -69.4122 51.282 -62.9711C66.8323 -56.5299 80.1234 -45.6223 89.4745 -31.6274C98.8256 -17.6325 103.817 -1.17893 103.817 15.6526C103.817 38.2229 94.8507 59.8689 78.891 75.8285C62.9314 91.7882 41.2855 100.754 18.7151 100.754ZM90.7323 15.6526C90.7323 -3.39115 83.1673 -21.6549 69.7013 -35.1209C56.2353 -48.5868 37.9716 -56.1519 18.9278 -56.1519C-0.115904 -56.1519 -18.3797 -48.5868 -31.8456 -35.1209C-45.3116 -21.6549 -52.8767 -3.39115 -52.8767 15.6526C-52.8767 34.6963 -45.3116 52.96 -31.8456 66.426C-18.3797 79.8919 -0.115904 87.4571 18.9278 87.4571C37.9716 87.4571 56.2353 79.8919 69.7013 66.426C83.1673 52.96 90.7323 34.6963 90.7323 15.6526Z" fill="#E17A20" />
        <path d="M90.7322 15.653C90.8164 29.8859 86.6688 43.8229 78.816 55.6938C70.9633 67.5647 59.7597 76.834 46.6282 82.3246C33.4966 87.8151 19.0295 89.2793 5.06418 86.531C-8.90118 83.7827 -21.7347 76.9459 -31.8066 66.889C-41.8785 56.8321 -48.7344 44.0087 -51.5035 30.0475C-54.2726 16.0862 -52.83 1.61701 -47.359 -11.5227C-41.888 -24.6624 -32.6353 -35.8798 -20.7761 -43.7502C-8.9169 -51.6207 5.01376 -55.789 19.2468 -55.726C28.6412 -55.7824 37.9536 -53.9754 46.6451 -50.4095C55.3366 -46.8437 63.2347 -41.5898 69.8826 -34.9518C76.5305 -28.3138 81.7961 -20.4235 85.3749 -11.7373C88.9537 -3.05112 90.7746 6.25853 90.7322 15.653ZM18.8213 -42.2162C7.34326 -42.132 -3.85244 -38.6488 -13.3523 -32.2063C-22.8522 -25.7639 -30.2302 -16.6511 -34.5548 -6.01849C-38.8793 4.61407 -39.9564 16.2896 -37.6502 27.5339C-35.344 38.7782 -29.7578 49.0871 -21.597 57.1589C-13.4361 65.2307 -3.06663 70.7034 8.20228 72.8861C19.4712 75.0689 31.1342 73.8637 41.7187 69.4228C52.3032 64.9819 61.3345 57.5044 67.6724 47.9345C74.0102 38.3645 77.3704 27.1313 77.3287 15.653C77.3009 8.00754 75.764 0.442788 72.806 -6.6073C69.848 -13.6574 65.5272 -20.0541 60.0914 -25.4306C54.6557 -30.807 48.212 -35.0574 41.1299 -37.9379C34.0478 -40.8183 26.4666 -42.2722 18.8213 -42.2162Z" fill="#F1A869" />
        <path d="M18.8217 -42.2166C30.3001 -42.1956 41.515 -38.774 51.0502 -32.3838C60.5854 -25.9936 68.0134 -16.9214 72.3963 -6.31269C76.7792 4.29603 77.9204 15.9656 75.6759 27.2225C73.4314 38.4793 67.9018 48.8187 59.7854 56.9352C51.6689 65.0517 41.3294 70.5813 30.0725 72.8258C18.8157 75.0703 7.14619 73.929 -3.46253 69.5461C-14.0712 65.1632 -23.1434 57.7352 -29.5336 48.2C-35.9238 38.6648 -39.3454 27.45 -39.3665 15.9716C-39.3946 8.32234 -37.9088 0.74309 -34.9945 -6.32933C-32.0803 -13.4017 -27.7952 -19.8275 -22.3863 -25.2364C-16.9774 -30.6453 -10.5516 -34.9303 -3.47917 -37.8446C3.59325 -40.7589 11.1724 -42.2448 18.8217 -42.2166ZM18.8217 65.1178C28.5626 65.1807 38.1036 62.3537 46.2379 56.9943C54.3721 51.6349 60.7344 43.9837 64.5204 35.0083C68.3063 26.033 69.3457 16.1365 67.5072 6.57045C65.6687 -2.99564 61.0348 -11.8017 54.1915 -18.7342C47.3483 -25.6667 38.603 -30.4143 29.0615 -32.3767C19.5201 -34.339 9.61105 -33.4278 0.587397 -29.7585C-8.43626 -26.0893 -16.1692 -19.8266 -21.6336 -11.7625C-27.098 -3.6983 -30.0484 5.80514 -30.1117 15.5461C-30.1412 28.5555 -25.0444 41.0528 -15.9253 50.3312C-6.80618 59.6096 5.60098 64.9219 18.609 65.1178H18.8217Z" fill="#E17A20" />
        <path d="M18.6085 65.1182C8.85026 65.011 -0.656165 62.0074 -8.7045 56.4884C-16.7528 50.9694 -22.9803 43.1839 -26.5965 34.1198C-30.2128 25.0557 -31.0548 15.1217 -29.0159 5.57819C-26.977 -3.96527 -22.1489 -12.6879 -15.1444 -19.4829C-8.13989 -26.2779 0.725318 -30.8389 10.3263 -32.5871C19.9273 -34.3354 29.8312 -33.1921 38.7813 -29.3023C47.7314 -25.4125 55.3242 -18.9516 60.5963 -10.7394C65.8684 -2.52717 68.5821 7.06612 68.393 16.8231C68.1412 29.8117 62.7681 42.1748 53.4437 51.2203C44.1193 60.2658 31.5987 65.261 18.6085 65.1182ZM54.5639 15.9721C54.6211 6.5472 50.9533 -2.5188 44.3589 -9.25269C37.7644 -15.9866 28.7772 -19.8433 19.3531 -19.9834C9.85496 -19.8513 0.773344 -16.0625 -6.00271 -9.40535C-12.7788 -2.74817 -16.7276 6.26494 -17.0278 15.7593C-16.9731 25.2234 -13.2111 34.289 -6.54875 41.011C0.113572 47.733 9.1453 51.5758 18.6085 51.7148C23.328 51.7714 28.0115 50.8871 32.3855 49.1135C36.7594 47.34 40.7362 44.7128 44.0835 41.3852C47.4308 38.0577 50.0816 34.0966 51.881 29.7333C53.6805 25.3699 54.5925 20.6918 54.5639 15.9721Z" fill="#E17A20" />
      </g>
    </svg>

  )
}

export default WalletBgPattern2