import * as Yup from "yup"
import AtIcon from "../components/Icons/AtIcon"
import LockIcon from "../components/Icons/LockIcon"

export const signInInputs = [
	{name: 'email', label: "Email", type: "email", icon:<AtIcon/>},
	{name: 'password', label: "Password", type: "password", icon:<LockIcon/>},
]

export const signInSchema = Yup.object({
	email: Yup.string().email("Email is invalid").required("Email is required"),
	password: Yup.string().required("Password is required"),
})