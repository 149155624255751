import * as React from "react";

function MailIcon(props) {
	return (
		<svg width={17} height={17} viewBox="0 0 24 24" fill="none" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7 2.75c-1.615 0-3.075.404-4.135 1.388C1.795 5.132 1.25 6.607 1.25 8.5v7c0 1.893.545 3.369 1.615 4.362 1.06.984 2.52 1.388 4.135 1.388h10c1.615 0 3.075-.404 4.135-1.388 1.07-.993 1.615-2.469 1.615-4.362v-7c0-1.893-.545-3.368-1.615-4.362-1.06-.984-2.52-1.388-4.135-1.388H7zm12.229 5.612a.75.75 0 00-.918-1.187l-5.547 4.287a1.25 1.25 0 01-1.528 0L5.689 7.175a.75.75 0 10-.918 1.187l5.548 4.287a2.75 2.75 0 003.362 0l5.548-4.287z"
				fill="currentColor"
			/>
		</svg>
	);
}

const MemoMailIcon = React.memo(MailIcon);
export default MemoMailIcon;
