import React from 'react'

const LoadingIcon = ({className = '', ...props}) => {
	return (
		<svg className={`${className}`} {...props} xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
			<circle cx="50" cy="50" r="32" strokeWidth="11" className='stroke-current' strokeDasharray="50.26548245743669 50.26548245743669" fill="none" strokeLinecap="round">
				<animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1.408450704225352s" keyTimes="0;1" values="0 50 50;360 50 50" />
			</circle></svg>
	)
}

export default LoadingIcon